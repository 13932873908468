/**
 * @author Ramya Jhey
 */

import React, { Component, Fragment } from 'react'
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Divider } from 'material-ui';


const useStyles = theme => ({

  table: {
    minWidth: 650,
  },
});

function createData(name, id, datatype, weightage) {
  return { name, id, datatype, weightage };
}

class MetricSummaryComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {rows : []}
  }
  componentDidMount() {
    const records = []
    var metrics_summary = sessionStorage.getItem('metrics_summary')
    if(metrics_summary !== null) {
      var jsonObject = JSON.parse(metrics_summary)
    
    Object.keys(jsonObject).forEach(function(key) {
      let data = jsonObject[key]
      console.log(data['name'], data['datatype'])
      records.push(createData(data['name'], data['id'], data['datatype'], data['weightage']))
    })
    console.log(records)
    sessionStorage.setItem('metrics_submit', JSON.stringify(records))
    this.setState({rows:records})
    }
  }
    
    render(){
      const { classes } = this.props;
            return (
              <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Metric Name</TableCell>
                  <TableCell>Metric ID</TableCell>
                  <TableCell >Data Type</TableCell>
                  <TableCell >Weightage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell  component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell >{row.id}</TableCell>
                    <TableCell >{row.datatype}</TableCell>
                    <TableCell>{row.weightage}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>          
        )
    }
   }
  
export default withStyles(useStyles)(MetricSummaryComponent);

