/**
 * @author Raghav Prabhu
 * @desc User management service
 */
import service from "../Common/";
import constants from "../../../settings/shared/constants";
import jwt_decode from "jwt-decode";

/**
 * This function helps to create a new user by tenant
 * @param {*} user
 */
function create(account) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.accountType.create_account,
    method: "POST",
    headers: headers,
    data: account,
  });
}

function getAllByTenantId() {
  let jwtToken = localStorage.getItem("token");
  let user = jwt_decode(jwtToken);
  console.log(JSON.stringify(user));
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.accountType.get_account_by_tenant,
    method: "GET",
    headers: headers,
  });
}



//Making to publish all functions
const AccountType = {
  create,
  getAllByTenantId,
};

export default AccountType;
