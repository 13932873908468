/**
 * @author Ramya
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';

import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import LoadingOverlay from 'react-loading-overlay';
import jwt_decode from 'jwt-decode';
//import { toast } from "../../settings/shared/constants";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ConnectorService from '../../Services/api/Connectors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import JiraAccountService from '../../Services/api/JiraAccount';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';
const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
	paper: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '100%',
		margin: '20px',
		// margin: `${theme.spacing.unit}px auto`,
		marginTop: '30px',
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '100%',
		margin: '20px',
		// margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 5,
	},
	appBar: {
		padding: 0,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	textField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 300,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	loadingOverlay: {
		position: 'absolute',
		height: '100vh',
		width: '90vw',
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
});

class AddJiraComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			jiraValue: '',
			value: '',
			jiradata: [],
			request: [],
			jiragetdata: [],
			cloudurl: '',
			cloudemail: '',
			cloudapitoken: '',
			isCloud: false,
			selfurl: '',
			jiraconnectionStatus: 'na',
			selfusername: '',
			selfpassword: '',
			jirawebhookurl: '',
		};
		this.triggerSubmit = this.triggerSubmit.bind(this);
	}
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});
	changeHandle = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	checkConnectionHandle = (data, errors) => {
		this.setState({ jiraconnectionStatus: 'loading' });
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		let request = {};
		console.log(this.state.jiraValue);
		if (this.state.jiraValue) {
			if (this.state.jiraValue === 'Cloud') {
				request = {
					accountType: this.state.jiraValue,
					url: this.state.cloudurl,
					username: this.state.cloudemail,
					secretToken: this.state.cloudapitoken,
					tenant: user.tenant,
					type: 'Destination',
					userId: user.id,
					id: user.organisation,
				};
			}
			if (this.state.jiraValue === 'Self') {
				request = {
					accountType: this.state.jiraValue,
					url: this.state.selfurl,
					username: this.state.selfusername,
					secretToken: this.state.selfpassword,
					tenant: user.tenant,
					type: 'Destination',
					userId: user.id,
					id: user.organisation,
				};
			}
			console.log('Data ', request);

			ConnectorService.getJiraConnectionStatus(request).then((response) => {
				if (response === true) {
					this.setState({ jiraconnectionStatus: 'true' });
				} else {
					this.setState({ jiraconnectionStatus: 'false' });
				}
			});
		} else {
			this.toast.error('Please Select Account Type.');
		}
	};

	genrateWebhook = () => {
		JiraAccountService.generateWebhookURL().then(
			(response) => {
				this.toast.success('Webhook URL created successfully.');
				this.setState({ jirawebhookurl: response });
			},
			(error) => {
				this.toast.error('Something Went Wrong.');
			}
		);
	};

	triggerSubmit = (data, errors) => {
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		let request = {};
		if (this.state.jiraValue) {
			if (this.state.jiraValue === 'Cloud') {
				request = {
					accountType: this.state.jiraValue,
					url: this.state.cloudurl,
					username: this.state.cloudemail,
					secretToken: this.state.cloudapitoken,
					tenant: user.tenant,
					type: 'Destination',
					userId: user.id,
					id: user.organisation,
				};
			}
			if (this.state.jiraValue === 'Self') {
				request = {
					accountType: this.state.jiraValue,
					url: this.state.selfurl,
					username: this.state.selfusername,
					secretToken: this.state.selfpassword,
					tenant: user.tenant,
					type: 'Destination',
					userId: user.id,
					id: user.organisation,
				};
			}
			console.log('Data ', request);

			ConnectorService.postJiraConnector(request).then((response) => {
				if (response.status === 'SUCCESS') {
					this.toast.success('JIRA account configured successfully', () => {
						JiraAccountService.GetJiraConnector().then((response) => {
							if (response.status === 'SUCCESS') {
								localStorage.setItem('jiraURL', response.jiraConnectorDO.url);
								this.props.history.push('/settings');
							}
						});
					});

					// window.location.reload();
				} else {
					this.toast.error(response.errorMessage, () => {
						console.log('closed');
					});
				}
			});
		} else {
			this.toast.error('Please Select Account Type.');
		}
	};

	deleteJira = (data, errors) => {
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		let request = {};
		if (this.state.jiraValue) {
			ConnectorService.deleteJiraConnector().then((response) => {
				if (response === true) {
					this.toast.success('Jira Delete Successfully', () => {
						this.props.history.push('/settings');
					});
				} else {
					this.toast.error('Something went wrong.', () => {
						console.log('closed');
					});
				}
			});
		} else {
			this.toast.error('Please Select Account Type.');
		}
	};

	jirahandleChange = (event) => {
		this.setState({ jiraValue: event.target.value });
		const { classes } = this.props;
		if (event.target.value === 'Cloud') {
			this.setState({ isCloud: true });
		} else {
			this.setState({ isCloud: false });
		}
	};

	componentDidMount() {
		localStorage.setItem('title', 'Jira Setting');
		const { classes } = this.props;
		this.setState({ loading: false, isCloud: false });

		JiraAccountService.getWebhookURL().then(
			(response) => {
				this.setState({ jirawebhookurl: response });
			},
			(error) => {}
		);

		ConnectorService.getJiraConnector().then((response) => {
			if (response.status === 'SUCCESS') {
				console.log('sss', response);

				let jiragetdata = response.jiraConnectorDO;
				if (response.jiraConnectorDO.accountType === 'Cloud') {
					this.setState({
						isCloud: true,
						id: response.jiraConnectorDO.id,
						jiraValue: response.jiraConnectorDO.accountType,
						cloudurl: response.jiraConnectorDO.url,
						cloudemail: response.jiraConnectorDO.username,
						cloudapitoken: response.jiraConnectorDO.secretToken,
					});
				} else {
					this.setState({
						isCloud: false,
						id: response.jiraConnectorDO.id,
						jiraValue: response.jiraConnectorDO.accountType,
						selfurl: response.jiraConnectorDO.url,
						selfusername: response.jiraConnectorDO.username,
						selfpassword: response.jiraConnectorDO.secretToken,
					});
				}
			} else {
			}
		});
	}
	render() {
		const { classes } = this.props;
		console.log('-------------------------');
		console.log(this.state);
		console.log('-------------------------');
		const isCloud = this.state.isCloud;
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		let role = undefined;

		if (user.roles !== undefined) {
			role = user.roles[0];
		}

		let isDisable = false;
		if (role === 'user') isDisable = true;
		return (
			<React.Fragment>
				<Header title="Jira settings" />
				<LoadingOverlay
					active={this.state.loading}
					spinner
					className={classes.loadingOverlay}
				>
					<Grid item>
						<Paper className={classes.paper1}>
							<Grid container spacing={8}>
								<FormControl
									component="fieldset"
									className={classes.formControl}
								>
									<Toolbar>
										<Typography variant="h5" color="inherit">
											JIRA Authentication Settings
										</Typography>
									</Toolbar>
									<FormLabel component="legend"></FormLabel>
									<RadioGroup
										aria-label="JIRA"
										name="Jira"
										value={this.state.jiraValue}
										onChange={this.jirahandleChange}
									>
										<FormControlLabel
											value="Cloud"
											control={<Radio color="primary" />}
											label="Cloud JIRA"
										/>
										<FormControlLabel
											value="Self"
											control={<Radio color="primary" />}
											label="Self-hosted JIRA"
										/>
									</RadioGroup>
									<br></br>
									<div>
										{isCloud
											? (this.state.jiradata = (
													<div>
														<form className={classes.form}>
															<Typography variant="h6" color="inherit">
																{/* {event.target.value} */}
																{this.state.jiraValue}
															</Typography>
															<FormControl margin="normal" required fullWidth>
																<InputLabel htmlFor="tenant">URL</InputLabel>
																<Input
																	id="cloudurl"
																	name="cloudurl"
																	disabled={isDisable}
																	value={this.state.cloudurl}
																	onChange={this.changeHandle}
																/>
															</FormControl>
															<FormControl margin="normal" required fullWidth>
																<InputLabel htmlFor="email">Email </InputLabel>
																<Input
																	id="cloudemail"
																	name="cloudemail"
																	disabled={isDisable}
																	value={this.state.cloudemail}
																	onChange={this.changeHandle}
																/>
															</FormControl>
															<FormControl margin="normal" required fullWidth>
																<InputLabel htmlFor="password">
																	API Token
																</InputLabel>
																<Input
																	id="cloudapitoken"
																	name="cloudapitoken"
																	type="password"
																	disabled={isDisable}
																	value={this.state.cloudapitoken}
																	onChange={this.changeHandle}
																/>
															</FormControl>

															<Grid item align="center" xs={6} sm={12}>
																<br></br>
																<Button
																	color="primary"
																	variant="contained"
																	className={classes.button}
																	onClick={this.triggerSubmit}
																	disabled={isDisable}
																>
																	Save
																	<SaveIcon className={classes.rightIcon} />
																</Button>
																<Button
																	color="primary"
																	variant="contained"
																	className={classes.button}
																	onClick={this.deleteJira}
																	disabled={isDisable}
																>
																	Delete
																	<CancelIcon className={classes.rightIcon} />
																</Button>
															</Grid>
														</form>
													</div>
											  ))
											: (this.state.jiradata = (
													<div>
														<form className={classes.form}>
															<Typography variant="h6" color="inherit">
																{this.state.jiraValue}
															</Typography>
															<FormControl margin="normal" required fullWidth>
																<InputLabel htmlFor="selfurl">URL</InputLabel>
																<Input
																	id="selfurl"
																	name="selfurl"
																	disabled={isDisable}
																	value={this.state.selfurl}
																	onChange={this.changeHandle}
																/>
															</FormControl>
															<FormControl margin="normal" required fullWidth>
																<InputLabel htmlFor="selfusername">
																	Username{' '}
																</InputLabel>
																<Input
																	id="selfusername"
																	name="selfusername"
																	disabled={isDisable}
																	value={this.state.selfusername}
																	onChange={this.changeHandle}
																/>
															</FormControl>
															<FormControl margin="normal" required fullWidth>
																<InputLabel htmlFor="password">
																	Password
																</InputLabel>
																<Input
																	id="selfpassword"
																	name="selfpassword"
																	type="password"
																	disabled={isDisable}
																	value={this.state.selfpassword}
																	onChange={this.changeHandle}
																/>
															</FormControl>

															<FormControl margin="normal" required fullWidth>
																<InputLabel htmlFor="selfurl">
																	Webhook URL
																</InputLabel>
																<Input
																	id="webhookurl"
																	name="webhookurl"
																	disabled={isDisable}
																	value={this.state.jirawebhookurl}
																/>
															</FormControl>

															<Grid item align="center" xs={6} sm={12}>
																<br></br>
																<Button
																	color="primary"
																	variant="contained"
																	className={classes.button}
																	disabled={isDisable}
																	onClick={this.checkConnectionHandle}
																>
																	Check Connection
																	{/* <SaveIcon className={classes.rightIcon} /> */}
																	{this.state.jiraconnectionStatus ===
																		'true' && <CheckCircleOutlineIcon />}
																	{this.state.jiraconnectionStatus ===
																		'false' && <CancelIcon />}
																	{this.state.jiraconnectionStatus ===
																		'loading' && <CircularProgress size={24} />}
																</Button>
																<Button
																	color="primary"
																	variant="contained"
																	className={classes.button}
																	onClick={this.triggerSubmit}
																	disabled={isDisable}
																>
																	Save
																	<SaveIcon className={classes.rightIcon} />
																</Button>

																<Button
																	color="primary"
																	variant="contained"
																	className={classes.button}
																	onClick={this.triggerSubmit}
																	disabled={isDisable}
																>
																	Cancel
																	<CancelIcon className={classes.rightIcon} />
																</Button>
																<Button
																	color="primary"
																	variant="contained"
																	className={classes.button}
																	onClick={this.genrateWebhook}
																	disabled={isDisable}
																>
																	Genrate Webhook URL
																</Button>
															</Grid>
														</form>
													</div>
											  ))}
									</div>
								</FormControl>
								<br></br>
							</Grid>
						</Paper>
					</Grid>
				</LoadingOverlay>
			</React.Fragment>
		);
	}
}

export default withStyles(useStyles)(AddJiraComponent);
