/**
 * @author Gaurav Sharma, Ramya Jhey
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:59:57
 * @modify date 2021-10-04 16:41:38
 * @desc OTFrom
 */

import React from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { FormGenerator } from 'dynamic-material-ui';
import * as MUI from '@material-ui/core/';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
	paper: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '50%',
		margin: `${theme.spacing.unit}px auto`,
		marginTop: '77px',
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '50%',
		margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 2,
	},
	appBar: {
		padding: 0,
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
});

class OTForm extends React.PureComponent {
	// const { classes } = props;

	constructor(props) {
		super(props);
		this.state = { formObj: props };
		this.updateForm({ props });
		this.onSubmit = this.onSubmit.bind(this);
		this.triggerSubmit = this.triggerSubmit.bind(this);
		this.deleteClick = this.deleteClick.bind(this);
	}

	updateForm(props) {
		this.setState((props) => {
			return { formObj: props };
		});
	}

	onSubmit(response, errors, formData) {
		console.log(errors);
		this.props.submitHandler(response);
	}

	triggerSubmit(data) {
		this.formRef.click();
	}

	deleteClick(data) {
		this.props.deleteHandler(data);
	}

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Grid item>
					<Paper className={classes.paper}>
						<AppBar position="static" className={classes.appBar}>
							<Toolbar>
								<Typography variant="h6" color="inherit">
									{this.state.formObj.formdata.title}
								</Typography>
							</Toolbar>
						</AppBar>
					</Paper>
					<Paper className={classes.paper1}>
						{/* <Grid container spacing={30}> */}

						<FormGenerator
							guid="layout"
							data={this.state.formObj.formdata.field}
							displayErrors={this.state.displayErrors}
							library={MUI}
							formRef={(form) => {
								this.formRef = form;
							}}
							onSubmit={this.onSubmit}
						/>
						<br></br>
						<Grid item align="center" xs={6} sm={12}>
							<Button
								variant="contained"
								className={classes.button}
								size="medium"
								color="primary"
								onClick={this.deleteClick}
							>
								Delete
								<DeleteIcon className={classes.rightIcon} />
							</Button>

							<Button
								variant="contained"
								className={classes.button}
								size="medium"
								color="primary"
								onClick={this.triggerSubmit}
							>
								{this.props.formdata.buttonName}
								<SaveIcon className={classes.rightIcon} />
							</Button>
						</Grid>
						{/* </Grid> */}
					</Paper>
				</Grid>
			</React.Fragment>
		);
	}
}

OTForm.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OTForm);
