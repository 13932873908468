/**
 * @author: Raghav Prabhu
 */
import ToastServive from 'react-material-toast';

export const apiVersion = 'v1.0';

//React toast library - https://www.npmjs.com/package/react-material-toast
export const toast = ToastServive.new({
	place: 'topLeft',
	duration: 20,
	maxCount: 8,
	closable: true,
});

export const jwtToken = localStorage.getItem('token');

export default {
  api: {
    base: {
      url:
        process.env.REACT_APP_SERVICE_APP_BACKEND_HOST ||
        "http://192.168.0.105:8080/",
    },
    googleauth: {
      token:
        "899889983925-ih5r70q8ckoosicos2usesevbql22qvf.apps.googleusercontent.com",
      // '894279444792-bvsjgmhcn6e57a2c9k8jtent0n10flao.apps.googleusercontent.com',
    },
    auth: {
      login: "/login/" + apiVersion,
      loginWithGoogle: "/login/" + apiVersion + "/google",
    },
    organisation: {
      create: "/api/admin/" + apiVersion + "/organisation",
      get: "/api/admin/" + apiVersion + "/organisation",
      get_all: "/api/admin/" + apiVersion + "/organisation/all",
      update: "/api/admin/" + apiVersion + "/organisation",
      delete: "/api/admin/" + apiVersion + "/organisation",
    },
    user: {
      create: "/api/" + apiVersion + "/user",
      get: "/api/" + apiVersion + "/user",
      get_all: "/api/" + apiVersion + "/user/tenant",
      update: "/api/" + apiVersion + "/user",
      delete: "/api/" + apiVersion + "/user",
      update_password: "/api/" + apiVersion + "/user/updatepassword",
      activeUserById: "/api/" + apiVersion + "/user/activate/user",
    },
    metric: {
      create: "/api/" + apiVersion + "/metric",
      update: "/api/" + apiVersion + "/metric",
      get_all: "/api/" + apiVersion + "/metric/tenant",
      get_all_with_bucket: "/api/" + apiVersion + "/metric/all/tenant",
      delete: "/api/" + apiVersion + "/metric",
    },
    bucket: {
      create: "/api/" + apiVersion + "/metric/bucket",
      create_list: "/api/" + apiVersion + "/metric/bucket/list",
      create_product_list: "/api/" + apiVersion + "/metric/bucket/product/list",
      get_by_metric: "/api/" + apiVersion + "/metric/bucket/metric",
      get_by_type: "/api/" + apiVersion + "/metric/bucket/type",
      delete_bucket_by_tenant: "/api/" + apiVersion + "/metric/bucket/tenent",
    },
    sfdc: {
      create_auth: "/api/" + apiVersion + "/sfdc/auth/",
      get_auth: "/api/" + apiVersion + "/sfdc/auth",
      get_fields_all: "/api/" + apiVersion + "/sfdc/fields/describe/all",
      get_fields_jira: "/api/" + apiVersion + "/sfdc/fields/describe/jira",
      get_fields_opportunity:
        "/api/" + apiVersion + "/sfdc/fields/describe/opportunity",
      get_fields_account:
        "/api/" + apiVersion + "/sfdc/fields/describe/account",
      get_auth_template: "/api/" + apiVersion + "/sfdc/auth/template",
      oauth_authorize: "/api/" + apiVersion + "/sfdc/auth/login",
      oauth_callback: "/api/" + apiVersion + "/sfdc/auth/oauth2",
      get_account_by_id:
        "/api/" + apiVersion + "/opportunity/tenant/sfdc/account",
      get_user_by_id: "/api/" + apiVersion + "/opportunity/tenant/sfdc/user",
      check_connection: "/api/" + apiVersion + "/sfdc/auth/checkstatus",
      get_notes_by_opp: "/api/" + apiVersion + "/opportunity",
    },
    // jira: {
    //   create_jiraauth: "/api/" + apiVersion + "/jira/auth/",
    //   get_jira_fields: "/api/" + apiVersion + "/jira/auth/connector",
    //   get_jira_token: "/api/" + apiVersion + "/jira/auth/requestToken",
    // },
    opportunity: {
      get_opportunity: "/api/" + apiVersion + "/opportunity/tenant",
      get_my_opportunity: "/api/" + apiVersion + "/feature",
      get_amount_details: "/api/" + apiVersion + "/opportunity/reportbyuser",
      get_amount_details_by_tenant:
        "/api/" + apiVersion + "/opportunity/reportbytenant",

      get_opp_by_id: "/api/" + apiVersion + "/opportunity/opp",
      get_opp_history: "/api/" + apiVersion + "/opportunity/history/opp",
      get_opp_roi: "/api/" + apiVersion + "/opportunity/roi",
    },
    accountType: {
      create_account: "/api/" + apiVersion + "/accounts",
      get_account_by_bucket: "/api/" + apiVersion + "/accounts/bucket",
      get_account_by_tenant: "/api/" + apiVersion + "/accounts/tenant",
    },
    jira: {
      get_projects: "/api/" + apiVersion + "/jira/project/all",
      get_jira_fields: "/api/" + apiVersion + "/jira/auth/connector",
      get_issue_type: "/api/" + apiVersion + "/jira/project/issuetype/all",
      post_submit_approve:
        "/api/" + apiVersion + "/jira/project/submit/approve",
      post_submit_reject: "/api/" + apiVersion + "/jira/project/submit/reject",
      create_jiraauth: "/api/" + apiVersion + "/jira/auth/",
      get_jira_connector: "/api/" + apiVersion + "/jira/connector",
      get_jira_token: "/api/" + apiVersion + "/jira/auth/requestToken",
      get_jira_details: "/api/" + apiVersion + "/jira/project/feature",
      get_features_with_jira_details:
        "/api/" + apiVersion + "/jira/project/opportunityid",
      get_jira_comments_user_details:
        "/api/" + apiVersion + "/jira/project/comments/user/data",
      post_jira_comment: "/api/" + apiVersion + "/jira/project/addcomments",
      get_jira_comments: "/api/" + apiVersion + "/jira/project/comment",
      update_jira_issue: "/api/" + apiVersion + "/jira/project/update/issue",
      get_jira_issue_by_tenent:
        "/api/" + apiVersion + "/jira/project/issue/bytenant",
      get_jira_issue_by_user:
        "/api/" + apiVersion + "/jira/project/issue/byuser",
      search_jira_issue: "/api/" + apiVersion + "/jira/project/searchissue",
      append_jira_desc: "/api/" + apiVersion + "/jira/project/append/issue",
      delete_append_jira: "/api/" + apiVersion + "/jira/project/feature",
      get_append_by_jira_id:
        "/api/" + apiVersion + "/jira/project/getappendbyjiraid",
      get_jira_connection_status: "/api/" + apiVersion + "/jira/checkstatus",
      get_jira_by_feature_id: "/api/" + apiVersion + "/jira/project/featureid",
      get_feature_with_jira_opp_details_by_jirastatus:
        "/api/" + apiVersion + "/jira/project/report/user/jira",
      get_feature_with_jira_opp_details_by_jirastatus1:
        "/api/" + apiVersion + "/jira/project/report/user",

      get_feature_with_jira_opp_details_by_tenant:
        "/api/" + apiVersion + "/jira/project/report/tenant",
      get_jira_history: "/api/" + apiVersion + "/jira/project/all/history",
      create_custom_field: "/api/" + apiVersion + "/jira/project/createfield",
    },

    feature: {
      submit_features: "/api/" + apiVersion + "/feature",
      delete_submit_features_by_jiraI_id: "/api/" + apiVersion + "/feature/ids",
      get_feature_by_source_id: "/api/" + apiVersion + "/feature/id",
      // http://localhost:8080/api/v1.0/feature/ids?jiraids=poop&jiraids=poo&jiraids=ooo&jiraids=pppp
    },

    dashboard: {
      get_my_opportunity: "/api/" + apiVersion + "/dashboard/my_submissions",
      get_all_opportunity: "/api/" + apiVersion + "/dashboard/all_submissions",
      get_featurue_summary: "/api/" + apiVersion + "/dashboard/featuresummary",
      get_my_featurue_summary:
        "/api/" + apiVersion + "/dashboard/myfeaturesummary",
    },
    selectCounty: {
      get_country: "https://api.printful.com/countries",
    },
    sfdcnotes: {
      get_sfdc_nodes_by_opp_id:
        "/api/" + apiVersion + "/sfdc/notes/opportunity",
    },
    score: {
      get_score_by_sfdc_id: "/api/" + apiVersion + "/score",
    },
    integration: {
      get_list: "/api/" + apiVersion + "/integrationtools/list",
    },
    jirawebhook: {
      genrate_webhook_url:
        "/api/" + apiVersion + "/jira/project/generatewebhook",
      get_webhook_url: "/api/" + apiVersion + "/jira/project/getwebhookurl",
    },
    csv: {
      add_csv: "/api/v1.0/csv",
      get_all_notes: "/api/v1.0/csv/notes/all",
      get_all_notes_by_oppid: "/api/v1.0/csv/notes/opportunity",
      get_notes_by_id: "/api/v1.0/csv/notes",
      get_all_opp: "/api/v1.0/csv/opportunity/all",
      get_opp_by_id: "/api/v1.0/csv/opportunity",
      get_csv_by_type: "/api/v1.0/csv",
    },
    gsheet: {
      oauth: "googlesheet/googlesignin",
      callback: "/api/" + apiVersion + "/googlesheet/Callback",
      create: "/api/" + apiVersion + "/googlesheet/",
      getOpp: "/api/" + apiVersion + "/googlesheet/opportunity/all",
      get_all_notes: "/api/" + apiVersion + "/googlesheet/notes/all",
      get_all_notes_by_oppid:
        "/api/" + apiVersion + "/googlesheet/notes/opportunity",
      get_notes_by_id: "/api/v1.0/googlesheet/notes",
      get_opp_by_id: "/api/" + apiVersion + "/googlesheet/opportunity",
      get_file_by_type: "/api/" + apiVersion + "/googlesheet/file",
      delete_details: "/api/" + apiVersion + "/googlesheet",
    },
    notification: {
      get_notification: "/api/" + apiVersion + "/notification/user",
      update_notification: "/api/" + apiVersion + "/notification",
    },
  },
};
