/**
 * @author Ramya Jhey
 * @desc Organisation Component Module
 */

import React, { Component } from 'react';
import { OTTable } from '../';
import {
	accountform,
	accountMenu,
} from '../../settings/templates/account/accountcreation';
import AccoutService from '../../Services/api/Account';
import jwt_decode from 'jwt-decode';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';

/**
 * Feature Request - Organisation {account} module. This module will be
 * accessed only by SUPER Admin
 */
class AccountComponent extends Component {
	constructor(props) {
		super(props);
		this.state = { accounts: [], formdata: accountform };
	}

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	submitHandler = (data) => {
		console.log(' *** ', data.layout);

		let request = {
			name: data.layout.organisationName,
			email: data.layout.emailAddress,
			city: data.layout.city,
			officeAddress: data.layout.officeAddress,
			description: data.layout.description,
			url: data.layout.url,
			firstName: data.layout.firstName,
			lastName: data.layout.lastName,
			password: data.layout.password,
		};

		AccoutService.create(request).then((response) => {
			this.toast.success('Account Created successfully...', () => {
				console.log('closed');
			});
			this.props.history.push('/accounts');
			//window.location.reload();
		});
	};

	//Component mount life cycle
	componentDidMount() {
		//accountform
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		localStorage.setItem('title', 'Accounts');
		AccoutService.getAll().then((response) => {
			console.log(response);
			if (response.status === 'SUCCESS') {
				let data = [];
				let account = [];
				let company = [];
				let companyField = {};
				let accounts = response.organisationDTOS;
				accounts.forEach((element) => {
					account = [];
					account.push(element.id);
					account.push(element.identifier);
					account.push(element.name);
					account.push(element.email);
					account.push(element.officeAddress);
					account.push(element.city);
					account.push(element.description);
					account.push(element.url);
					data.push(account);
					//   companyField = {
					//       "value": element.identifier,
					//       "primaryText": element.name
					//   }
					//  company.push(companyField)
				});
				// this.state.formdata.field.forEach(function(element){
				//   if(element.props.id === 'company') {
				//     element.options = company
				//   }
				// });
				this.setState({ accounts: data });
			} else {
			}
		});
	}

	render() {
		return (
			<>
				<Header title="Accounts" />
				<OTTable
					formdata={this.state.formdata}
					title="Account List"
					data={this.state.accounts}
					columns={accountMenu}
					tooltip="Add Account"
					handleClick={this.myuserHandler}
					submitHandler={this.submitHandler}
				></OTTable>
			</>
		);
	}
}

export default AccountComponent;
