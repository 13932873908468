/**
 * @author Ramya Jhey, Raghav Prabhu
 */

import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import AddCircle from '@material-ui/icons/AddCircle';
import MetricService from '../../Services/api/Metric';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import SaveIcon from '@material-ui/icons/Save';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { default as UUID } from 'node-uuid';
import {
	percentageDataSet,
	currencyDataSet,
	priorityDataSet,
	customerDataSet,
} from '../DataSets/MenuDataSet';
import DeleteIcon from '@material-ui/icons/Delete';
// import Select from 'react-select';
import ToastServive from 'react-material-toast';
import { emphasize } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import BucketService from '../../Services/api/Bucket';
import Header from '../CommonComponents/Header';
import styled from 'styled-components';
import CustomToolbar from '../RankingComponent/MetricToolBar';
import jwt_decode from 'jwt-decode';

const useStyles = (theme) => ({
	appBar: {
		position: 'relative',
	},

	grid: {
		display: 'flex',
	},
	textField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 200,
		fontSize: '12px',
	},
	selectField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 220,
	},
	selectFields: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 221,
		fontSize: '12px',
	},
	selectPercentageField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 100,
	},
	deletebutton: {
		marginLeft: '-30px',
		marginTop: '5px',
		width: 150,
	},

	root: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			marginTop: theme.spacing.unit * 6,
			marginBottom: theme.spacing.unit * 6,
			padding: theme.spacing.unit * 3,
		},
	},

	formControl: {
		margin: theme.spacing.unit * 1,
		minWidth: 120,
	},

	selectMenu: {
		// marginTop: 7,
		fontSize: '12px',
	},
	buttonadd: {
		marginLeft: '800px',
	},
	cardHeader: {
		backgroundColor: '#ededed',
		borderBottom: '1px solid #ededed',
	},
	cardHeadertop: {
		backgroundColor: '#fff',
		borderBottom: '1px solid #ededed',
		borderTop: '2px solid #0288d1',
	},
	card: {
		minWidth: 75,
		fontSize: '1rem',
	},

	cardAction: {
		display: 'block',
		textAlign: '-webkit-center',
		padding: '0px',
		borderTop: '1px solid #ededed',
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
	root1: {
		flexGrow: 1,
		// height: 100,
		minWidth: 600,
	},
	input: {
		display: 'flex',
		padding: 0,
		height: 'auto',
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	chip: {
		margin: theme.spacing(0.5, 0.25),
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === 'light'
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		),
	},
	noOptionsMessage: {
		padding: theme.spacing(1, 2),
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		bottom: 6,
		fontSize: 16,
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

// function getStyles(name, customerName, theme) {
//   return {
//     fontWeight:
//       customerName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

class BucketComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: [],
			add: [],
			dynamicmetrics: [],
			prob: [],
			multi: null,
			setMulti: null,
			customerName: [],
			setcustomerName: [],
			setPersonName: [],
		};
		this.position = null;
		this.addOptions = null;
	}

	addInputField = (event, stateName, type) => {
		let stateRecord = this.state[stateName];

		let record = undefined;
		let uuid = UUID.v4();
		if (type === 'Currency') {
			record = {
				percentage: 0,
				condition: '',
				range: '',
				range_to: '',
				id: uuid,
				percentage_id: 'percentage_' + uuid,
				condition_id: 'condition_' + uuid,
				range_id: 'range_' + uuid,
				range_to_id: 'range_to_' + uuid,
				datatype: type,
			};
		} else if (type === 'Priority') {
			record = {
				percentage: 0,
				priority: '',
				id: uuid,
				percentage_id: 'percentage_' + uuid,
				priority_id: 'priority_' + uuid,
				datatype: type,
			};
		} else if (type === 'Customer') {
			record = {
				percentage: 0,
				customer: [],
				id: uuid,
				percentage_id: 'percentage_' + uuid,
				customer_id: 'customer_' + uuid,
				datatype: type,
			};
		}

		stateRecord.push(record);
		this.setState({
			record,
			open: true,
		});
		event.preventDefault();
	};

	handleCustomerChange = (event) => {
		this.setState({ setcustomerName: event.target.value });
	};

	renderOptions = (selected) => {
		console.log(selected);
	};

	handleChange = (event, fieldId, stateName, type, fieldType) => {
		const addOptions = this.state[stateName];

		let field = fieldType + '_id';

		addOptions.map((index) => {
			if (index[field] === event.target.name) {
				index[fieldType] = event.target.value;
			} else if (index[field] === undefined) {
				index[fieldType] = event.target.value;
				index.id = fieldId;
				index[field] = fieldType + '_' + fieldId;
			}
		});
		if (
			fieldType === 'percentage' ||
			fieldType === 'priority' ||
			fieldType === 'condition'
		) {
			this.setState(addOptions);
		}
	};

	removeInputField = (fieldId, stateName, type) => {
		let stateRecord = this.state[stateName];
		let record = undefined;
		for (let i = 0; i < stateRecord.length; i++) {
			if (stateRecord[i].id === fieldId) {
				stateRecord.splice(i, 1);
			}
		}

		this.setState({
			record,
			open: true,
		});
	};

	handleClickCancel = () => {
		this.props.history.push('/dashboard');
	};
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	deleteButtonHandler = () => {
		BucketService.deleteBucketsByTenantId().then((response) => {
			if (response.status === 'SUCCESS') {
				this.toast.success('Bucket Deleted successfully...', () => {});
				window.location.reload();
			}
		});
	};

	bucketDeleteHandler = (id) => {
		BucketService.deleteBucketsById(id).then((response) => {
			if (response.status === 'SUCCESS') {
				this.toast.success('Bucket Deleted successfully...', () => {});
				// window.location.reload();
			}
		});
	};

	handleClickSave = () => {
		var buckets = [];
		var bucket = {};
		this.state.dynamicmetrics.map((metricElement, index) => {
			if (this.state[metricElement.id].length > 0) {
				this.state[metricElement.id].forEach((element) => {
					if (element.datatype === 'Currency') {
						bucket = {
							metric: metricElement.id,
							fromValue: element.range,
							toValue: element.range_to,
							percentage: element.percentage,
							condition: element.condition,
							type: element.datatype,
							range_to: element.range_to,
						};
					} else if (element.datatype === 'Priority') {
						bucket = {
							metric: metricElement.id,
							fromValue: element.range,
							percentage: element.percentage,
							condition: element.priority,
							type: element.datatype,
						};
					} else if (element.datatype === 'Customer') {
						bucket = {
							metric: metricElement.id,
							fromValue: element.range,
							percentage: element.percentage,
							customers: element.customer,
							type: element.datatype,
						};
					}
					buckets.push(bucket);
				});
			}
		});
		BucketService.create(buckets).then((response) => {
			if (response.status === 'SUCCESS') {
				this.toast.success('Bucket Created successfully...', () => {});
				this.props.history.push('/opportunities');
			}
			if (response.status === 'FAILURE') {
				this.toast.error(response.errorMessage, () => {});
				// this.props.history.push('/opportunities');
			}
		});
	};
	handleClose = () => {
		this.setState({ open: false });
	};

	getDealField = (stateIndex, stateName, type) => {
		const { classes } = this.props;

		let rangeField = 'range_' + stateIndex.id;
		let rangeFieldTo = 'range_to_' + stateIndex.id;
		let conditionField = 'condition_' + stateIndex.id;
		return (
			<React.Fragment>
				<p>If Deal size </p>

				<TextField
					select
					value="currency"
					label="Deal"
					margin="dense"
					variant="outlined"
					name={conditionField}
					id={conditionField}
					onChange={(event) => {
						this.handleChange(
							event,
							stateIndex.id,
							stateName,
							type,
							'condition'
						);
					}}
					value={stateIndex.condition}
					className={classes.selectFields}
					inputProps={{
						classes: {
							icon: classes.icon,
						},
					}}
				>
					{currencyDataSet.map((index) => (
						<MenuItem key={index} value={index}>
							{index}
						</MenuItem>
					))}
					;
				</TextField>
				{/* <TextField
          id={rangeField}
          name={rangeField}
          label="Min"
          className={classes.textField}
          onChange={(event) => {
            this.handleChange(event, stateIndex.id, stateName, type, "range");
          }}
          value={stateIndex.range}
          margin="dense"
          variant="outlined"
        /> */}
				<TextField
					id={rangeField}
					name={rangeField}
					label="Min"
					className={classes.textField}
					onChange={(event) => {
						this.handleChange(event, stateIndex.id, stateName, type, 'range');
					}}
					defaultValue={stateIndex.range}
					margin="dense"
					variant="outlined"
				/>
				<TextField
					id={rangeFieldTo}
					name={rangeFieldTo}
					label="Max"
					className={classes.textField}
					onChange={(event) => {
						this.handleChange(
							event,
							stateIndex.id,
							stateName,
							type,
							'range_to'
						);
					}}
					defaultValue={stateIndex.range_to}
					margin="dense"
					variant="outlined"
				/>
				<p>then percentage</p>
			</React.Fragment>
		);
	};

	/**
	 * @param stateIndex -
	 */
	getPriorityField = (stateIndex, stateName, type) => {
		const { classes } = this.props;

		let priorityField = 'priority_' + stateIndex.id;
		return (
			<React.Fragment>
				<FormControl className={classes.formControl}>
					{/* <InputLabel className={classes.label}></InputLabel> */}
					<TextField
						select
						value="priority"
						label="Priority"
						name={priorityField}
						margin="dense"
						variant="outlined"
						id={priorityField}
						onChange={(event) => {
							this.handleChange(
								event,
								stateIndex.id,
								stateName,
								type,
								'priority'
							);
						}}
						value={stateIndex.priority}
						className={classes.selectField}
					>
						{priorityDataSet.map((index) => (
							<MenuItem value={index}>{index}</MenuItem>
						))}
						;
					</TextField>
				</FormControl>
			</React.Fragment>
		);
	};

	getCustomerField = (stateIndex, stateName, type) => {
		const names = [
			{ name: 'Andorra' },
			{ name: 'United Arab Emirates' },
			{ name: 'Iran, Islamic Republic of' },
		];
		const { classes } = this.props;

		let customerField = 'customer_' + stateIndex.id;
		const suggestions = customerDataSet.map((suggestion) => ({
			value: suggestion.label,
			label: suggestion.label,
		}));

		return (
			<React.Fragment>
				<div className={classes.root1}>
					<NoSsr>
						<div className={classes.divider} />
						<FormControl className={classes.formControl}>
							<TextField
								id={customerField}
								name={customerField}
								label="Customer"
								className={classes.textField}
								onChange={(event) => {
									this.handleChange(
										event,
										stateIndex.id,
										stateName,
										type,
										'customer'
									);
								}}
								//value={stateIndex.customer}
								defaultValue={stateIndex.customer}
								margin="dense"
								variant="outlined"
							/>
						</FormControl>
					</NoSsr>
				</div>
			</React.Fragment>
		);
	};

	getField = (stateIndex, stateName, type) => {
		if (type === 'Currency') {
			return this.getDealField(stateIndex, stateName, type);
		} else if (type === 'Priority') {
			return this.getPriorityField(stateIndex, stateName, type);
		} else if (type === 'Customer') {
			return this.getCustomerField(stateIndex, stateName, type);
		}
	};

	percentage = (stateIndex, stateName, type) => {
		const { classes } = this.props;
		const { value } = this.state;
		let fieldId = stateIndex.id;

		if (fieldId === undefined) {
			fieldId = UUID.v4();
			stateIndex.id = UUID.v4();
		}
		let field = 'percentage_' + fieldId;
		return (
			<Grid container spacing={1}>
				<Grid item xs={12} className={classes.grid}>
					{this.getField(stateIndex, stateName, type)}
					<FormControl className={classes.formControl}>
						<TextField
							select
							value="percentage"
							label="Percentage"
							margin="dense"
							variant="outlined"
							name={field}
							id={field}
							className={classes.selectPercentageField}
							onChange={(event) => {
								this.handleChange(
									event,
									fieldId,
									stateName,
									type,
									'percentage'
								);
							}}
							value={stateIndex.percentage}
						>
							{percentageDataSet.map((index) => (
								<MenuItem key={index} value={index}>
									{index}
								</MenuItem>
							))}
							;
						</TextField>
					</FormControl>
					<p>%</p>
					<FormControl className={classes.formControl}>
						<Button
							color="secondary"
							className={classes.deletebutton}
							onClick={(event) => {
								this.removeInputField(fieldId, stateName, type);
							}}
						>
							<Icon>
								<HighlightOff />
							</Icon>
						</Button>
						<Button
							variant="contained"
							className={classes.button}
							size="medium"
							color="primary"
							onClick={() => {
								this.bucketDeleteHandler(stateIndex.bucketId);
							}}
						>
							Delete
							<DeleteIcon className={classes.rightIcon} />
						</Button>
					</FormControl>
				</Grid>
			</Grid>
		);
	};

	process = (stateName, type, bucketId) => {
		const { classes } = this.props;
		const { value } = this.state;
		return (
			<React.Fragment>
				<Grid key={UUID.v4()}>
					<Grid key={UUID.v4()}>
						<Button
							color="primary"
							onClick={(event) => {
								this.addInputField(event, stateName, type);
							}}
						>
							<Icon>
								<AddCircle />
							</Icon>
						</Button>
						{this.state[stateName].map((index) => {
							return (
								<Grid key={UUID.v4()} container spacing={1}>
									<Grid key={UUID.v4()} item xs={12}>
										<FormControl className={classes.formControl}>
											{this.percentage(index, stateName, type)}
										</FormControl>
									</Grid>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	componentDidUpdate() {
		const index = this.position;
		if (this.position) {
			// Try with and without this line in Safari or Edge to check the difference.
			this.inputRef.setSelectionRange(index, index);
			this.position = null;
		}
	}

	componentDidMount() {
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		if (role === 'user') return <CustomToolbar path="/dashboard" />;
		this.setState({ addprobability: [] });

		localStorage.setItem('title', 'Buckets');
		MetricService.getAll('SALES').then((response) => {
			const { classes } = this.props;
			const { dynamicmetrics } = this.state;
			let record = {};

			if (response.status === 'SUCCESS') {
				response.metricsDTOS.forEach((element) => {
					let records = [];
					this.setState({ [element.id]: [] });
					BucketService.get(element.id).then((response) => {
						if (response.bucketsDTOS.length > 0) {
							response.bucketsDTOS.forEach((bucket) => {
								let uuid = UUID.v4();
								record = {};
								if (bucket.type === 'Currency') {
									record = {
										percentage: bucket.percentage,
										condition: bucket.condition,
										range: bucket.fromValue,
										range_to: bucket.toValue,
										id: uuid,
										bucketId: bucket.id,
										percentage_id: 'percentage_' + uuid,
										condition_id: 'condition_' + uuid,
										range_id: 'range_' + uuid,
										range_to_id: 'range_to_' + uuid,
										datatype: bucket.type,
									};
								} else if (bucket.type === 'Priority') {
									record = {
										percentage: bucket.percentage,
										priority: bucket.condition,
										id: uuid,
										bucketId: bucket.id,
										percentage_id: 'percentage_' + uuid,
										priority_id: 'priority_' + uuid,
										datatype: bucket.type,
									};
								} else if (bucket.type === 'Customer') {
									record = {
										percentage: bucket.percentage,
										customer: bucket.name,
										name: bucket.name,
										id: uuid,
										bucketId: bucket.id,
										percentage_id: 'percentage_' + uuid,
										customer_id: 'customer_' + uuid,
										datatype: bucket.type,
									};
								}
								records.push(record);
							});
							this.setState({ [element.id]: records });
						}
					});
				});

				let myshownmetrics = response.metricsDTOS.filter((item) => {
					return !(
						item.type.toLowerCase() === 'date' ||
						item.type.toLowerCase() === 'probability'
					);
				});

				this.setState({ dynamicmetrics: myshownmetrics });
			}
		});
	}
	render() {
		const { classes } = this.props;
		const { value } = this.state;
		const Container = styled.div``;
		const Input = styled.input``;

		return (
			<React.Fragment>
				<Header title="Buckets" />
				<CssBaseline />
				<Card className={classes.card}>
					<CardHeader
						title="Bucket List"
						className={classes.cardHeadertop}
						titleTypographyProps={{ variant: 'subtitle1' }}
					/>
					<CardContent className={classes.cardContent}>
						<Typography variant="body2" component="span">
							<div className={classes.root}>
								{this.state.dynamicmetrics.map((element, index) => (
									<div key={index}>
										<Card
											className={classes.card}
											key={index}
											// onClick={() => this.handleName(element.type,element.name)}
										>
											<CardHeader
												title={element.name}
												className={classes.cardHeader}
												titleTypographyProps={{ variant: 'subtitle1' }}
											/>
											<CardContent className={classes.cardContent}>
												<Typography variant="body2" component="span">
													<Grid container spacing={1}>
														<Grid item xs={12}>
															<Fragment>
																<Grid>
																	<Grid container spacing={1}>
																		{(() => {
																			return this.process(
																				element.id,
																				element.type,
																				element.bucketId
																			);
																		})()}
																	</Grid>
																</Grid>
															</Fragment>
														</Grid>
													</Grid>
												</Typography>
											</CardContent>
											<CardActions className={classes.cardAction}></CardActions>
										</Card>
										<br></br>
									</div>
								))}
							</div>
						</Typography>
					</CardContent>
					<CardActions className={classes.cardAction}>
						<Grid item align="left" xs={6} sm={12}>
							<Button
								variant="contained"
								className={classes.button}
								size="medium"
								color="primary"
								onClick={this.handleClickCancel}
							>
								Cancel
								<HighlightOff className={classes.rightIcon} />
							</Button>

							<Button
								variant="contained"
								className={classes.button}
								size="medium"
								color="primary"
								onClick={this.handleClickSave}
							>
								Save
								<SaveIcon className={classes.rightIcon} />
							</Button>
						</Grid>
					</CardActions>
				</Card>
			</React.Fragment>
		);
	}
}

BucketComponent.propTypes = {
	classes: PropTypes.object,
};
export default withStyles(useStyles)(BucketComponent);
