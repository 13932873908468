/**
 * @author Raghav Prabhu
 * @desc User Management
 */

import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../../settings/theme';
import OpportunityService from '../../Services/api/Opportunity';
import GsheetService from '../../Services/api/Gsheet';
import CsvService from '../../Services/api/Csv';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, Typography } from '@material-ui/core';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';
import { CallEnd } from '@material-ui/icons';

class FeatureComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      myValue: false,
      tableData: [],
      searchText: "",
      loading: true,
      numberofOpp: 0,
    };
  }

  toast = ToastServive.new({
    place: "topRight",
    duration: 2,
    maxCount: 8,
  });

  onRowClick = (rowData, rowMeta) => {
    let selectedFeature = this.allFeatureDataList.filter(function (feature) {
      return feature.id == rowData[0];
    });
    selectedFeature = selectedFeature[0];

    localStorage.setItem("selectedFeature", JSON.stringify(selectedFeature));
    this.props.history.push("/submitfeature");
  };

  getData = (pageNumber, pageSize) => {
    let dataFrom = JSON.parse(localStorage.getItem("integrationList"))[0];
    if (dataFrom === "SFDC") {
      this.getOppFromSFDC(pageNumber, pageSize);
    } else if (dataFrom === "CSV") {
      this.getOppFromCSV(pageNumber, pageSize);
    } else if (dataFrom === "GSHEET") {
      this.getOppFromGsheet(pageNumber, pageSize);
    }
    let data = [];
    this.setState({ loading: false });
    localStorage.setItem("data", data);

    localStorage.setItem("title", "Opportunity");
  };

  options = {
    title: "Opportunity",
    selectableRows: false,
    print: false,
    download: false,
    //viewColumns: false,
    search: true,
    //filter: false,
    onRowClick: this.onRowClick,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 25],
    serverSide: true,
    onSearchChange: (searchText) => {
      this.setState({ searchText });
      this.searchOppFromSFDC(searchText);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          this.getData(tableState.page + 1, tableState.rowsPerPage);
          break;
        case "changeRowsPerPage":
          this.getData(tableState.page, tableState.rowsPerPage);
          break;
      }
    },
  };
  citiesMenu = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const { classes } = this.props;
          return (
            <p
              style={{
                color: "#039be5",
                cursor: "pointer",
                margin: "0px",
                textDecoration: "none",
              }}
            >
              {value}
            </p>
          );
        },
      },
    },
    // {
    //   name: "score",
    //   label: "Score",
    //   options: {
    //     display: false,
    //   },
    // },
    // {
    //   name: "rank",
    //   label: "Rank",
    //   options: {
    //     display: false,
    //   },
    // },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "probability",
      label: "Probability",
    },
    {
      name: "priority",
      label: "Priority",
    },
    {
      name: "closedate",
      label: "Close Date",
    },
    {
      name: "stageName",
      label: "State",
      options: {
        display: false,
      },
    },
  ];
  tabledata = [];
  allFeatureDataList = [];

  myuserHandler = () => {
    console.log("myuserHandlerCalled");
  };

  getOppFromSFDC(pageNumber, pageSize) {
    //		OpportunityService.getOpportunities()
    OpportunityService.getOpportunitiesByPage(pageNumber, pageSize)
      .then((response) => {
        if (response.status === "SUCCESS") {
          let itemList = response.featureRequestRanks;

          let oppList = [];
          this.allFeatureDataList = response.featureRequestRanks;
          itemList.forEach((item) => {
            let opp = {
              id: item.id,
              name: item.name,
              score: parseFloat(item.score).toFixed(2),
              rank: item.rank,
              amount: item.parameters.amount,
              probability: item.parameters.probability,
              priority: item.parameters.priority__c,
              closedate: item.parameters.closeDate,
              stageName: item.parameters.stageName,
            };
            oppList.push(opp);
            this.tabledata = oppList;
            // this.tabledata.push(opp);
          });
          this.setState({ tableData: this.tabledata });
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          this.toast.error("Please Check Your SFDC Connection Setting.");
          this.props.history.push("/settings");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.toast.error("Please Check Your SFDC Connection Setting.");
        this.props.history.push("/settings");
      });
  }
  searchOppFromSFDC(text) {
    OpportunityService.searchOpportunityByPage(text)
      .then((response) => {
        if (response.status === "SUCCESS") {
          let itemList = response.featureRequestRanks;

          let oppList = [];
          this.allFeatureDataList = response.featureRequestRanks;
          itemList.forEach((item) => {
            let opp = {
              id: item.id,
              name: item.name,
              score: parseFloat(item.score).toFixed(2),
              rank: item.rank,
              amount: item.parameters.amount,
              probability: item.parameters.probability,
              priority: item.parameters.priority__c,
              closedate: item.parameters.closeDate,
              stageName: item.parameters.stageName,
            };
            oppList.push(opp);
            this.tabledata = oppList;
            // this.tabledata.push(opp);
          });
          this.setState({ tableData: this.tabledata });
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          this.toast.error("Please Check Your SFDC Connection Setting.");
          this.props.history.push("/settings");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.toast.error("Please Check Your SFDC Connection Setting.");
        this.props.history.push("/settings");
      });
  }

  getOppFromCSV() {
    console.log("get opp from csv");
    CsvService.getOpportunities()
      .then((response) => {
        if (response.status === "SUCCESS") {
          let itemList = response.opportunity;

          this.allFeatureDataList = response.opportunity;
          itemList.forEach((item) => {
            let opp = {
              id: item.id,
              name: item.opportunityName,
              score: parseFloat(item.score).toFixed(2),
              amount: item.amount,
              probability: item.probability,
              priority: item.priority,
              closedate: item.closeDate,
              stageName: "",
            };
            this.tabledata.push(opp);
          });
          this.setState({ tableData: this.tabledata });
          this.setState({ loading: false });
        } else {
          // SFDCService.oauth_login_url().then((response) => {
          //   let url = response + "&state=/opportunities";
          //   window.location = url;
          // });
          this.setState({ loading: false });
          this.toast.error("Please Check Your SFDC Connection Setting.");
          this.props.history.push("/settings");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.toast.error("Please Check Your SFDC Connection Setting.");
        this.props.history.push("/settings");
      });
  }

  getOppFromGsheet() {
    GsheetService.getOpportunities()
      .then((response) => {
        if (response.status === "SUCCESS") {
          let itemList = response.opportunity;

          this.allFeatureDataList = response.opportunity;
          itemList.forEach((item) => {
            let opp = {
              id: item.id,
              name: item.opportunityName,
              score: parseFloat(item.score).toFixed(2),
              amount: item.amount,
              probability: item.probability,
              priority: item.priority,
              closedate: item.closeDate,
              stageName: "",
            };
            this.tabledata.push(opp);
          });
          this.setState({ tableData: this.tabledata });
          this.setState({ loading: false });
        } else {
          // SFDCService.oauth_login_url().then((response) => {
          //   let url = response + "&state=/opportunities";
          //   window.location = url;
          // });
          let errorMessage =
            response.errorMessage ||
            "Please Check Your SFDC Connection Setting.";
          this.setState({ loading: false });
          this.toast.error(errorMessage);
          this.props.history.push("/settings");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.toast.error("Please Check Your SFDC Connection Setting.");
        this.props.history.push("/settings");
      });
  }
  getTotalNumberOfOpp() {
    OpportunityService.getNumberOfTotalOpportunity()
      .then((response) => {
        console.log(response);
        this.setState({ numberofOpp: response });
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.toast.error("Please Check Your SFDC Connection Setting.");
        this.props.history.push("/settings");
      });
  }

  componentDidMount() {
    let dataFrom = JSON.parse(localStorage.getItem("integrationList"))[0];
    if (dataFrom === "SFDC") {
      this.getOppFromSFDC(1, 10);
      this.getTotalNumberOfOpp();
    } else if (dataFrom === "CSV") {
      this.getOppFromCSV();
    } else if (dataFrom === "GSHEET") {
      this.getOppFromGsheet();
    }

    let data = [];
    this.setState({ loading: false });
    localStorage.setItem("data", data);

    localStorage.setItem("title", "Opportunity");
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <Header title="Opportunity" />
          <CircularProgress
            size={70}
            left={-20}
            top={10}
            style={{ marginLeft: "50%", marginTop: "20%" }}
          />
        </>

        // </Paper>
      );
    } else {
      return (
        <>
          <Header title="Opportunity" />
          <div style={{ height: "800px" }}>
            <Paper style={{ height: "100%" }}>
              <MuiThemeProvider
                style={{ border: "5px solid red" }}
                theme={theme}
              >
                <MUIDataTable
                  title={
                    <Typography variant="h6">
                      All Opportunities
                      {this.state.loading && (
                        <CircularProgress
                          size={40}
                          style={{
                            marginLeft: 15,
                            position: "relative",
                            top: 4,
                          }}
                        />
                      )}
                    </Typography>
                  }
                  data={this.state.tableData}
                  columns={this.citiesMenu}
                  options={{ ...this.options, count: this.state.numberofOpp }}
                />
              </MuiThemeProvider>
            </Paper>
          </div>
        </>
      );
    }
  }
}

export default FeatureComponent;
