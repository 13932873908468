/**
 * @author Gaurav Sharma
 */

import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import jwt_decode from 'jwt-decode';
import RankingComponent from './RankingComponent';
import ConfigureMetricComponent from './ConfigureMetricComponent';
import MetricSummaryComponent from './MetricSummaryComponent';
import MetricService from '../../Services/api/Metric';
import MetricDashboardComponent from '../RankingComponent/Dashboard';
import LoadingOverlay from 'react-loading-overlay';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';
import CustomToolbar from '../RankingComponent/MetricToolBar';

const useStyles = (theme) => ({
	appBar: {
		position: 'relative',
	},
	stepper: {
		width: '90%',
	},
	root: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
			width: 1000,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			marginTop: theme.spacing.unit * 6,
			marginBottom: theme.spacing.unit * 6,
			padding: theme.spacing.unit * 3,
		},
	},
	instructions: {
		marginTop: theme.spacing.unit * 1,
		marginBottom: theme.spacing.unit * 1,
	},
	// loadingOverlay:{
	//   position: 'absolute',
	//   height: '100vh',
	//   width: '100vw',
	// }
});

function getSteps() {
	return ['Choose Metric Fields', 'Configure Metrics', 'Metric Summary'];
}

function getStepContent(step) {
	switch (step) {
		case 0:
			console.log('going to zero page');
			return <RankingComponent />;
		case 1:
			console.log('going to 1 page');
			return <ConfigureMetricComponent />;
		case 2:
			console.log('going to 2 page');

			return <MetricSummaryComponent />;
		default:
			return <MetricDashboardComponent />;
	}
}

class MetricHomeComponent extends Component {
	state = {
		activeStep: 0,
		loading: true,
	};

	componentDidMount() {
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		if (role === 'user') return <CustomToolbar path="/dashboard" />;
		localStorage.setItem('title', 'Metric');
		if (this.state.activeStep === 0) {
			this.setState({ loading: false });
		}
	}

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	handleChange = (input) => (event) => {
		this.setState({ [input]: event.target.value, loading: true });
	};

	checkScore = () => {
		if (this.state.activeStep === 1) {
			console.log();
			this.setState({ loading: true });
			let jsonObject = JSON.parse(sessionStorage.getItem('metrics_summary'));
			let totalWeightage = 0;
			if (jsonObject !== undefined) {
				let request = [];
				console.log('======================');
				Object.keys(jsonObject).forEach(function (key) {
					let data = jsonObject[key];
					let record = {};
					totalWeightage += data['weightage'];
					record = {
						name: data['name'],
						metricObjectId: data['id'],
						weightage: data['weightage'],
						type: data['datatype'],
					};
					console.log(data);
					request.push(record);
					// this.setState({ loading: false})
				});
				console.log('======================');
				console.log('total weightage is ' + totalWeightage);
				if (totalWeightage === 100) {
					return true;
				} else {
					this.toast.error(
						'You are already at 100%, please change the other metrics accordingly'
					);
					return false;
				}
			}
		}
	};

	handleNext = () => {
		console.log(this.state.activeStep);
		if (this.state.activeStep === 1) {
			let isValid = this.checkScore();
			console.log(isValid);
			if (!isValid) {
				this.setState({
					loading: false,
				});
				return;
			}
		}
		if (this.state.activeStep === 2) {
			this.setState({ loading: true });
			let jwtToken = localStorage.getItem('token');
			let user = jwt_decode(jwtToken);
			let jsonObject = JSON.parse(sessionStorage.getItem('metrics_submit'));
			let deleteMetricID = JSON.parse(sessionStorage.getItem('deleteMetricID'));
			let totalWeightage = 0;
			let request = {};
			if (jsonObject !== undefined) {
				let metrics = [];
				Object.keys(jsonObject).forEach(function (key) {
					let data = jsonObject[key];
					let record = {};
					totalWeightage += data['weightage'];
					record = {
						name: data['name'],
						metricObjectId: data['id'],
						weightage: data['weightage'],
						type: data['datatype'],
						tenant: user.tenant,
					};
					metrics.push(record);
					// this.setState({ loading: false})
				});
				request.metricDTOs = metrics;
				if (deleteMetricID) {
					request.deleteMetricsObjectId = deleteMetricID;
				}
				console.log('total weightage is ' + totalWeightage);
				if (totalWeightage === 100) {
					request.metricsType = 'sales';
					MetricService.create(request).then((response) => {
						sessionStorage.removeItem('metrics_summary');
						sessionStorage.removeItem('metrics_submit');
						this.setState({ loading: false });
						let url = 'metrics';

						// window.location = url;
						if (response.status === 'SUCCESS') {
							this.toast.success('Metrics Saved Successfully');
						}
						this.props.history.push('/buckets');
					});
				} else {
					this.toast.error(
						'You are already at 100%, please change the other metrics accordingly'
					);
				}
			}
		}

		this.setState((state) => ({
			activeStep: state.activeStep + 1,
			loading: false,
		}));
	};

	handleBack = () => {
		this.setState((state) => ({
			activeStep: state.activeStep - 1,
			loading: false,
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 0,
			loading: false,
		});
	};

	render() {
		const { classes } = this.props;
		const steps = getSteps();
		const { activeStep, loading } = this.state;

		return (
			<React.Fragment>
				<Header title="Metrics" />
				<form>
					<LoadingOverlay
						active={this.state.loading}
						spinner
						className={classes.loadingOverlay}
					>
						<React.Fragment>
							<CssBaseline />
							<main className={classes.root}>
								<Paper className={classes.paper}>
									<div className={classes.stepper}>
										<Stepper activeStep={activeStep}>
											{steps.map((label) => {
												return (
													<Step key={label} className={classes.step}>
														<StepLabel
															classes={{
																iconContainer: classes.iconContainer,
															}}
														>
															{label}
														</StepLabel>
													</Step>
												);
											})}
										</Stepper>
										<React.Fragment>
											<div>
												{getStepContent(activeStep)}
												<div>
													<br></br>
													<Button
														disabled={activeStep === 0}
														variant="contained"
														color="primary"
														onClick={this.handleBack}
														className={classes.button}
													>
														Back
													</Button>
													<Button
														variant="contained"
														color="primary"
														style={{ margin: '10px' }}
														onClick={this.handleNext}
														className={classes.button}
													>
														{activeStep === steps.length - 1
															? 'Finish'
															: 'Next'}
													</Button>
												</div>
											</div>
										</React.Fragment>
									</div>
								</Paper>
							</main>
						</React.Fragment>
					</LoadingOverlay>
				</form>
			</React.Fragment>
		);
	}
}

MetricHomeComponent.propTypes = {
	classes: PropTypes.object,
};
export default withStyles(useStyles)(MetricHomeComponent);
