/**
 * @author Ramya Jhey
 * @desc Organisation Edit Module
 */

import React, { Component } from 'react';
import { accountedit } from '../../settings/templates/account/accountcreation';
import AccoutService from '../../Services/api/Account';
import AddForm from '../CommonComponents/UpdateTemplate';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';

const styles = (theme) => ({
	appBar: {
		position: 'fixed',
	},
	flex: {
		flex: 1,
	},
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

/**
 * Feature Request - Organisation {account} module. This module will be
 * accessed only by SUPER Admin
 */
class AccountEdit extends Component {
	constructor(props) {
		super(props);

		this.state = { accounts: [] };
		this.state = { formdata: accountedit };
		this.state = { formdata: accountedit, open: true };
	}
	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
		this.props.history.push('/accounts');
		window.location.reload();
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});
	//Account update submit handler
	submitHandler = (data) => {
		let id = this.props.location.state['data'][0];
		let request = {
			id: id,
			name: data.layout.organisationName,
			email: data.layout.emailAddress,
			city: data.layout.city,
			officeAddress: data.layout.officeAddress,
			description: data.layout.description,
			url: data.layout.url,
		};

		AccoutService.update(id, request).then((response) => {
			this.props.history.push('/accounts');
			this.toast.success('Account updated successfully...', () => {
				console.log('closed');
			});
			// alert(JSON.stringify(response))
		});
	};

	//Account remove delete handler
	deleteHandler = (data) => {
		let stateObj = this.props.location.state;
		let accountId = stateObj['data'][0];
		AccoutService.remove(accountId).then((response) => {
			this.props.history.push('/accounts');
			this.toast.success('Account removed successfully...', () => {
				console.log('closed');
			});
			// alert(JSON.stringify(response))
		});
	};

	render() {
		const { classes } = this.props;

		if (this.props.location.state === undefined) {
			this.props.history.push('/accounts');
		}

		var stateObj = this.props.location.state;
		console.log(stateObj);
		//  var fieldForm = this.state.formdata.field
		//  let fields = this.state.formdata.field.forEach(function(element){
		//      if(element.props.id === 'organisationName') {
		//         element.props.value = stateObj['data'][2]
		//      }else if(element.props.id === 'id') {
		//         element.props.value = stateObj['data'][0]
		//      }else if(element.props.id === 'officeAddress') {
		//         element.props.value = stateObj['data'][4]
		//      }else if(element.props.id === 'city') {
		//         element.props.value = stateObj['data'][5]
		//      }else if(element.props.id === 'url') {
		//         element.props.value = stateObj['data'][7]
		//      }else if(element.props.id === 'phoneNumber') {
		//         element.props.value = stateObj['data'][8]
		//      }else if(element.props.id === 'description') {
		//         element.props.value = stateObj['data'][6]
		//      }

		//  })
		return (
			<React.Fragment>
				<Header title="Edit Accounts" />
				<Dialog
					fullScreen
					open={this.state.open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar className={classes.appBar} position="absolute">
						<Toolbar>
							<IconButton
								color="inherit"
								onClick={this.handleClose}
								aria-label="Close"
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>

					<AddForm
						formname={this.state.formdata.title}
						formdata={this.state.formdata}
						submitHandler={this.submitHandler}
						deleteHandler={this.deleteHandler}
					/>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(AccountEdit);
