/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:56:00
 * @modify date 2019-04-15 17:56:00
 * @desc ModuleComponent
 */

import React, {Component} from 'react';
import { SampleGraph } from '../'

class ModuleComponent extends Component {
    render(){
        return (
            <div>
                <SampleGraph></SampleGraph>
            </div>
        )
    }

};

export default ModuleComponent;
