import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import OpportunityService from '../../Services/api/Feature';
import OpportunityRoiService from '../../Services/api/Opportunity';
import PopupTable from '../DashboardComponent/Popuptable';
import { Route, Link } from 'react-router-dom';
import ToastServive from 'react-material-toast';
import { Breadcrumbs } from '@material-ui/core';

const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	typography: {
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
});

class FinalReportComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			totalsize: 10,
			tableData: [],
			loading: false,
			todayDate: '',
			reporttitle: 'Report',
		};
	}
	tabledata = [];

	getDataByUser = (startDate, endDate) => {
		this.setState({ loading: true });
		let tabledata = [];
		let data = {
			endDate: endDate,
			startDate: startDate,
		};
		OpportunityRoiService.getOpportunityRoi(data).then((response) => {
			console.log('output   going to be print - start');
			console.log(response);
			let itemList = response;
			itemList.forEach((item) => {
				let opp = {
					id: item.id,
					name: item.name,
					roi: item.parameters.roi,
				};
				tabledata.push(opp);
			});
			this.setState({ tableData: tabledata });
			this.setState({ loading: false });
			console.log('output   going to be print - end');
		});
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	getNumberOfAppendAsHyperLink(value, tableMeta, updateValue) {
		const open = Boolean(this.state.anchorEl);
		let { rowData } = tableMeta;
		const id = open ? 'simple-popover' : undefined;
		return <PopupTable numberofappend={value} jiraid={rowData[2]} />;
	}

	componentDidMount() {
		//{startDate: "2020-01-01", endDate: "2020-12-23", reportName: ""}
		if (this.props.reportData) {
			this.getDataByUser(
				this.props.reportData.startDate,
				this.props.reportData.endDate
			);
		} //get_opp_roi
	}

	render() {
		let options = {
			selectableRows: false,
			print: false,
			download: true,
			search: true,
			count: this.state.totalsize,
			filterType: 'textField',
			rowsPerPage: 10,
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			serverSide: false,
			downloadOptions: {
				filename: 'report.csv',
				separator: ',',
			},
		};

		return (
			<MUIDataTable
				title={
					<Typography variant="h6">
						<div>
							<Breadcrumbs aria-label="breadcrumb">
								<Link color="inherit" onClick={this.props.clickOnBackButton}>
									Report
								</Link>
								<Typography
									color="textPrimary"
									//href="/getting-started/installation/"
									// onClick={this.props.clickOnBackButton}
								>
									{this.state.reporttitle}
								</Typography>
								{/* <Typography color="textPrimary">Breadcrumb</Typography> */}

								{this.state.loading && (
									<CircularProgress
										size={24}
										style={{ marginLeft: 15, position: 'relative', top: 4 }}
									/>
								)}
							</Breadcrumbs>
							{/* <p onClick={this.props.clickOnBackButton}>
								{this.state.reporttitle}
							</p> */}
						</div>
					</Typography>
				}
				data={this.state.tableData}
				columns={this.frMyMenu}
				options={options}
			/>
		);
	}

	frMyMenu = [
		{
			name: 'id',
			label: 'ID',
			options: {
				display: false,
				filter: false,
			},
		},
		{
			label: 'Opportunity Name',
			name: 'name',
			options: {
				filter: true,
			},
		},
		{
			label: 'ROI',
			name: 'roi',
			options: {
				filter: true,
			},
		},
	];
}
export default withStyles(useStyles)(FinalReportComponent);
