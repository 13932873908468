/**
 * @author Gaurav Sharma
 * @desc Bucket management service
 */
import service from '../Common/';
import constants from '../../../settings/shared/constants';
import jwt_decode from 'jwt-decode';

/**
 * This function helps to create a new organisation/tenant
 * @param {*} bucket management
 */
function create(buckets) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(headers, buckets);
	return service({
		url: constants.api.bucket.create_list + '/',
		method: 'POST',
		headers: headers,
		data: buckets,
	});
}

/**
 * This function helps to create  product bucket
 * @param {*} bucket management
 */
function createProduct(buckets) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(headers, buckets);
	return service({
		url: constants.api.bucket.create_product_list + '/',
		method: 'POST',
		headers: headers,
		data: buckets,
	});
}

/**
 * Get all SFDC accounts
 */
function getSFDCAccounts() {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url:
			constants.api.opportunity.get_opportunity +
			'/' +
			user.tenant +
			'/sfdc/accounts',
		method: 'GET',
		headers: headers,
	});
}

function deleteBucketsByTenantId() {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.bucket.delete_bucket_by_tenant + '/' + user.tenant,
		method: 'DELETE',
		headers: headers,
	});
}

function deleteBucketsById(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(constants.api.bucket.create + '/' + id);
	return service({
		url: constants.api.bucket.create + '/' + id,
		method: 'DELETE',
		headers: headers,
	});
}

/**
 * This function helps to get organisation/tenant by id
 */
function get(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.bucket.get_by_metric + '/' + id,
		method: 'GET',
		headers: headers,
	});
}

function getByBucketId(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.bucket.create + '/' + id,
		method: 'GET',
		headers: headers,
	});
}

function getBucketByType(type, metrictype) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.bucket.get_by_type + '/' + type + '/' + metrictype + '/',
		method: 'GET',
		headers: headers,
	});
}

//Making to publish all functions
const BucketService = {
	createProduct,
	create,
	get,
	getSFDCAccounts,
	getBucketByType,
	getByBucketId,
	deleteBucketsByTenantId,
	deleteBucketsById,
};

export default BucketService;
