/**
 * @author Raghav Prabhu
 * @desc Metrics Management
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MUIDataTable from 'mui-datatables';
import MetricService from '../../Services/api/Metric';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../../settings/theme';
import moment from 'moment';
import CustomToolbar from '../RankingComponent/MetricToolBar';

import jwt_decode from 'jwt-decode';
import Header from '../CommonComponents/Header';
const metricMenu = [
	{
		name: 'id',
		options: {
			filter: false,
			display: 'excluded',
			print: false,
		},
	},
	{
		name: 'Metric Name',
		options: {
			filter: true,
		},
	},
	{
		name: 'Data Type',
		options: {
			filter: true,
		},
	},
	{
		name: 'Weightage',
		options: {
			filter: true,
		},
	},
	{
		name: 'Created By',
		options: {
			filter: true,
		},
	},
	{
		name: 'Created Date',
		options: {
			filter: true,
		},
	},
	{
		name: 'Updated Date',
		options: {
			filter: true,
			display: false,
		},
	},
	{
		name: 'Edit',
		options: {
			filter: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<div>
						<Link
							to={{
								pathname: '/productmetricedit/' + tableMeta.rowData[0],
								state: { data: tableMeta.rowData },
							}}
						>
							<IconButton color="primary" aria-label="Edit">
								<Icon>edit_icon</Icon>
							</IconButton>
						</Link>
					</div>
				);
			},
		},
	},
];

export const metricedit = {
	title: 'Edit Metric',
	buttonName: 'Update',
	field: [
		{
			id: 'metricName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'metricName',
				disabled: true,
				label: 'Metric Name',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the metric Name.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'weightage',
			type: 'textfield',
			visible: true,
			props: {
				id: 'weightage',
				label: 'Weightage',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'float',
						message: 'Please enter the valid weightage.',
						options: {
							min: 0.0,
							max: 0.9,
						},
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'type',
			type: 'selectfield',
			visible: true,
			props: {
				id: 'type',
				label: 'Data Type',
				fullWidth: true,
				style: {
					display: 'table',
				},
				selected: '',
				errortext: '',
			},
			options: [
				{
					value: null,
					primaryText: '',
				},
			],
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please select a data type.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
	],
};

class ProductMetricDashboardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = { metrics: [], rowsSelected: [], rowData: [] };
	}

	componentDidMount() {
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		if (role === 'user') return <CustomToolbar path="/dashboard" />;

		localStorage.setItem('title', 'Product Metric');
		MetricService.getAll('PRODUCT').then((response) => {
			if (response.status === 'SUCCESS') {
				let data = [];
				let metric = [];
				let metrics = response.metricsDTOS;
				console.log(metrics);

				var createdmetrics = [];
				metrics.forEach((element) => {
					let createdDate = moment(element.createdDate).format(
						'MMMM DD YYYY, h:mm:ss a'
					);
					let updatedDate = moment(element.updatedDate).format(
						'MMMM DD YYYY, h:mm:ss a'
					);
					metric = [];
					metric.push(element.id);
					metric.push(element.name);
					createdmetrics.push(element.name);
					metric.push(element.type);
					metric.push(element.weightage);
					metric.push(element.createdBy);
					// metric.push(<Moment format="DD-MMM-YYYY">{element.createdDate}</Moment>)
					metric.push(createdDate);
					metric.push(updatedDate);
					data.push(metric);
				});
				localStorage.setItem('createdmetrics', createdmetrics);
				this.setState({ metrics: data });
			} else {
			}
		});
	}

	myuserHandler = () => {
		console.log('myuserHandlerCalled');
	};

	render() {
		const options = {
			sort: true,
			filterType: 'dropdown',
			//responsive: "scroll",  // comment this line when we need to disable scroll on table
			print: false,
			filter: false,
			download: false,
			selectableRows: false,
			//  onRowClick: rowData => console.log('dfsfsg',rowData),
			onRowClick: (rowData, rowMeta) => {
				console.log('----RowClick');
				console.log('rowData: ', rowData);
				console.log('rowMeta: ', rowMeta);
			},
			// onRowsSelect: rowData => console.log('row',rowData),
			//  selectableRowsOnClick: true,
			responsive: 'stacked',
			rowsPerPage: 10,
			rowsSelected: this.state.rowsSelected,
			onRowsSelect: (rowsSelected, allRows) => {
				console.log(rowsSelected, allRows, this.state.rowData);
				this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
			},

			customToolbar: () => {
				return <CustomToolbar path="/productmetric" />;
			},
		};
		return (
			<MuiThemeProvider theme={theme}>
				<Header title="Product Metric" />
				<MUIDataTable
					title="Metrics List"
					data={this.state.metrics}
					columns={metricMenu}
					options={options}
				/>
			</MuiThemeProvider>
		);
	}
}

export default ProductMetricDashboardComponent;
