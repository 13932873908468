/**
 * @author Raghav Prabhu
 * @desc Ranking settings module
 */
import service from '../Common/';
import constants from '../../../settings/shared/constants';

/**
 * This function to get all objects fields from configured source.
 * For example, this will get SFDC field attributes, if consifured source is SFDC.
 */
function getScoreBySFDCId(sfdcid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.score.get_score_by_sfdc_id + '/' + sfdcid,
		method: 'GET',
		headers: headers,
	});
}

//http://localhost:8080/api/v1.0/score/opp/l/user/l

function getScoreByOppIdAndUser(sfdcid, userid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url:
			constants.api.score.get_score_by_sfdc_id +
			'/opp/' +
			sfdcid +
			'/user/' +
			userid,
		method: 'GET',
		headers: headers,
	});
}

//Making to publish all functions
const ScoreService = {
	getScoreBySFDCId,
	getScoreByOppIdAndUser,
};

export default ScoreService;
