/**
 * @author Ramya
 */

import React, { Component } from 'react';
import SFDCService from '../../Services/api/SFDC';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingOverlay from 'react-loading-overlay';
import { FormGenerator } from 'dynamic-material-ui';
import * as MUI from '@material-ui/core/';
import jwt_decode from 'jwt-decode';
import ToastServive from 'react-material-toast';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import IntegrationService from '../../Services/api/Integration';
import Swal from 'sweetalert2';
import Header from '../CommonComponents/Header';
const sfdcSettingsForm = {
	title: 'SalesForce Authentication Settings',
	buttonName: 'Configure Account',
	field: [
		{
			id: 'username',
			type: 'textfield',
			visible: true,
			props: {
				id: 'username',
				label: 'User Name',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter SFDC user name.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'password',
			type: 'textfield',
			visible: true,
			props: {
				id: 'password',
				label: 'Password',
				fullWidth: true,
				type: 'password',
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the SFDC password.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'clientId',
			type: 'textfield',
			visible: true,
			props: {
				id: 'clientId',
				label: 'Client ID',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the SFDC Client Id.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 10,
				},
				lg: {
					col: 10,
				},
			},
		},
		{
			id: 'clientSecret',
			type: 'textfield',
			visible: true,
			props: {
				id: 'clientSecret',
				label: 'Client Secret',
				fullWidth: true,
				type: 'password',
				value: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the SFDC client secret.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 10,
				},
				lg: {
					col: 10,
				},
			},
		},
		{
			id: 'token',
			type: 'textfield',
			visible: true,
			props: {
				id: 'token',
				label: 'Token',
				fullWidth: true,
				value: '',
				errorText: '',
				type: 'password',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the SFDC token secret.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 10,
				},
				lg: {
					col: 10,
				},
			},
		},
	],
};

const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
	paper: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '100%',
		margin: '20px',
		// margin: `${theme.spacing.unit}px auto`,
		marginTop: '30px',
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '100%',
		margin: '20px',
		// margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 5,
	},
	appBar: {
		padding: 0,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	textField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 300,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	loadingOverlay: {
		position: 'absolute',
		height: '100vh',
		width: '90vw',
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
});

class AddSfdcComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sfdcFormData: sfdcSettingsForm,
			response: [],
			request: [],
			loading: true,
			id: undefined,
			sfdcconnectionStatus: 'na',
			checking: false,
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.triggerSubmit = this.triggerSubmit.bind(this);
		// this.checkConnectionHandle = this.checkConnectionHandle.bind(this);
	}

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	triggerSubmit(data) {
		this.formRef.click();
	}

	deleteClick(data) {
		if (data !== undefined) {
			Swal.fire({
				title: 'Are you sure ?',
				text: '',
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			}).then((result) => {
				if (result.isConfirmed) {
					SFDCService.delete_auth_by_tenant(data).then((response) => {
						if (response.status === 'SUCCESS') {
							Swal.fire('SFDC account removed successfully', '', 'success');
							IntegrationService.getList().then((response) => {
								localStorage.setItem(
									'integrationList',
									JSON.stringify(response)
								);
							});
							this.props.history.push('/metrics');

							// window.location.reload();
						}
					});
				}
			});
		}
	}

	onSubmit = (data, errors, formData) => {
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		let request = {
			clientId: data.layout.clientId,
			clientSecret: data.layout.clientSecret,
			password: data.layout.password,
			tenantId: user.tenant,
			token: data.layout.token,
			username: data.layout.username,
			id: this.state.id,
		};

		if (this.state.checking) {
			this.setState({ checking: false });
			this.setState({ sfdcconnectionStatus: 'loading' });
			SFDCService.check_connection(request).then((response) => {
				if (response === true) {
					this.setState({ sfdcconnectionStatus: 'true' });
				} else {
					this.setState({ sfdcconnectionStatus: 'false' });
				}
			});
		} else {
			SFDCService.create_auth(request).then((response) => {
				if (response.status === 'SUCCESS') {
					this.toast.success('SFDC account configured successfully', () => {
						console.log('closed');
					});
					IntegrationService.getList().then((response) => {
						localStorage.setItem('integrationList', JSON.stringify(response));
						let user = jwt_decode(jwtToken);
						let { roles } = user;
						console.log(roles);
						console.log(roles.indexOf('ADMIN'));
						if (roles.indexOf('ADMIN') === -1) {
							this.props.history.push('/opportunities');
						} else {
							this.props.history.push('/metrics');
						}
					});

					// window.location.reload();
				} else {
					this.toast.error(response.errorMessage, () => {
						console.log('closed');
					});
				}
			});
		}
	};

	checkConnectionHandle = async () => {
		await this.setState({ checking: true });
		this.formRef.click();
	};

	componentDidMount() {
		localStorage.setItem('title', 'SFDC settings');
		this.setState({ loading: false });
		SFDCService.get_auth_by_tenant().then((response) => {
			if (response.status === 'SUCCESS') {
				this.setState({ id: response.authConfigDO.id });
				this.state.sfdcFormData.field.forEach(function (element) {
					if (element.id === 'username') {
						element.props.value = response.authConfigDO.username;
					} else if (element.id === 'clientId') {
						element.props.value = response.authConfigDO.clientId;
					} else if (element.id === 'clientSecret') {
						element.props.value = response.authConfigDO.clientSecret;
					} else if (element.id === 'password') {
						element.props.value = response.authConfigDO.password;
					} else if (element.id === 'token') {
						element.props.value = response.authConfigDO.token;
					}
				});
				this.setState({ sfdcFormData: this.state.sfdcFormData });
			} else {
			}
		});
	}

	render() {
		const { classes } = this.props;
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		let role = undefined;
		if (user.roles !== undefined) {
			role = user.roles[0];
		}
		let isDisable = false;
		if (role === 'user') isDisable = true;
		return (
			<React.Fragment>
				<Header title="SFDC settings" />

				<LoadingOverlay
					active={this.state.loading}
					spinner
					className={classes.loadingOverlay}
				>
					<Grid item>
						<Paper className={classes.paper1}>
							<Grid container spacing={8}>
								<Toolbar>
									<Typography variant="h6" color="inherit">
										{this.state.sfdcFormData.title}
									</Typography>
								</Toolbar>
								<FormGenerator
									guid="layout"
									data={this.state.sfdcFormData.field}
									displayErrors={this.state.displayErrors}
									library={MUI}
									formRef={(form) => {
										this.formRef = form;
									}}
									onSubmit={this.onSubmit}
								/>
							</Grid>
							<br></br>
							<Grid item align="center" xs={6} sm={12}>
								<br></br>
								<Button
									color="primary"
									variant="contained"
									className={classes.button}
									onClick={this.checkConnectionHandle}
									disabled={isDisable}
								>
									Check Connection
									{/* <SaveIcon className={classes.rightIcon} /> */}
									{this.state.sfdcconnectionStatus === 'true' && (
										<CheckCircleOutlineIcon />
									)}
									{this.state.sfdcconnectionStatus === 'false' && (
										<CancelIcon />
									)}
									{this.state.sfdcconnectionStatus === 'loading' && (
										<CircularProgress size={24} />
									)}
								</Button>
								<Button
									color="primary"
									variant="contained"
									className={classes.button}
									// style={{ align: "center", marginTop: 30 }} align="center"
									onClick={() => this.deleteClick(this.state.id)}
									disabled={isDisable}
								>
									Delete
									<DeleteIcon className={classes.rightIcon} />
								</Button>

								<Button
									color="primary"
									variant="contained"
									className={classes.button}
									// style={{ align: "center", marginTop: 30 }} align="center"
									onClick={this.triggerSubmit}
									disabled={isDisable}
								>
									Save
									<SaveIcon className={classes.rightIcon} />
								</Button>
							</Grid>
						</Paper>
					</Grid>
				</LoadingOverlay>
			</React.Fragment>
		);
	}
}

export default withStyles(useStyles)(AddSfdcComponent);
