/**
 * @author Raghav Prabhu
 * @description Feature APIs
 */

import service from '../Common/';
import constants from '../../../settings/shared/constants';

function submitFeature(features) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(headers, features);
	return service({
		url: constants.api.feature.submit_features + '/',
		method: 'POST',
		headers: headers,
		data: features,
	});
}

/**
 * Get feature by ID
 * @param {*} featureId
 */
function getFeatureById(featureId) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.feature.get_feature_by_source_id + '/' + featureId,
		method: 'GET',
		headers: headers,
	});
}

function getFeatures() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.feature.submit_features + '/',
		method: 'GET',
		headers: headers,
	});
}

function deleteFeaturesByJiraId(jiraIds) {
	console.log('in service');
	console.log(jiraIds);
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	// jiraids=poop&jiraids=poo&jiraids=ooo&jiraids=pppp
	let jiraIdsString = '';
	for (let i = 0; i < jiraIds.length; i++) {
		if (i == 0) jiraIdsString += 'jiraids=' + jiraIds[i];
		else jiraIdsString += '&jiraids=' + jiraIds[i];
	}
	return service({
		url:
			constants.api.feature.delete_submit_features_by_jiraI_id +
			'?' +
			jiraIdsString,
		method: 'DELETE',
		headers: headers,
	});
}

//Making to publish all functions
const FeatureService = {
	submitFeature,
	getFeatureById,
	getFeatures, //, get, getAll, remove
	deleteFeaturesByJiraId,
};

export default FeatureService;
