export const reportType = [
	{
		name: 'Submitted',
		desc: 'Report Desc 1',
		insights: 'insights 1',
		value: 'submitted',
	},
	{
		name: 'In Progress',
		desc: 'Report Desc 2',
		insights: 'insights 2',
		value: 'In Progress',
	},
	{
		name: 'Done',
		desc: 'Report Desc 3',
		insights: 'insights 3',
		value: 'done',
	},
	{
		name: 'Rejected',
		desc: 'Report Desc 4',
		insights: 'insights 4',
		value: 'rejected',
	},
	{
		name: 'Deleted',
		desc: 'Report Desc 5',
		insights: 'insights 5',
		value: 'delete',
	},
	{
		name: 'Opportunities Open',
		desc: 'Report Desc 5',
		insights: 'insights 5',
		value: 'all',
		oppValue: 'Open',
	},
	{
		name: 'Opportunities Won',
		desc: 'Report Desc 5',
		insights: 'insights 5',
		value: 'all',
		oppValue: 'Won',
	},
	{
		name: 'Opportunities Lost',
		desc: 'Report Desc 5',
		insights: 'insights 5',
		value: 'all',
		oppValue: 'Lost',
	},
];
