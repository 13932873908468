/**
 * @author Gaurav  Sharma
 * @desc Submit Feature
 */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import JiraAccountService from '../../Services/api/JiraAccount';
import SFDCService from '../../Services/api/SFDC';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import TableContainer from '@material-ui/core/TableContainer';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ToastServive from 'react-material-toast';
import AppendFeature from './AppendFeature';
import BucketService from '../../Services/api/Bucket';
import ScoreService from '../../Services/api/Score';
import AccountType from '../../Services/api/accountType';
import OpportunityService from '../../Services/api/Opportunity';
import NotesService from '../../Services/api/Notes';
import jwt_decode from 'jwt-decode';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Header from '../CommonComponents/Header';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../../settings/theme';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const styles = (theme) => ({
	appBar: {
		position: 'fixed',
	},
	flex: {
		flex: 1,
	},
});
function Transition(props) {
	return <Slide direction="up" {...props} />;
}
function ValueLabelComponent(props) {
	const { children, open, value } = props;
	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
			{children}
		</Tooltip>
	);
}
ValueLabelComponent.propTypes = {
	children: PropTypes.element.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.number.isRequired,
};
const useStyles = (theme) => ({
	// appBar: {
	//   position: 'relative',
	// },
	root: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(200 + theme.spacing(3 * 2))]: {
			width: 'auto',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	paper: {
		marginTop: theme.spacing(1),
		padding: '18px',
	},
	paper2: {
		marginTop: theme.spacing(0.25),
		padding: '18px',
	},
	tabletitle: {
		color: 'black',
		fontWeight: 700,
	},
	table: {
		width: '100%',
	},
	cardHeader: {
		backgroundColor: '#ededed',
		borderBottom: '1px solid #ededed',
	},
	cardHeadertop: {
		backgroundColor: '#fff',
		borderBottom: '1px solid #ededed',
		borderTop: '2px solid #0288d1',
	},
	card: {
		minWidth: 75,
		fontSize: '1rem',
	},
	selectField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 150,
	},
});
function createData(
	Body,
	Title,
	Action,
	isSubmitted,
	project,
	issueType,
	key,
	id,
	createdBy,
	modifiedDate,
	frid,
	score
) {
	modifiedDate = moment(modifiedDate).format('DD-MMM-YYYY, hh:mm A');
	score = parseFloat(score).toFixed(2);
	return {
		Body,
		Title,
		Action,
		isSubmitted,
		project,
		issueType,
		key,
		id,
		createdBy,
		modifiedDate,
		frid,
		score,
	};
}
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
class SubmitFeatureSFDC extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sfdcAccountById: '',
			created_user: '',
			updated_user: '',
			jiraProjectList: [],
			jiraIssueTypeList: [],
			project: '',
			issueType: '',
			oppScore: '',
			oppRoi: '',
			oppRank: '',
			isSubmitted: false,
			value: 0,
			setValue: 0,
			open: false,
			setOpen: false,
			submitfeatures: [],
			dialogopen: false,
			feature: {},
			isAccountAdded: false,
			submitRequest: {},
			accountname: '',
			accountBucketList: [],
			accountBucketName: '',
			sfdcNotesList: [],
			historyMenu: [],
			historyData: [],
			isHistoryShow: false,
		};
	}
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});
	handleClickOpen = () => {
		this.setState({ dialogopen: true });
	};
	handleClose = () => {
		this.setState({ dialogopen: false });
	};
	selectedFeatureWithJiraDetails(key) {
		let features = JSON.parse(localStorage.getItem('featureDetailsWithJira'));
		let myfeature = features.filter((feature) => feature.id === key);
		if (myfeature.length > 0) {
			localStorage.setItem('isupdate', 'false');
			localStorage.setItem(
				'selectedFeatureWithJiraDetails',
				JSON.stringify(myfeature[0])
			);
			this.props.history.push('/selectedfeature');
		}
	}
	updateFeatureJiraDetails(key, isupdate) {
		let features = JSON.parse(localStorage.getItem('featureDetailsWithJira'));
		let myfeature = features.filter((feature) => feature.id === key);
		if (myfeature.length > 0) {
			localStorage.setItem(
				'selectedFeatureWithJiraDetails',
				JSON.stringify(myfeature[0])
			);
			localStorage.setItem('isupdate', 'true');
			this.props.history.push('/selectedfeature');
		}
	}
	componentWillUnmount() {
		// localStorage.removeItem("selectedFeature")
		localStorage.removeItem('featureDetailsWithJira');
	}
	submitfeatures = [];

	getOppHistroy() {
		let data = JSON.parse(localStorage.getItem('selectedFeature'));
		OpportunityService.getHistoryByOppId(data.id).then((response) => {
			const jsonResponse = JSON.parse(response.histryDetails);
			let historyMenu = [];
			if (jsonResponse.records.length > 0) {
				const keys = Object.keys(jsonResponse.records[0]);
				keys.map((item) => {
					if (!(item === 'attributes' || item.includes('Id'))) {
						let obj = { name: item, label: item };
						historyMenu.push(obj);
					}
				});

				this.setState({
					historyMenu: historyMenu,
					isHistoryShow: true,
					historyData: jsonResponse.records,
				});
			}
		});
	}

	componentDidMount() {
		let data = JSON.parse(localStorage.getItem('selectedFeature'));
		let accountName = data.parameters.account.Name;
		NotesService.getNotesByOppId(data.id).then((response) => {
			this.setState({ sfdcNotesList: response });
		});
		ScoreService.getScoreBySFDCId(data.id).then((response) => {
			if (response.status === 'SUCCESS' && response.scores.length > 0) {
				this.setState({
					oppRank: response.scores[0].rank,
					oppScore: parseFloat(response.scores[0].otScore).toFixed(2),
					oppRoi: response.scores[0].roi,
				});
			}
		});
		AccountType.getAllByTenantId().then((response) => {
			if (response) {
				for (let i = 0; i < response.length; i++) {
					if (
						accountName.toLowerCase() ===
						response[i]['accountName'].toLowerCase()
					) {
						BucketService.getByBucketId(response[i]['bucket']).then(
							(bucketresponse) => {
								this.setState({
									isAccountAdded: true,
									accountBucketName: bucketresponse.name,
								});
							}
						);
					}
				}
			}
		});
		BucketService.getBucketByType('customer', 'sales').then((response) => {
			if (response.status === 'SUCCESS') {
				this.setState({ accountBucketList: response.bucketsDTOS });
			}
		});
		if (data !== null) {
			SFDCService.get_sfdc_account_by_id(data.parameters.accountId).then(
				(response) => {
					this.setState({ sfdcAccountById: response });
				}
			);
			JiraAccountService.getFeaturesWithJiraDetails(data.id).then(
				(response) => {
					localStorage.setItem(
						'featureDetailsWithJira',
						JSON.stringify(response)
					);
					this.setState({ submitfeatures: response });
				}
			);
		} else {
			this.props.history.push('/opportunities');
		}
		JiraAccountService.getAllProjects().then((response) => {
			if (response != null) {
				let projects = [];
				for (let index = 0; index < response.length; index++) {
					projects.push(response[index]);
				}
				this.setState({ jiraProjectList: projects });
			}
		});
		JiraAccountService.getAllIssueType().then((response) => {
			if (response != null) {
				let issueTypes = [];
				for (let index = 0; index < response.length; index++) {
					issueTypes.push(response[index]);
				}
				this.setState({ jiraIssueTypeList: issueTypes });
			}
		});
	}
	featureSubmitHandler(
		feature,
		Title,
		Body,
		issueType,
		project,
		score,
		rank,
		noteid,
		oppUserId
	) {
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		this.setState({
			feature: {
				feature: feature,
				title: Title,
				body: Body,
				issueType: issueType,
				project: project,
				score: parseFloat(score).toFixed(2),
				rank: rank,
				noteid: noteid,
				oppUserId: user.id,
			},
		});
		let submitRequest = {
			featureRequestRank: feature,
			title: Title,
			body: Body,
			salesforceNoteId: noteid,
			description: ' ',
			score: parseFloat(score).toFixed(2),
			oppUserId: user.id,
		};
		this.setState({
			dialogopen: true,
			submitRequest: submitRequest,
		});
	}
	changeDialogBoxState = () => {
		console.log('i am change dialog box method ');
		this.setState({ dialogopen: false });
	};

	handleBackButton = () => {
		this.props.history.push('/opportunities');
	};

	featureRejecthandler(feature) {
		alert('featureRejecthandler called.');
	}
	handleChange = (name) => (event) => {
		if (name === 'projects') {
			this.setState({ project: event.target.value });
		} else if (name === 'issueType') {
			this.setState({ issueType: event.target.value });
		}
	};
	accountHandler(event) {
		this.setState({ accountname: event.target.value });
	}
	frClickHandler(fr) {
		this.props.history.push('/selectfeature/' + fr);
	}
	addAccountHandler() {
		let data = JSON.parse(localStorage.getItem('selectedFeature'));
		let account = {
			accountName: data.parameters.account.Name,
			bucket: this.state.accountname,
			oppId: data.id,
		};
		if (this.state.accountname === undefined || this.state.accountname === '') {
			this.toast.error('Please Select Account type');
		} else {
			AccountType.create(account).then((response) => {
				this.toast.success('Account type added successfully');
				window.location.reload();
			});
		}
	}

	render() {
		console.log(this.state.historyData);
		console.log(this.state.historyMenu);
		const { classes } = this.props;
		let data = JSON.parse(localStorage.getItem('selectedFeature'));
		let notes = this.state.sfdcNotesList;
		let rows = [];
		let featureDetailsWithJira = JSON.parse(
			localStorage.getItem('featureDetailsWithJira')
		);
		if (featureDetailsWithJira && featureDetailsWithJira.length > 0) {
			for (let k = 0; k < notes.length; k++) {
				let isAvailable = false;
				let newTitle = null;
				let newBody = null;
				let newSalesforceNotesid = notes[k].id;
				let createby = notes[k].owner;

				let modifiedDate = notes[k].lastModifiedDate;
				//notes.forEach(note => {
				for (let fe = 0; fe < featureDetailsWithJira.length; fe++) {
					newTitle = notes[k].title;
					newBody = notes[k].body;
					newSalesforceNotesid = notes[k].id;
					console.log(notes[k]);
					console.log(featureDetailsWithJira[fe]);
					if (featureDetailsWithJira[fe].salesforceNotesId === notes[k].id) {
						rows.push(
							createData(
								newTitle,
								newBody,
								'Edit',
								true,
								featureDetailsWithJira[fe].jiraDetails.projectId,
								featureDetailsWithJira[fe].jiraDetails.issueTypeId,
								featureDetailsWithJira[fe].id,
								newSalesforceNotesid,
								createby,
								modifiedDate,
								featureDetailsWithJira[fe].customFeatureId,
								parseFloat(featureDetailsWithJira[fe].score).toFixed(2)
							)
						);
						isAvailable = true;
						break;
					}
				}
				if (!isAvailable) {
					rows.push(
						createData(
							newTitle,
							newBody,
							'Submit',
							false,
							null,
							null,
							newSalesforceNotesid,
							notes[k].id,
							createby,
							modifiedDate,
							null,
							null
						)
					);
					isAvailable = false;
				}
			}
		} else {
			for (let k = 0; k < notes.length; k++) {
				rows.push(
					createData(
						notes[k].title,
						notes[k].body,
						'Submit',
						false,
						null,
						null,
						notes[k].id,
						notes[k].id,
						notes[k].owner,
						notes[k].lastModifiedDate,
						null,
						null
					)
				);
			}
		}
		return (
			<div>
				<Header title="Submit Feature" />
				<Dialog
					fullScreen
					open={this.state.dialogopen}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar className={classes.appBar} position="absolute">
						<Toolbar>
							<IconButton
								color="inherit"
								onClick={this.handleClose}
								aria-label="Close"
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<AppendFeature
						feature={this.state.feature}
						score={this.state.oppScore}
						rank={this.state.oppRank}
						submitdetails={this.state.submitRequest}
						changeDialogBoxState={this.changeDialogBoxState}
					/>
				</Dialog>
				<Card className={classes.card}>
					<Tooltip title={this.props.tooltip}>
						<IconButton
							onClick={() => {
								this.handleBackButton();
							}}
						>
							<KeyboardBackspaceIcon />
						</IconButton>
					</Tooltip>

					<CardHeader
						title="Feature Details"
						className={classes.cardHeadertop}
						titleTypographyProps={{ variant: 'subtitle1' }}
					></CardHeader>
					<CardContent className={classes.cardContent}>
						<Paper className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={9}>
									<Paper className={classes.paper}>
										<FormLabel component="legend">
											<b>Title : {data.name}</b>
										</FormLabel>
									</Paper>
								</Grid>

								<Grid item xs={2}>
									<Paper className={classes.paper}>
										<Typography variant="body2" gutterBottom>
											<b>ROI:</b> {this.state.oppRoi}
										</Typography>
									</Paper>
								</Grid>
								<Grid item xs={1}>
									<Button
										variant="contained"
										color="primary"
										style={{
											marginTop: '15px',
										}}
										onClick={() => this.getOppHistroy()}
									>
										History
									</Button>
								</Grid>
								{this.state.isHistoryShow && (
									<Grid item xs={12}>
										<MuiThemeProvider theme={theme}>
											<MUIDataTable
												title={'Opportunity History'}
												data={this.state.historyData}
												columns={this.state.historyMenu}
											/>
										</MuiThemeProvider>
									</Grid>
								)}
								{/* {this.state.oppScore == 0 && (
									<Grid item xs={3}>
										<Paper className={classes.paper}>
											<Typography variant="body2" gutterBottom>
												Score: Not available, Select account type to generate
												score
											</Typography>
										</Paper>
									</Grid>
								)} */}
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Paper className={classes.paper}>
										<FormLabel component="legend">
											<b>Description : </b>
										</FormLabel>
										<br></br>
										<FormControl
											fullWidth
											className={classes.margin}
											variant="outlined"
										>
											<InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
											<OutlinedInput
												id="outlined-adornment-desc"
												value={data.description}
												onChange={this.handleChange}
												disabled
												labelWidth={60}
											/>
										</FormControl>
									</Paper>
								</Grid>
							</Grid>
						</Paper>
						<Paper className={classes.paper}>
							<FormLabel component="legend">Account Specific Details</FormLabel>{' '}
							<br />
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<label id="demo-simple-select-label">Account Name</label>
								</Grid>
								<Grid item xs={3}>
									<FormControl
										variant="outlined"
										className={classes.formControl}
									>
										<InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
										<OutlinedInput
											id="outlined-adornment-account"
											value={data.parameters.account.Name}
											onChange={this.handleChange}
											labelWidth={10}
										/>
									</FormControl>
								</Grid>
								{this.state.isAccountAdded && (
									<Grid item xs={3}>
										<label id="demo-simple-select-label">Customer Type</label>
									</Grid>
								)}
								{this.state.isAccountAdded && (
									<Grid item xs={3}>
										<FormControl
											variant="outlined"
											className={classes.formControl}
										>
											<InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
											<OutlinedInput
												id="outlined-adornment-accounttype"
												value={this.state.accountBucketName}
												onChange={this.handleChange}
												labelWidth={10}
											/>
										</FormControl>
									</Grid>
								)}
								{!this.state.isAccountAdded && (
									<Grid item xs={3}>
										{/* // drop down here */}
										<TextField
											select
											value="Customer Type"
											label="Customer Type"
											name="customerType"
											margin="dense"
											variant="outlined"
											id="customerType"
											onChange={(event) => {
												this.accountHandler(event);
											}}
											value={this.state.accountname}
											className={classes.selectField}
										>
											{this.state.accountBucketList.map((item) => (
												<MenuItem key={item.id} value={item.id}>
													{item.name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}
								{!this.state.isAccountAdded && (
									<Grid item xs={3}>
										<Button
											variant="contained"
											color="primary"
											onClick={() => this.addAccountHandler()}
										>
											Add Account
										</Button>
									</Grid>
								)}
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<label id="demo-simple-select-label">Probability</label>
								</Grid>
								<Grid item xs={3}>
									<InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
									<OutlinedInput
										id="outlined-adornment-probability"
										value={data.parameters.probability}
										onChange={this.handleChange}
										labelWidth={10}
									/>
								</Grid>
								<Grid item xs={3}>
									<label id="demo-simple-select-label">Priority</label>
								</Grid>
								<Grid item xs={3}>
									<OutlinedInput
										id="outlined-adornment-priroty"
										value={data.parameters.priority__c}
										onChange={this.handleChange}
										labelWidth={10}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<label id="demo-simple-select-label">Time Needed By</label>
								</Grid>
								<Grid item xs={3}>
									<OutlinedInput
										id="outlined-adornment-time"
										value={data.parameters.closeDate}
										onChange={this.handleChange}
										labelWidth={10}
									/>
								</Grid>
								<Grid item xs={3}>
									<label id="demo-simple-select-label">Amount</label>
								</Grid>
								<Grid item xs={3}>
									<OutlinedInput
										id="outlined-adornment-amount"
										value={data.parameters.amount}
										onChange={this.handleChange}
										labelWidth={10}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<label id="demo-simple-select-label">State</label>
								</Grid>
								<Grid item xs={3}>
									<OutlinedInput
										id="outlined-adornment-time"
										value={data.parameters.stageName}
										onChange={this.handleChange}
										labelWidth={10}
									/>
								</Grid>
							</Grid>
						</Paper>
						<Paper className={classes.paper2}>
							<FormLabel component="legend">Opportunity Notes</FormLabel> <br />
							<TableContainer component={Paper}>
								<Table className={classes.table} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.tabletitle}>
												FR-ID
											</TableCell>
											<TableCell className={classes.tabletitle}>
												Title
											</TableCell>
											{/* <TableCell className={classes.tabletitle}>
                        Description
                      </TableCell> */}
											<TableCell className={classes.tabletitle}>
												Created By
											</TableCell>
											<TableCell className={classes.tabletitle}>
												Modified Date
											</TableCell>
											<TableCell className={classes.tabletitle}>
												Project
											</TableCell>
											<TableCell className={classes.tabletitle}>
												Issue Type
											</TableCell>
											<TableCell className={classes.tabletitle}>
												OT Score
											</TableCell>
											{this.state.oppScore != 0 && (
												<TableCell className={classes.tabletitle}>
													Action
												</TableCell>
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row) => (
											<TableRow key={row.key}>
												<TableCell
													onClick={() => this.frClickHandler(row.frid)}
												>
													<p
														style={{
															color: '#039be5',
															cursor: 'pointer',
															textDecoration: 'none',
														}}
													>
														{row.frid}
													</p>

													{/* <a href="/">{row.frid}</a> */}
												</TableCell>
												<TableCell
													onClick={() =>
														this.selectedFeatureWithJiraDetails(row.key)
													}
												>
													{row.Body}
												</TableCell>
												{/* </Link> */}
												{/* <TableCell>{row.Title}</TableCell> */}
												<TableCell>{row.createdBy}</TableCell>
												<TableCell>{row.modifiedDate}</TableCell>
												{row.isSubmitted && (
													<TableCell>{row.project}</TableCell>
												)}
												{!row.isSubmitted && <TableCell> </TableCell>}
												{row.isSubmitted && (
													<TableCell>{row.issueType}</TableCell>
												)}
												{!row.isSubmitted && <TableCell> </TableCell>}
												{row.isSubmitted && <TableCell>{row.score}</TableCell>}
												{!row.isSubmitted && <TableCell> </TableCell>}
												<TableCell>
													{row.Action === 'Submit' && this.state.oppScore != 0 && (
														<Button
															size="small"
															variant="contained"
															color="primary"
															onClick={() =>
																this.featureSubmitHandler(
																	data,
																	row.Body,
																	row.Title,
																	this.state.issueType,
																	this.state.project,
																	this.state.oppScore,
																	data.rank,
																	row.id,
																	data.oppUserId
																)
															}
														>
															{row.Action}
														</Button>
													)}
													{row.Action === 'Edit' && this.state.isAccountAdded && (
														<Button
															size="small"
															variant="contained"
															color="primary"
															onClick={() =>
																this.updateFeatureJiraDetails(row.key, 'true')
															}
														>
															{row.Action}
														</Button>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</CardContent>
				</Card>
			</div>
		);
	}
}
export default withStyles(useStyles)(SubmitFeatureSFDC);
