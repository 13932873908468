/**
 * @author Gaurav Sharma
 * @desc Organisation management service
 */
import service from "../Common/";
import constants from "../../../settings/shared/constants";
import jwt_decode from "jwt-decode";

function getNotesByOppId(id) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  console.log("in header start nootes service");
  console.log(headers);
  console.log("in header end nootes service");
  return service({
    url: constants.api.sfdcnotes.get_sfdc_nodes_by_opp_id + "/" + id,
    method: "GET",
    headers: headers,
  });
}

const NotesService = {
  getNotesByOppId,
};

export default NotesService;
