/**
 * @author Ramya Jhey
 * @desc Color theme constant - https://material-ui.com/style/color/
 */

import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
	overrides: {
		MuiTableCell: {
			head: {
				paddingTop: '.75rem',
				paddingBottom: '.75rem',
				fontSize: '.65rem',
				textTransform: 'uppercase',
				letterSpacing: 1,
			},
			root: {
				fontSize: '.8125rem',
				whiteSpace: 'nowrap',
			},
		},
	},
	palette: {
		type: 'light',
		primary: {
			main: '#039be5',
		},
		secondary: {
			main: '#f06292',
		},
	},
	typography: {
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 10,
		fontWeight: 'normal',
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
	},
	body: {
		fontFamily: '"Open Sans", sans-serif',
		fontSize: 10,
		fontWeight: 'normal',
	},
});

export default theme;
