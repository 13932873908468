import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import JiraAccountService from '../../Services/api/JiraAccount';
import { CircularProgress, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PopupTable from './Popuptable';
import jwt_decode from 'jwt-decode';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Badge from '@material-ui/core/Badge';
import DashboardService from '../../Services/api/Dashboard';
import { amber, green } from '@material-ui/core/colors';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import Paper from '@material-ui/core/Paper';
import LoginService from '../../Services/api/Login';
import ToastServive from 'react-material-toast';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Header from '../CommonComponents/Header';
// import SweetAlert from 'sweetalert2-react';

import Swal from 'sweetalert2';
import FeatureService from '../../Services/api/Feature';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	margin: {
		margin: theme.spacing(1),
	},
	paper: {
		height: 140,
		width: 150,
		// marginTop: '70px',
	},
	paper1: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	control: {
		padding: theme.spacing(2),
	},
	depositContext: {
		flex: 1,
	},
	submittedmargin: {
		margin: theme.spacing(1),
		minWidth: 130,
		backgroundColor: '#039be5',
		justifyContent: 'center',
	},
	approvedmargin: {
		margin: theme.spacing(1),
		minWidth: 130,
		backgroundColor: green[600],
		justifyContent: 'center',
	},
	rejectedmargin: {
		margin: theme.spacing(1),
		minWidth: 130,
		backgroundColor: theme.palette.error.dark,
		justifyContent: 'center',
	},
	divider: {
		margin: theme.spacing(2, 0),
		width: '100%',
	},
	breadcrumbs: {
		paddingLeft: 35,
	},
	dollarbadge: {
		paddingRight: '10px',
	},
	chartcss: {
		height: '300px !important',
	},
	table: {
		minWidth: 650,
	},
	tabletitle: {
		color: 'black',
		fontWeight: 700,
	},
	reportlink: {
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
});

class OpportunityComponent extends React.Component {
	constructor(props) {
		super(props);
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		this.state = {
			value: 0,
			data: [],
			columns: [],
			options: {},
			show: true,
			title: 'All Feature',
			targetItem: undefined,
			myOtSubmittedCount: 0,
			myOtSubmittedAmount: 0,
			myOtApprovedCount: 0,
			myOtApprovedAmount: 0,
			myOtRejectedCount: 0,
			myOtRejectedAmount: 0,
			allOtSubmittedCount: 0,
			allOtSubmittedAmount: 0,
			allOtApprovedCount: 0,
			allOtApprovedAmount: 0,
			allOtRejectedCount: 0,
			allOtRejectedAmount: 0,
			dashboardTable: undefined,
			featureDetails: [],
			myfeatureDetails: [],
			myAmountDetails: [],
			myAmountDetailsByTenant: [],
			anchorEl: null,
			loading: true,
			totalsize: 10,
			role: role,
			isTenent: false,
			isUser: true,
		};
	}
	allFeatureDataList = [];
	tabledata = [];

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	showMissingJiraMessage = () => {
		Swal.fire('Any fool can use a computer');
	};

	getData = (pageindex, pagesize) => {
		this.setState({ loading: true });
		this.tabledata = [];
		JiraAccountService.getJiraIssueByTenent(pageindex, pagesize).then(
			(response) => {
				if (response.status === 'SUCCESS') {
					let itemList = response.featurelist;
					this.allFeatureDataList = response.featurelist;
					this.setState({ totalsize: response.totalsize });
					itemList.forEach((item) => {
						let opp = {
							id: item.id,
							jiraid: item.destinationId,
							name: item.jiraDetails.issueName,
							score: parseFloat(item.score).toFixed(2),
							salesScore: parseFloat(item.salesScore).toFixed(2),
							productScore: parseFloat(item.productScore).toFixed(2),
							rank: item.rank,
							createdby: item.createdBy,
							numberOfAppend: item.numberOfAppend,
							jiraStatus: item.jiraStatus,
						};
						this.tabledata.push(opp);
					});
					//"missingJira":["OTPROD-225"]
					if (response.missingJira && response.missingJira.length > 0) {
						Swal.fire({
							title: 'Missing Jira!',
							text: 'Jira Id ' + response.missingJira + ' missing..',
							icon: 'warning',
							confirmButtonColor: '#3085d6',
							confirmButtonText: 'Ok',
						});
					}
					this.getDashBoardData();
					this.setState({ tableData: this.tabledata });
					this.setState({ totalsize: response.totalsize });
					this.setState({ loading: false });
				}
			}
		);
	};

	getDataByUser = (pageindex, pagesize) => {
		this.setState({ loading: true });
		this.tabledata = [];
		JiraAccountService.getJiraIssueByUser(pageindex, pagesize).then(
			(response) => {
				if (response.status === 'SUCCESS') {
					let itemList = response.featurelist;
					this.allFeatureDataList = response.featurelist;
					this.setState({ totalsize: response.totalsize });
					itemList.forEach((item) => {
						let opp = {
							id: item.id,
							jiraid: item.destinationId,
							name: item.jiraDetails.issueName,
							score: parseFloat(item.score).toFixed(2),
							salesScore: parseFloat(item.salesScore).toFixed(2),
							productScore: parseFloat(item.productScore).toFixed(2),
							rank: item.rank,
							createdby: item.createdBy,
							numberOfAppend: item.numberOfAppend,
							jiraStatus: item.jiraStatus,
						};
						this.tabledata.push(opp);
					});
					//"missingJira":["OTPROD-225"]
					if (response.missingJira && response.missingJira.length > 0) {
						Swal.fire({
							title: 'Missing Jira!',
							text:
								'Jira Id ' +
								response.missingJira +
								' seems to be deleted. Corresponding FR will be delinked.',
							icon: 'warning',
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Ok',
						}).then((result) => {
							if (result.isConfirmed) {
								console.log(response.missingJira);
								FeatureService.deleteFeaturesByJiraId(
									response.missingJira
								).then((deleteResponse) => {
									if (deleteResponse === true) {
										Swal.fire(
											'Delinked!',
											// if we can grep the FR list. show FR here.
											'Your FR has been delinked.',
											'success'
										);
									}
								});
							}
						});
					}
					this.getDashBoardData();
					this.setState({ tableData: this.tabledata });
					this.setState({ totalsize: response.totalsize });
					this.setState({ loading: false });
				}
			}
		);
	};

	getDashBoardData() {
		DashboardService.getFeatureDetails().then((response) => {
			console.log('=============================');
			console.log(response);
			this.setState({ featureDetails: response });
			console.log('=============================');
		});
		DashboardService.getMyAmountDetails().then((amountdetails) => {
			this.setState({ myAmountDetails: amountdetails });
		});
		DashboardService.getMyFeatureDetails().then((response) => {
			console.log('=============================');
			console.log(response);
			this.setState({ myfeatureDetails: response });
			console.log('=============================');
		});
		DashboardService.getMyAmountDetailsByTenant().then((response) => {
			console.log('=============================');
			console.log(response);
			this.setState({ myAmountDetailsByTenant: response });
			console.log('=============================');
		});
	}

	changeNumber(labelValue) {
		// Nine Zeroes for Billions
		const number =
			Math.abs(Number(labelValue)) >= 1.0e9
				? Math.abs(Number(labelValue)) / 1.0e9 + 'B'
				: // Six Zeroes for Millions
				Math.abs(Number(labelValue)) >= 1.0e6
				? Math.abs(Number(labelValue)) / 1.0e6 + 'M'
				: // Three Zeroes for Thousands
				Math.abs(Number(labelValue)) >= 1.0e3
				? Math.abs(Number(labelValue)) / 1.0e3 + 'K'
				: Math.abs(Number(labelValue));

		console.log(number);

		return number;
	}

	componentDidMount() {
		this.setState({ loading: true });
		localStorage.setItem('title', 'Dashboard');
		this.getDashBoardData();
		this.getDataByUser(0, 10);
	}

	frMenu = [
		{
			name: 'id',
			label: 'ID',
			options: {
				display: false,
			},
		},
		{
			name: 'jiraid',
			label: 'Jira ID',
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const { classes } = this.props;
					let jira_url = localStorage.getItem('jiraURL');
					if (jira_url.charAt(jira_url.length - 1) !== '/')
						jira_url = jira_url + '/';
					let jiraurl = `${jira_url}browse/${value}`;
					return (
						<a
							href={jiraurl}
							target="_blank"
							style={{ color: '#039be5', textDecoration: 'none' }}
						>
							{value}
						</a>
					);
				},
			},
		},
		{
			name: 'name',
			label: 'Name',
		},
		{
			name: 'numberOfAppend',
			label: 'Appends',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const open = Boolean(this.state.anchorEl);
					let { rowData } = tableMeta;
					const id = open ? 'simple-popover' : undefined;
					return <PopupTable numberofappend={value} jiraid={rowData[1]} />;
				},
			},
		},
		{
			name: 'score',
			label: 'OT Score',
		},
		{
			name: 'salesScore',
			label: 'Sales Score',
		},
		{
			name: 'productScore',
			label: 'Product Score',
		},
		{
			name: 'jiraStatus',
			label: 'Jira Status',
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const { classes } = this.props;
					//myStr1.equalsIgnoreCase(myStr2)

					if (value && value.toUpperCase() === 'to do'.toUpperCase())
						return (
							<p
								style={{
									display: 'inline',
									backgroundColor: '#9F6000',
									borderRadius: '3',
									color: '#ffffff',
								}}
							>
								{value}
							</p>
						);
					if (value && value.toUpperCase() === 'done'.toUpperCase())
						return (
							<p
								style={{
									display: 'inline',
									backgroundColor: '#4F8A10',

									color: '#ffffff',
								}}
							>
								{value}
							</p>
						);
					if (value && value.toUpperCase() === 'in progress'.toUpperCase())
						return (
							<p
								style={{
									display: 'inline',
									backgroundColor: '#00529B',
									color: '#ffffff',
								}}
							>
								{value}
							</p>
						);
					return <p>{value}</p>;
				},
			},
		},
		{
			name: 'createdby',
			label: 'Created By',
		},
	];

	frMyMenu = [
		{
			name: 'id',
			label: 'ID',
			options: {
				display: false,
			},
		},
		{
			name: 'jiraid',
			label: 'Jira ID',
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const { classes } = this.props;
					let jira_url = localStorage.getItem('jiraURL');
					if (jira_url.charAt(jira_url.length - 1) !== '/')
						jira_url = jira_url + '/';
					let jiraurl = `${jira_url}browse/${value}`;
					return (
						<a
							href={jiraurl}
							target="_blank"
							style={{ color: '#039be5', textDecoration: 'none' }}
						>
							{value}
						</a>
					);
				},
			},
		},
		{
			name: 'name',
			label: 'Name',
		},
		{
			name: 'numberOfAppend',
			label: 'Appends',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const open = Boolean(this.state.anchorEl);
					let { rowData } = tableMeta;
					const id = open ? 'simple-popover' : undefined;
					return <PopupTable numberofappend={value} jiraid={rowData[1]} />;
				},
			},
		},
		{
			name: 'score',
			label: 'OT Score',
		},
		{
			name: 'salesScore',
			label: 'Sales Score',
		},
		{
			name: 'productScore',
			label: 'Product Score',
		},
		// {
		// 	name: 'jiraStatus',
		// 	label: 'Jira Status',
		// },
		{
			name: 'jiraStatus',
			label: 'Jira Status',
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const { classes } = this.props;
					//myStr1.equalsIgnoreCase(myStr2)

					if (value && value.toUpperCase() === 'to do'.toUpperCase())
						return (
							<p
								style={{
									display: 'inline',
									backgroundColor: '#9F6000',
									borderRadius: '3',
									color: '#ffffff',
								}}
							>
								{value}
							</p>
						);
					if (value && value.toUpperCase() === 'done'.toUpperCase())
						return (
							<p
								style={{
									display: 'inline',
									backgroundColor: '#4F8A10',

									color: '#ffffff',
								}}
							>
								{value}
							</p>
						);
					if (value && value.toUpperCase() === 'in progress'.toUpperCase())
						return (
							<p
								style={{
									display: 'inline',
									backgroundColor: '#00529B',
									color: '#ffffff',
								}}
							>
								{value}
							</p>
						);
					return <p>{value}</p>;
				},
			},
		},

		{
			name: 'createdby',
			label: 'Created By',
		},
	];

	handleChange = (event, newValue) => {
		this.setState({ value: newValue });
		if (newValue === 1) {
			this.setState({ isTenent: true, isUser: false });
			this.getData(0, 10);
		}
		if (newValue === 0) {
			this.setState({ isTenent: false, isUser: true });
			this.getDataByUser(0, 10);
		}
	};

	countClickHandler = (key, value) => {
		console.log('click hander called');
		this.props.history.push('/user/reports/' + key);
	};

	tenantCountClickHandler = (key, value) => {
		console.log('click hander called');
		this.props.history.push('/admin/reports/' + key);
	};

	amountClickHandler = (key, value) => {
		this.props.history.push('/user/reports/all/oppstatus/' + key);
	};

	tenantAmountClickHandler = (key, value) => {
		this.props.history.push('/admin/reports/all/oppstatus/' + key);
	};

	deleteButtonHandler = (rowdata) => {
		let featureid = rowdata[0];
		JiraAccountService.DeleteAppendFeatureJiraIssue(featureid).then(
			(response) => {
				if (response.status === 'SUCCESS') {
					this.toast.success('Account type added successfully');
					window.location.reload();
				} else {
					this.toast.error(response.errorMessage);
				}
			}
		);
	};

	render() {
		const { classes } = this.props;

		let dataFrom = JSON.parse(localStorage.getItem('integrationList'))[0];
		let showROI = false;
		if (dataFrom === 'SFDC') {
			showROI = true;
		}
		let data = [];
		localStorage.setItem('data', data);
		let options = {
			selectableRows: false,
			print: false,
			download: true,
			count: this.state.totalsize,
			rowsPerPage: 10,
			rowsPerPageOptions: [10, 15, 20, 25],
			serverSide: false,
			onTableChange: (action, tableState) => {
				console.log(action, tableState);
				if (this.state.isUser) {
					switch (action) {
						case 'changePage':
							this.getDataByUser(tableState.page, tableState.rowsPerPage);
							break;
						case 'changeRowsPerPage':
							this.getDataByUser(tableState.page, tableState.rowsPerPage);
							break;
					}
				}
				if (this.state.isTenent) {
					switch (action) {
						case 'changePage':
							this.getData(tableState.page, tableState.rowsPerPage);
							break;
						case 'changeRowsPerPage':
							this.getData(tableState.page, tableState.rowsPerPage);
							break;
					}
				}
			},
		};
		if (this.state.role === 'ADMIN') {
			return (
				<>
					<div className={classes.root}>
						<Header title="Dashboard" />
						<AppBar position="static" color="default">
							<Tabs
								value={this.state.value}
								onChange={this.handleChange}
								aria-label="simple tabs example"
							>
								<Tab label="My Features" {...a11yProps(0)} />
								<Tab label="All Features" {...a11yProps(1)} />
							</Tabs>
						</AppBar>
						<TabPanel value={this.state.value} index={0}>
							<Paper className={classes.paper1}>
								<Paper className={classes.paper1}>
									<Grid item xs={12} container>
										<Grid item xs={12}>
											<Grid container spacing={3}>
												{Object.entries(this.state.myfeatureDetails).map(
													([key, value]) => {
														for (let mykey in value) {
															return (
																// user deatils show .
																<Grid key={key} item>
																	<Paper className={classes.paper}>
																		<SnackbarContent
																			variant="error"
																			className={classes.submittedmargin}
																			message={mykey}
																		/>
																		<br></br>
																		<Breadcrumbs
																			aria-label="breadcrumb"
																			className={classes.breadcrumbs}
																		>
																			<Typography
																				color="textPrimary"
																				style={{ marginLeft: 25 }}
																			>
																				{' '}
																				<Typography
																					component="p"
																					variant="h6"
																					color="primary"
																					className={classes.reportlink}
																					onClick={() =>
																						this.countClickHandler(
																							mykey,
																							value[mykey]
																						)
																					}
																				>
																					{value[mykey] + ''}
																				</Typography>
																			</Typography>
																		</Breadcrumbs>
																	</Paper>
																	<br />
																</Grid>
															);
														}
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Paper>
								<br />

								{showROI && (
									<Paper className={classes.paper1}>
										<Paper className={classes.paper1}>
											<Grid item xs={12} container>
												<Grid item xs={12}>
													<Grid container spacing={3}>
														{Object.entries(this.state.myAmountDetails).map(
															([key, value]) => {
																for (let mykey in value) {
																	return (
																		<Grid key={key} item>
																			<Paper className={classes.paper}>
																				<SnackbarContent
																					variant="error"
																					className={classes.submittedmargin}
																					message={mykey}
																				/>
																				<br></br>
																				<Breadcrumbs
																					aria-label="breadcrumb"
																					className={classes.breadcrumbs}
																				>
																					<Typography
																						component="p"
																						variant="h6"
																						color="primary"
																						className={classes.reportlink}
																						onClick={() =>
																							this.amountClickHandler(
																								mykey,
																								value[mykey]
																							)
																						}
																					>
																						{/* CHANGEHERE */}
																						{'$' +
																							this.changeNumber(value[mykey]) +
																							''}
																					</Typography>
																				</Breadcrumbs>
																			</Paper>
																			<br />
																		</Grid>
																	);
																}
															}
														)}
													</Grid>
												</Grid>
											</Grid>
										</Paper>
									</Paper>
								)}
							</Paper>
							<MUIDataTable
								title={
									<Typography variant="h6">
										My Features
										{this.state.loading && (
											<CircularProgress
												size={24}
												style={{ marginLeft: 15, position: 'relative', top: 4 }}
											/>
										)}
									</Typography>
								}
								data={this.state.tableData}
								columns={this.frMyMenu}
								options={options}
							/>
						</TabPanel>
						<TabPanel value={this.state.value} index={1}>
							<Paper className={classes.paper1}>
								<Paper className={classes.paper1}>
									<Grid item xs={12} container>
										<Grid item xs={12}>
											<Grid container spacing={3}>
												{Object.entries(this.state.featureDetails).map(
													([key, value]) => {
														for (let mykey in value) {
															return (
																<Grid key={key} item>
																	<Paper className={classes.paper}>
																		<SnackbarContent
																			variant="error"
																			className={classes.submittedmargin}
																			message={mykey}
																		/>
																		<br></br>
																		<Breadcrumbs
																			aria-label="breadcrumb"
																			className={classes.breadcrumbs}
																		>
																			<Typography
																				component="p"
																				variant="h6"
																				whiteSpace="normal"
																				gutterBottom
																				color="primary"
																				className={classes.reportlink}
																				onClick={() =>
																					this.tenantCountClickHandler(
																						mykey,
																						value[mykey]
																					)
																				}
																			>
																				{value[mykey] + ''}
																			</Typography>
																		</Breadcrumbs>
																	</Paper>
																	<br />
																</Grid>
															);
														}
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Paper>
								<br />
								{showROI && (
									<Paper className={classes.paper1}>
										<Grid item xs={12} container>
											<Grid item xs={12}>
												<Grid container spacing={3}>
													{Object.entries(
														this.state.myAmountDetailsByTenant
													).map(([key, value]) => {
														for (let mykey in value) {
															return (
																<Grid key={key} item>
																	<Paper className={classes.paper}>
																		<SnackbarContent
																			variant="error"
																			className={classes.submittedmargin}
																			message={mykey}
																		/>
																		<br></br>
																		<Breadcrumbs
																			aria-label="breadcrumb"
																			className={classes.breadcrumbs}
																		>
																			<Typography
																				component="p"
																				variant="h6"
																				color="primary"
																				className={classes.reportlink}
																				onClick={() =>
																					this.tenantAmountClickHandler(
																						mykey,
																						value[mykey]
																					)
																				}
																			>
																				{'$' +
																					this.changeNumber(value[mykey]) +
																					''}
																			</Typography>
																		</Breadcrumbs>
																	</Paper>
																	<br />
																</Grid>
															);
														}
													})}
												</Grid>
											</Grid>
										</Grid>
									</Paper>
								)}
							</Paper>
							<MUIDataTable
								title={
									<Typography variant="h6">
										All Features
										{this.state.loading && (
											<CircularProgress
												size={24}
												style={{ marginLeft: 15, position: 'relative', top: 4 }}
											/>
										)}
									</Typography>
								}
								data={this.state.tableData}
								columns={this.frMenu}
								options={options}
							/>
						</TabPanel>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className={classes.root}>
						<Header title="Opportunity" />
						<AppBar position="static" color="default">
							<Tabs
								value={this.state.value}
								onChange={this.handleChange}
								aria-label="simple tabs example"
							>
								<Tab label="My Features" {...a11yProps(1)} />
							</Tabs>
						</AppBar>
						<TabPanel value={this.state.value} index={0}>
							<Paper className={classes.paper1}>
								<Paper className={classes.paper1}>
									<Grid item xs={12} container>
										<Grid item xs={12}>
											<Grid container spacing={3}>
												{Object.entries(this.state.myfeatureDetails).map(
													([key, value]) => {
														for (let mykey in value) {
															return (
																<Grid key={key} item>
																	<Paper className={classes.paper}>
																		<SnackbarContent
																			variant="error"
																			className={classes.submittedmargin}
																			message={mykey}
																		/>
																		<br></br>
																		<Breadcrumbs
																			aria-label="breadcrumb"
																			className={classes.breadcrumbs}
																		>
																			<Typography
																				component="p"
																				variant="h6"
																				whiteSpace="normal"
																				gutterBottom
																				color="primary"
																				className={classes.reportlink}
																				onClick={() =>
																					this.countClickHandler(
																						mykey,
																						value[mykey]
																					)
																				}
																			>
																				{value[mykey] + ''}
																			</Typography>
																		</Breadcrumbs>
																	</Paper>
																	<br />
																</Grid>
															);
														}
													}
												)}
											</Grid>
										</Grid>
									</Grid>
								</Paper>
								{showROI && (
									<Paper className={classes.paper1}>
										<Paper className={classes.paper1}>
											<Grid item xs={12} container>
												<Grid item xs={12}>
													<Grid container spacing={3}>
														{Object.entries(this.state.myAmountDetails).map(
															([key, value]) => {
																for (let mykey in value) {
																	return (
																		<Grid key={key} item>
																			<Paper className={classes.paper}>
																				<SnackbarContent
																					variant="error"
																					className={classes.submittedmargin}
																					message={mykey}
																				/>
																				<br></br>
																				<Breadcrumbs
																					aria-label="breadcrumb"
																					className={classes.breadcrumbs}
																				>
																					<Typography
																						component="p"
																						variant="h6"
																						color="primary"
																						className={classes.reportlink}
																						onClick={() =>
																							this.amountClickHandler(
																								mykey,
																								value[mykey]
																							)
																						}
																					>
																						{/* CHANGEHERE */}
																						{'$' +
																							this.changeNumber(value[mykey]) +
																							''}
																					</Typography>
																				</Breadcrumbs>
																			</Paper>
																			<br />
																		</Grid>
																	);
																}
															}
														)}
													</Grid>
												</Grid>
											</Grid>
										</Paper>
									</Paper>
								)}
							</Paper>
							<MUIDataTable
								title={
									<Typography variant="h6">
										My Features
										{this.state.loading && (
											<CircularProgress
												size={24}
												style={{ marginLeft: 15, position: 'relative', top: 4 }}
											/>
										)}
									</Typography>
								}
								data={this.state.tableData}
								columns={this.frMyMenu}
								options={options}
							/>
						</TabPanel>
					</div>
				</>
			);
		}
	}
}

OpportunityComponent.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(useStyles)(OpportunityComponent);
