/**
 * @author Raghav Prabhu
 * @description Generic API client module
 */

import constants, { apiVersion } from "../../../settings/shared/constants";
import axios from "axios";

/**
 * Generic Axios client with default base URL
 */

const instance = axios.create({
  baseURL: constants.api.base.url,
});

/**
 * API Wrapper with default success/error actions
 */
const service = function (options) {
  const onSuccess = function (response) {
    console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      console.error("Status:", error.response.status);
      if (error.response.status === 401) {
        //Token expires or unauthorized access
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
      if (error.response.status === 403) {
        //Unauthorized access
        window.location.href = "/";
        //window.location.reload();
      }
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };
  //returns the default request
  return instance(options).then(onSuccess).catch(onError);
};

export default service;
