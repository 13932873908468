function getTitle(url) {
	console.log(url);
	switch (url) {
		case 'dashboard':
			return 'Dasboard';
			break;
	}
	console.log('get title method calllleld');
}

export default getTitle;
