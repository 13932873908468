/**
 * @author Ramya Jhey
 * @desc User Change Password
 */

import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import UserService from '../../Services/api/User';
import ToastServive from 'react-material-toast';

const styles = (theme) => ({
	appBar: {
		position: 'fixed',
	},
	flex: {
		flex: 1,
	},
	root: {
		width: 'auto',
		display: 'block',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
			width: 1000,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '50%',
		margin: `${theme.spacing.unit}px auto`,
		marginTop: '77px',
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '50%',
		margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 2,
	},
	appBar: {
		padding: 0,
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
});

/**
 * Change Password.
 */
class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formdata: {
				password: '',
				repeatPassword: '',
			},
			user: {},
			submitted: false,
		};
	}

	componentDidMount() {
		// custom rule will have name 'isPasswordMatch'
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			const { formdata } = this.state;
			if (value !== formdata.password) {
				return false;
			}
			return true;
		});
	}
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	handleChange = (event) => {
		const { formdata } = this.state;
		formdata[event.target.name] = event.target.value;
		this.setState({ formdata });
	};

	handleSubmit = () => {
		this.setState({ submitted: true }, () => {
			setTimeout(() => this.setState({ submitted: false }), 5000);
		});
	};
	ChangePasswordHandler = () => {
		const { formdata } = this.state;
		const user = {
			newPassword: formdata.password,
			oldPassword: formdata.oldPassword,
		};

		//User service integration
		UserService.updatepassword(user).then((response) => {
			if (response.status === 'SUCCESS') {
				console.log(response);
				// let token = response.token
				// let decodedUser = jwt_decode(token);

				// //Store the objects into browser local storage
				// localStorage.setItem('user', decodedUser)
				// localStorage.setItem('token', response.token)

				this.setState({ submitted: true });
				//  this.props.history.push("/");
				window.location.href = '/';
			} else {
				this.toast.error(response.errorMessage, () => {});
				this.setState({ isError: true });
				this.setState({ error: response.errorMessage });
			}
		});

		this.setState({ submitted: true }, () => {
			setTimeout(() => this.setState({ submitted: false }), 5000);
		});
	};
	render() {
		const { classes } = this.props;
		const { formdata, submitted, user } = this.state;
		return (
			<div className={classes.root}>
				<Grid item>
					<Paper className={classes.paper}>
						<AppBar position="static" className={classes.appBar}>
							<Toolbar>
								<Typography variant="h6" color="inherit">
									Change Password
								</Typography>
							</Toolbar>
						</AppBar>
					</Paper>
					<Paper className={classes.paper1}>
						<ValidatorForm onSubmit={this.ChangePasswordHandler}>
							<form className={classes.form}>
								<TextValidator
									label="Old Password"
									onChange={this.handleChange}
									id="oldPassword"
									name="oldPassword"
									type="password"
									validators={['required']}
									errorMessages={['this field is required']}
									value={formdata.oldPassword}
									inputProps={{ minLength: 8 }}
								/>
								<br></br>
								<TextValidator
									label="New Password"
									onChange={this.handleChange}
									id="password"
									name="password"
									type="password"
									validators={['required']}
									errorMessages={['this field is required']}
									value={formdata.password}
									inputProps={{ minLength: 8 }}
								/>
								<br></br>
								<TextValidator
									label="Repeat password"
									onChange={this.handleChange}
									name="repeatPassword"
									type="password"
									validators={['isPasswordMatch', 'required']}
									errorMessages={[
										'password mismatch',
										'this field is required',
									]}
									value={formdata.repeatPassword}
									inputProps={{ minLength: 8 }}
								/>
								<br></br>
								<br></br>
								<Grid item align="center" xs={6} sm={12}>
									<Button
										color="primary"
										variant="contained"
										type="submit"
										className={classes.button}
										disabled={submitted}
									>
										<SaveIcon className={classes.rightIcon} />
										{(submitted && 'New Password has been Changed!') ||
											(!submitted && 'Save')}
									</Button>
								</Grid>
							</form>
						</ValidatorForm>
					</Paper>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(ChangePassword);
