/**
 * @author Raghav Prabhu
 * @desc Login authentication service
 */
import service from '../Common/';
import constants from '../../../settings/shared/constants';

/**
 * This function helps to authenticate with backend and
 * returns its response.
 *
 * @param {*} user {email, password, tenant}
 */

function auth(user) {
	let headers = { 'Content-Type': 'application/json' };
	return service({
		url: constants.api.auth.login + '/',
		method: 'POST',
		headers: headers,
		data: {
			email: user.email,
			password: user.password,
			tenant: user.tenant,
			authToken: user.authToken,
		},
	});
}

//To export authentication function
const LoginService = {
	auth,
};

export default LoginService;
