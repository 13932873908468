/**
 * @author Ramya Jhey
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { percentageDataSet } from '../DataSets/MenuDataSet';
import Header from '../CommonComponents/Header';
import CustomToolbar from '../RankingComponent/MetricToolBar';
import jwt_decode from 'jwt-decode';

const useStyles = (theme) => ({
	appBar: {
		position: 'relative',
	},
	stepper: {
		width: '90%',
	},
	root: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			marginTop: theme.spacing.unit * 6,
			marginBottom: theme.spacing.unit * 6,
			padding: theme.spacing.unit * 3,
		},
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	formControl: {
		margin: theme.spacing.unit * 1,
		minWidth: 120,
	},
	// textField: {
	//   marginLeft: theme.spacing.unit * 1,
	//   marginRight: theme.spacing.unit * 1,
	//   marginTop: -16,
	//   width: 200,
	// },
	formControl: {
		margin: theme.spacing.unit * 1,
		minWidth: 120,
	},
	textField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		marginTop: 6,
		width: 200,
	},
	selectMenu: {
		// marginTop: 7,
	},
});

class ConfigureMetricComponent extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSliderChange = this.handleSliderChange.bind(this);

		this.state = {
			formData: [],
			add: [],
			summary: {},
			metrics: {},
			hasError: false,
		};
	}

	componentDidMount() {
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		if (role === 'user') return <CustomToolbar path="/dashboard" />;

		let metrics = sessionStorage.getItem('metrics_summary');
		var obj = JSON.parse(metrics);
		this.setState({ metrics: obj });
		console.log(percentageDataSet);
	}

	handleChange = (event) => {
		console.log('handle change called');
		var metrics_object = this.state.metrics;
		for (var keys in metrics_object) {
			if (event.target.name === metrics_object[keys].uuid) {
				metrics_object[keys].datatype = event.target.value;
			}
		}
		this.setState({ metrics: metrics_object });
		sessionStorage.setItem('metrics_summary', JSON.stringify(metrics_object));
	};

	handleSliderChange = (event) => {
		console.log('handle slider changed called');
		var metrics_object = this.state.metrics;
		for (var keys in metrics_object) {
			if (event.target.name === metrics_object[keys].uuid) {
				metrics_object[keys].weightage = event.target.value;
			}
		}
		this.setState({ metrics: metrics_object });
		sessionStorage.setItem('metrics_summary', JSON.stringify(metrics_object));
	};

	createMetricConfiguration = () => {
		let itemList = [];
		let metrics_object = this.state.metrics;
		const { classes } = this.props;
		for (var keys in metrics_object) {
			if (metrics_object[keys].weightage === undefined) {
				metrics_object[keys].weightage = 0;
			}
			itemList.push(
				<Grid key={metrics_object[keys].uuid} container spacing={16}>
					<Grid item xs={4}>
						<b>
							<br></br>
							<br></br>
							{metrics_object[keys].name}
						</b>
					</Grid>
					<Grid item xs={4}>
						<FormControl required className={classes.formControl}>
							<InputLabel htmlFor="name">Data Type </InputLabel>
							<Select
								value={metrics_object[keys].datatype}
								onChange={this.handleChange}
								required={true}
								classes={{ selectMenu: classes.selectMenu }}
								data-testid="select-dropdown"
								inputProps={{
									name: metrics_object[keys].uuid,
									id: metrics_object[keys].uuid,
								}}
							>
								<MenuItem value="Currency">Currency</MenuItem>
								<MenuItem value="Probability">Probability</MenuItem>
								<MenuItem value="Priority">Priority</MenuItem>
								<MenuItem value="Date">Date</MenuItem>
								<MenuItem value="Customer">Customer </MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl className={classes.formControl}>
							<TextField
								select
								value="weightage"
								label="Weightage"
								margin="dense"
								variant="outlined"
								name={metrics_object[keys].uuid}
								id={metrics_object[keys].uuid}
								onChange={this.handleSliderChange}
								value={metrics_object[keys].weightage}
							>
								{percentageDataSet.map((index) => (
									<MenuItem value={index}>{index} </MenuItem>
								))}
							</TextField>
						</FormControl>

						{/* <br />
          <Typography id="discrete-slider-always" gutterBottom>
            Weightage
          </Typography>
          <Slider
            arial-label="Weightage"
            id={metrics_object[keys].uuid}
            name={metrics_object[keys].uuid}
            defaultValue={metrics_object[keys].weightage}
            step={10}
            marks={0}
            onChange={this.handleSliderChange}
            valueLabelDisplay="on"
          /> */}
					</Grid>
				</Grid>
			);
		}
		return itemList;
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleOpen = () => {
		this.setState({ open: true });
	};

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				{/* <Header title="Configure Metrics" /> */}
				<CssBaseline />
				{this.createMetricConfiguration()}
			</React.Fragment>
		);
	}
}

ConfigureMetricComponent.propTypes = {
	classes: PropTypes.object,
};
export default withStyles(useStyles)(ConfigureMetricComponent);
