/**
 * @author Raghav Prabhu
 * @desc JIRA management service
 */
import service from '../Common';
import constants from '../../../settings/shared/constants';

/**
 * This function helps to  get all projects from JIRA
 *
 */
function getAllProjects() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_projects + '/',
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function helps to  get all projects from JIRA
 *
 */
function getAllIssueType() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_issue_type + '/',
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function helps to create get all JIRA issue types
 *
 */
function submitFeature(submitRequest) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.post_submit_approve + '/',
		method: 'POST',
		headers: headers,
		data: submitRequest,
	});
}

function postJiraComment(commentRequest) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.post_jira_comment + '/',
		method: 'POST',
		headers: headers,
		data: commentRequest,
	});
}

function getJiraDetails(jiraId) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_jira_details + '/' + jiraId,
		method: 'GET',
		headers: headers,
	});
}
/**
 * Get Jira REST data of JIRA comment's user details
 * @param {*} dataUri
 */
function getJiraCommentsUser(dataUri) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_jira_comments_user_details + '/',
		method: 'POST',
		headers: headers,
		data: dataUri,
	});
}

function getFeaturesWithJiraDetails(oppid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_features_with_jira_details + '/' + oppid,
		method: 'GET',
		headers: headers,
	});
}
// get_jira_by_feature_id

function getFeaturesByFeatureidWithJiraDetails(featureid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_jira_by_feature_id + '/' + featureid,
		method: 'GET',
		headers: headers,
	});
}

function getCommentByIssueId(issueid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_jira_comments + '/' + issueid,
		method: 'GET',
		headers: headers,
	});
}

function getJiraIssueByTenent(page, size) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url:
			constants.api.jira.get_jira_issue_by_tenent +
			'?page=' +
			page +
			'&size=' +
			size,
		method: 'GET',
		headers: headers,
	});
}
function getJiraHistory(jiraId) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_jira_history + '/' + jiraId,
		method: 'GET',
		headers: headers,
	});
}
// get_feature_with_jira_opp_details_by_jirastatus

function getFeatureWithJiraOppDetailsByJirastatus(page, size, jiraStatus) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url:
			constants.api.jira.get_feature_with_jira_opp_details_by_jirastatus +
			'/' +
			jiraStatus +
			'?page=' +
			page +
			'&size=' +
			size,
		method: 'GET',
		headers: headers,
	});
}

function getFeatureWithJiraOppDetailsByJirastatus1(data, role) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	if (!role) role = 'user';
	if (role === 'user')
		return service({
			url:
				constants.api.jira.get_feature_with_jira_opp_details_by_jirastatus1 +
				'/',
			method: 'POST',
			headers: headers,
			data: data,
		});
	if (role === 'admin')
		return service({
			url: constants.api.jira.get_feature_with_jira_opp_details_by_tenant + '/',
			method: 'POST',
			headers: headers,
			data: data,
		});
}

function getJiraIssueByUser(page, size) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url:
			constants.api.jira.get_jira_issue_by_user +
			'?page=' +
			page +
			'&size=' +
			size,
		method: 'GET',
		headers: headers,
	});
}
//search_jira_issue

function SearchJiraIssue(issue) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.search_jira_issue + '/' + issue,
		method: 'GET',
		headers: headers,
	});
}

function UpdatIssueByIssueId(issueid, issue) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.update_jira_issue + '/' + issueid,
		method: 'PUT',
		headers: headers,
		data: issue,
	});
}

function getAppendByJiraId(jiraid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_append_by_jira_id + '/' + jiraid,
		method: 'GET',
		headers: headers,
	});
}

function appendFeatureJiraIssue(issueid, desc) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.append_jira_desc + '/' + issueid,
		method: 'PUT',
		headers: headers,
		data: desc,
	});
}
//

function DeleteAppendFeatureJiraIssue(fatureid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.delete_append_jira + '/' + fatureid,
		method: 'DELETE',
		headers: headers,
	});
}

function GetJiraConnector() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.get_jira_connector,
		method: 'GET',
		headers: headers,
	});
}

function generateWebhookURL() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jirawebhook.genrate_webhook_url + '/',
		method: 'GET',
		headers: headers,
	});
}

function getWebhookURL() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jirawebhook.get_webhook_url + '/',
		method: 'GET',
		headers: headers,
	});
}

/**
 * Create Custom field on Jira
 * @param {*} dataUri
 */
function createCustomField(customField) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.jira.create_custom_field + '/',
		method: 'POST',
		headers: headers,
		data: customField,
	});
}

//Making to publish all functions
const JiraAccountService = {
	getAllProjects,
	UpdatIssueByIssueId,
	getCommentByIssueId,
	getAllIssueType,
	submitFeature,
	DeleteAppendFeatureJiraIssue,
	postJiraComment,
	getJiraDetails,
	getJiraCommentsUser,
	getFeaturesWithJiraDetails,
	getJiraIssueByTenent,
	getJiraIssueByUser,
	SearchJiraIssue,
	createCustomField,
	appendFeatureJiraIssue,
	getAppendByJiraId,
	GetJiraConnector,
	generateWebhookURL,
	getJiraHistory,
	getWebhookURL,
	getFeaturesByFeatureidWithJiraDetails,
	getFeatureWithJiraOppDetailsByJirastatus,
	getFeatureWithJiraOppDetailsByJirastatus1,
};
export default JiraAccountService;
