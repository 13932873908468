import React from 'react';
import { Redirect } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import { withStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';

const defaultToolbarStyles = {
	iconButton: {},
};
const history = createBrowserHistory();

class CustomToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			redirect: true,
		};
	}

	handleClick = (e) => {
		e.preventDefault();
		console.log(history);
		history.push(this.props.path);
		window.location.reload();
	};

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Tooltip title={'configure metric'}>
					<IconButton className={classes.iconButton} onClick={this.handleClick}>
						<BuildIcon className={classes.deleteIcon} color="secondary" />
					</IconButton>
				</Tooltip>
			</React.Fragment>
		);
	}
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
	CustomToolbar
);
