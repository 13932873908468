/**
 * @author Ramya Jhey
 * @desc User Edit Module
 */

import React, { Component } from 'react';
import { roleTypes } from '../DataSets/MenuDataSet';
import AddForm from '../CommonComponents/UpdateTemplate';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import jwt_decode from 'jwt-decode';
import ToastServive from 'react-material-toast';
import UserService from '../../Services/api/User';

const userEditform = {
	title: 'Edit User',
	buttonName: 'Update User',
	field: [
		{
			id: 'emailAddress',
			type: 'textfield',
			visible: true,
			props: {
				id: 'emailAddress',
				label: 'Email Address',
				disabled: true,
				fullWidth: true,
				value: '',
				errortext: '',
			},
			rules: {
				validation: [
					{
						rule: 'email',
						message: 'Please enter valid email.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'role',
			type: 'selectfield',
			visible: true,
			props: {
				id: 'Role',
				label: 'Role',
				fullWidth: true,
				style: {
					display: 'table',
				},
				selected: '',
				errortext: '',
			},
			options: [
				{
					value: null,
					primaryText: '',
				},
			],
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please select a title.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'firstName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'firstName',
				label: 'First Name',
				fullWidth: true,
				value: '',
				errortext: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the Your Name.',
					},
					{
						rule: 'alpha',
						message: 'Please enter character only',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'lastName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'lastName',
				label: 'Last Name',
				fullWidth: true,
				value: '',
				errortext: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the Last Name.',
					},
					{
						rule: 'alpha',
						message: 'Please enter character only',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'phoneNumber',
			type: 'textfield',
			visible: true,
			props: {
				id: 'phoneNumber',
				label: 'Phone Number',
				fullWidth: true,
				value: '',
				errortext: '',
				type: 'number',
			},
			rules: {
				validation: [
					{
						rule: 'numeric',
						message: 'Please enter numbers only',
					},
					{
						rule: 'length',
						value: 10,
						message: 'Phone Number must have 10 number.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'homePhone',
			type: 'textfield',
			visible: true,
			props: {
				id: 'homePhone',
				label: 'Home Phone Number',
				fullWidth: true,
				value: '',
				errortext: '',
				type: 'number',
			},
			rules: {
				validation: [
					{
						rule: 'numeric',
						message: 'Please enter numbers only',
					},
					{
						rule: 'length',
						value: 10,
						message: 'Home Phone must have 10 number.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
	],
};
const styles = (theme) => ({
	appBar: {
		position: 'fixed',
	},
	flex: {
		flex: 1,
	},
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

/**
 * Feature Request - User  module.
 */
class UserEdit extends Component {
	constructor(props) {
		super(props);

		this.state = { accounts: [] };
		this.state = { formdata: userEditform };
		this.state = { formdata: userEditform, open: true };
	}
	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
		this.props.history.push('/users');
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});
	//Account update submit handler
	submitHandler = (data) => {
		let id = this.props.location.state['data'][0];
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);

		let request = {
			id: id,
			email: data.layout.emailAddress,
			firstName: data.layout.firstName,
			lastName: data.layout.lastName,
			password: data.layout.password,
			role: data.layout.role,
			tenant: user.tenant,
			organisation: user.organisation,
			homePhone: data.layout.homePhone,
			mobileNumber: data.layout.mobileNumber,
		};

		UserService.update(id, request).then((response) => {
			if (response.status === 'SUCCESS') {
				this.props.history.push('/users');
				this.toast.success('User updated successfully...', () => {
					console.log('closed');
				});
			} else {
				this.toast.error(response.errorMessage, () => {
					console.log('closed');
				});
			}
		});
	};

	//User remove delete handler
	deleteHandler = (data) => {
		let stateObj = this.props.location.state;
		let userId = stateObj['data'][0];
		UserService.remove(userId).then((response) => {
			this.props.history.push('/users');
			this.toast.success('User removed successfully...', () => {
				console.log('closed');
			});
		});
	};

	render() {
		const { classes } = this.props;
		if (this.props.location.state === undefined) {
			this.props.history.push('/users');
		}
		var stateObj = this.props.location.state;
		console.log(stateObj);
		var fieldForm = this.state.formdata.field;
		let fields = this.state.formdata.field.forEach(function (element) {
			if (element.props.id === 'emailAddress') {
				element.props.value = stateObj['data'][3];
			} else if (element.props.id === 'Role') {
				let roles = [];
				let jwtToken = localStorage.getItem('token');
				let user = jwt_decode(jwtToken);

				for (var key in roleTypes) {
					let type = {};
					if (roleTypes.hasOwnProperty(key)) {
						type = {
							value: roleTypes[key],
							primaryText: roleTypes[key],
						};
						roles.push(type);
					}
				}
				element.props.selected = stateObj['data'][5];
				element.options = roles;
			} else if (element.props.id === 'firstName') {
				element.props.value = stateObj['data'][1];
			} else if (element.props.id === 'lastName') {
				element.props.value = stateObj['data'][2];
			} else if (element.props.id === 'homePhone') {
				element.props.value = stateObj['data'][6];
			} else if (element.props.id === 'phoneNumber') {
				element.props.value = stateObj['data'][7];
			}
		});
		return (
			<React.Fragment>
				<Dialog
					fullScreen
					open={this.state.open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar className={classes.appBar} position="absolute">
						<Toolbar>
							<IconButton
								color="inherit"
								onClick={this.handleClose}
								aria-label="Close"
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>

					<AddForm
						formname={this.state.formdata.title}
						formdata={this.state.formdata}
						submitHandler={this.submitHandler}
						deleteHandler={this.deleteHandler}
					/>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(UserEdit);
