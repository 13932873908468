/**
 * @author Ramya
 * @desc SFDC service
 */
import service from "../Common/";
import constants from "../../../settings/shared/constants";
import jwt_decode from "jwt-decode";

/**
 * This function helps to create SFDC Details
 */
function create_auth(request) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.sfdc.create_auth,
    method: "POST",
    headers: headers,
    data: request,
  });
}

/**
 * This function helps to get SFDC OAuth login URL
 */
function oauth_login_url() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.sfdc.oauth_authorize,
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to create SFDC Details
 */
function get_sfdc_account_by_id(accountId) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  let user = jwt_decode(jwtToken);
  return service({
    url:
      constants.api.sfdc.get_account_by_id +
      "/" +
      user.tenant +
      "/" +
      accountId,
    method: "GET",
    headers: headers,
  });
}
//check_connection

function check_connection(request) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.sfdc.check_connection,
    method: "POST",
    headers: headers,
    data: request,
  });
}

/**
 * This function helps to create SFDC Details
 */
function get_sfdc_user_by_id(userId) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  let user = jwt_decode(jwtToken);
  return service({
    url: constants.api.sfdc.get_user_by_id + "/" + user.tenant + "/" + userId,
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to create SFDC Details
 */
function connect_oauth(code) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.sfdc.oauth_callback + "?code=" + code,
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to get SFDC Details
 */
function get_template() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.sfdc.get_auth_template,
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to get SFDC auth details
 */
function get_auth_by_tenant() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  let user = jwt_decode(jwtToken);
  return service({
    url: constants.api.sfdc.get_auth + "/" + user.tenant,
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to get SFDC auth details
 */
function delete_auth_by_tenant(id) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.sfdc.get_auth + "/" + id,
    method: "DELETE",
    headers: headers,
  });
}

function getNotesByOppId(id) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  console.log("in header start nootes service");
  console.log(headers);
  console.log("in header end nootes service");
  return service({
    url: constants.api.sfdc.get_notes_by_opp + "/" + id + "/notes",
    method: "GET",
    headers: headers,
  });
}

//Making to publish all functions
const SFDCService = {
  get_template,
  create_auth,
  get_auth_by_tenant,
  delete_auth_by_tenant,
  connect_oauth,
  oauth_login_url,
  get_sfdc_account_by_id,
  get_sfdc_user_by_id,
  check_connection,
  getNotesByOppId,
};

export default SFDCService;
