/**
 * @author Raghav Prabhu
 */

import React, {Fragment, Component} from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import SFDCService from '../../Services/api/SFDC'

class ActivateSalesForceAccount extends Component{
    constructor(props) {
        super(props)

    }

    componentDidMount() {
        const value=queryString.parse(this.props.location.search);
        const token=value.code;
        const urlState=value.state
        console.log('token',value)
       
        SFDCService.connect_oauth(token).then((response) => {
            if (response.status === 'FAILURE') {
                console.log(response.oauthLoginURL)
                window.location.replace(response.oauthLoginURL)
               
            } else {
                this.props.history.push(urlState);
                window.location.reload();
            }
            console.log(response)
        });
 
    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        )

    }
}
export default withRouter(ActivateSalesForceAccount);