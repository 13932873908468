/**
 * @author Ramya
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import LoadingOverlay from 'react-loading-overlay';
import jwt_decode from 'jwt-decode';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import CsvService from '../../Services/api/Csv';
import ToastServive from 'react-material-toast';
import IntegrationService from '../../Services/api/Integration';
import DeleteIcon from '@material-ui/icons/Delete';
import Header from '../CommonComponents/Header';
import BubbolChart from '../GraphsComponents/BubbolChart';
const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
	paper: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '100%',
		margin: '20px',
		// margin: `${theme.spacing.unit}px auto`,
		marginTop: '30px',
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '100%',
		margin: '20px',
		// margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 5,
	},
	appBar: {
		padding: 0,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	textField: {
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
		width: 300,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	loadingOverlay: {
		position: 'absolute',
		height: '100vh',
		width: '90vw',
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
});

class AddCsvComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			oppurl: '',
			notesrul: '',
			oppid: '',
			notesid: '',
		};
		this.triggerSubmit = this.triggerSubmit.bind(this);
	}
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	changeHandle = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	triggerSubmit = (data, errors) => {
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		let opprequest = {};
		let notesrequest = {};

		opprequest = {
			type: 'opportunity',
			url: this.state.oppurl,
		};

		notesrequest = {
			type: 'notes',
			url: this.state.notesurl,
		};

		CsvService.create(opprequest).then((response) => {
			if (response.status === 'SUCCESS') {
				this.toast.success('Opportunity csv added Successfully.');
				CsvService.create(notesrequest).then((response) => {
					if (response.status === 'SUCCESS') {
						this.toast.success('Notes csv added Successfully.');
						IntegrationService.getList().then((response) => {
							localStorage.setItem('integrationList', JSON.stringify(response));
						});
						IntegrationService.getListByTenant().then((response) => {
							localStorage.setItem(
								'integrationListByTenant',
								JSON.stringify(response)
							);
						});
					}
				});
			} else {
				this.toast.error(response.errorMessage);
			}
		});
	};

	deleteClick = () => {
		if (this.state.oppid) {
			CsvService.deleteCSVFile(this.state.oppid).then((response) => {
				if (response.status === 'SUCCESS') {
					this.toast.success('Opportunity file deleted Successfully.');
				}
			});
		}
		console.log(this.state.notesid);
		if (this.state.notesid) {
			CsvService.deleteCSVFile(this.state.notesid).then((response) => {
				if (response.status === 'SUCCESS') {
					this.toast.success('Notes file deleted Successfully.');
				}
			});
		}
	};

	jirahandleChange = (event) => {
		this.setState({ jiraValue: event.target.value });
		const { classes } = this.props;
		if (event.target.value === 'Cloud') {
			this.setState({ isCloud: true });
		} else {
			this.setState({ isCloud: false });
		}
	};

	componentDidMount() {
		//  getCSVFileByYType
		localStorage.setItem('title', 'CSV Setting');
		const { classes } = this.props;
		CsvService.getCSVFileByYType('opportunity').then((response) => {
			if (response.status === 'SUCCESS' && response.csvDetails.length > 0) {
				this.setState({
					oppurl: response.csvDetails[0].url,
					oppid: response.csvDetails[0].id,
				});
			}
		});
		CsvService.getCSVFileByYType('notes').then((response) => {
			if (response.status === 'SUCCESS' && response.csvDetails.length > 0) {
				console.log('notesid:' + response.csvDetails[0].id);
				this.setState({
					notesurl: response.csvDetails[0].url,
					notesid: response.csvDetails[0].id,
				});
			}
		});
		this.setState({ loading: false, isCloud: false });
	}

	render() {
		const { classes } = this.props;
		const isCloud = this.state.isCloud;
		console.log('-------------------------');
		console.log(this.state);
		console.log('-------------------------');
		return (
			<React.Fragment>
				<Header title="CSV settings" />
				<LoadingOverlay
					active={this.state.loading}
					spinner
					className={classes.loadingOverlay}
				>
					<Grid item>
						<Paper className={classes.paper1}>
							<Grid container spacing={8}>
								<FormControl
									component="fieldset"
									className={classes.formControl}
								>
									<Toolbar>
										<Typography variant="h5" color="inherit">
											CSV Settings
										</Typography>
									</Toolbar>
									<FormLabel component="legend"></FormLabel>

									<br></br>
									<div>
										<div>
											<form className={classes.form}>
												<Typography variant="h6" color="inherit">
													{this.state.jiraValue}
												</Typography>
												<FormControl margin="normal" required fullWidth>
													<InputLabel htmlFor="oppurl">
														Opportunity URL
													</InputLabel>
													<Input
														id="oppurl"
														name="oppurl"
														value={this.state.oppurl}
														onChange={this.changeHandle}
													/>
												</FormControl>
												<FormControl margin="normal" required fullWidth>
													<InputLabel htmlFor="notesurl">Notes URL </InputLabel>
													<Input
														id="notesurl"
														name="notesurl"
														value={this.state.notesurl}
														onChange={this.changeHandle}
													/>
												</FormControl>

												<Grid item align="center" xs={18} sm={18}>
													<br></br>

													<Button
														color="primary"
														variant="contained"
														className={classes.button}
														onClick={this.triggerSubmit}
													>
														Save
														<SaveIcon className={classes.rightIcon} />
													</Button>
													<Button
														color="primary"
														variant="contained"
														className={classes.button}
														onClick={() =>
															this.props.history.push('/dashboard')
														}
													>
														Cancel
														<CancelIcon className={classes.rightIcon} />
													</Button>
													<Button
														color="primary"
														variant="contained"
														className={classes.button}
														// style={{ align: "center", marginTop: 30 }} align="center"
														onClick={() => this.deleteClick()}
													>
														Delete
														<DeleteIcon className={classes.rightIcon} />
													</Button>
												</Grid>
											</form>
										</div>
									</div>
								</FormControl>
								<br></br>
							</Grid>
						</Paper>
					</Grid>
				</LoadingOverlay>
			</React.Fragment>
		);
	}
}

export default withStyles(useStyles)(AddCsvComponent);
