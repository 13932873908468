import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import JiraAccountService from '../../Services/api/JiraAccount';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Route, Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ToastServive from 'react-material-toast';
import Swal from 'sweetalert2';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
	typography: {
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
}));

export default function TablePopUp(props) {
	let toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [jiradatatable, setJiradatatable] = useState([]);
	// const [data, setData] = useState({});
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	let mydata = [];
	let { jiraid } = props;
	console.log(jiraid);
	console.log(typeof jiraid);

	function gettabledata(jiraid) {
		console.log('getting table data');
		let jiradataarray = [];
		JiraAccountService.getAppendByJiraId(jiraid).then((response) => {
			for (let i = 0; i < response.length; i++) {
				console.log(response[i]);
				let jiradata = {
					jiraid: response[i]['jiraID'],
					name: response[i]['jiraTitle'],
					featurecustomid: response[i]['featurecustomid'],
					featureId: response[i]['featureId'],
					createdby: response[i]['submitterName'],
					appendScore: response[i]['appendScore'],
					productScore: response[i]['productScore'],
					salesScore: response[i]['salesScore'],
					featurePriority: response[i]['featurePriority'],
				};
				jiradataarray.push(jiradata);
			}
			setIsLoading(false);
			setJiradatatable(jiradataarray);
		});
		console.log('gettingtabledatafinish');
	}

	const handleClick = (event) => {
		gettabledata(jiraid);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleFeatureClick = (value) => {
		console.log('handleFeatureClick called');
		console.log(value);
	};
	const deleteButtonHandler = (rowdata) => {
		let featureid = rowdata[0];
		Swal.fire({
			title: 'Are you sure ?',
			text: '',
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok',
			backdrop: false,
			target: document.getElementById('swal2-container'),
		}).then((result) => {
			if (result.isConfirmed) {
				JiraAccountService.DeleteAppendFeatureJiraIssue(featureid).then(
					(response) => {
						if (response.status === 'SUCCESS') {
							Swal.fire({
								title: 'feature Deleted Successfully!',
								text: '',
								icon: 'success',
								target: document.getElementById('swal2-container'),
							});
							window.location.reload();
						} else {
							toast.error(response.errorMessage);
						}
					}
				);
			}
		});
	};

	const frMenu = [
		{
			name: 'featureId',
			label: 'FR ID',
			options: {
				display: false,
			},
		},
		{
			name: 'featurecustomid',
			label: 'FR ID',
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Link key={value} to={'/selectfeature/' + value}>
							<Typography
								className={classes.typography}
								color="primary"
								onClick={() => handleFeatureClick(value)}
								aria-describedby={id}
								variant="contained"
							>
								{value}
							</Typography>
						</Link>
					);
				},
			},
		},
		{
			name: 'name',
			label: 'Appended Name',
		},
		{
			name: 'featurePriority',
			label: 'Feature Priority',
		},
		{
			name: 'createdby',
			label: 'Appended By',
		},
		{
			name: 'appendScore',
			label: 'OT Score',
		},
		{
			name: 'productScore',
			label: 'Product Score',
		},
		{
			name: 'salesScore',
			label: 'Sales Score',
		},

		{
			name: 'Actions',
			options: {
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					// const { classes } = this.props;
					let { rowData } = tableMeta;
					return (
						<IconButton
							color="primary"
							aria-label="Edit"
							component="span"
							sizeSmall
							style={{ height: '18px' }}
							onClick={() => deleteButtonHandler(rowData)}
						>
							<DeleteIcon margin={0} />
						</IconButton>
					);
				},
			},
		},
	];

	const options = {
		selectableRows: false,
		print: false,
		download: false,
		viewColumns: false,
		search: false,
		filterType: 'textField',
		filter: false,
		rowsPerPage: 4,
		rowsPerPageOptions: [4, 5, 10, 15, 20, 25],
	};

	console.log(jiradatatable);

	return (
		<div>
			<Typography
				className={classes.typography}
				color="primary"
				onClick={handleClick}
				aria-describedby={id}
				variant="contained"
			>
				{props.numberofappend}
			</Typography>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{isLoading && <CircularProgress />}
				{!isLoading && (
					<Dialog
						onClose={handleClose}
						aria-labelledby="simple-dialog-title"
						open={open}
					>
						<div id="swal2-container"></div>
						<MUIDataTable
							data={jiradatatable}
							columns={frMenu}
							options={options}
						/>
					</Dialog>
				)}
			</Popover>
		</div>
	);
}
