/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:50:20
 * @modify date 2022-03-31 09:52:12
 * @desc this is navbar component and this is just like a root of our project as of now.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Icon from '@material-ui/core/Icon';
import { Route, Link } from 'react-router-dom';
import DashboardComponent from '../DashboardComponent/DashboardComponent';
import ProductComponent from '../ProductComponent/ProductComponent';
import ModuleComponent from '../ModuleComponent/ModuleComponent';
import FeatureComponent from '../FeatureComponent';
import MetricHomeComponent from '../RankingComponent';
import MetricEdit from '../RankingComponent/MetricEdit';
import ProductMetricEdit from '../MetricsProduct/ProductMetricEdit';
import ProductMetricCreate from '../MetricsProduct/ProdcutMetricCreate';
import ConfigureMetricComponent from '../RankingComponent/ConfigureMetricComponent';
import MetricDashboardComponent from '../RankingComponent/Dashboard';
import LoginPageComponent from '../RankingComponent/loginpage';
import BucketComponent from '../BucketComponent';
import ProductBucketComponent from '../ProductBucketComponent';
import UserComponent from '../UserComponent';
import ChangePassword from '../UserComponent/ChangePasswordComponent';
import ReportComponent from '../ReportComponent/ReportComponent';
import OpportunityReportComponent from '../OpportunityReportComponent';
import DoneReportComponent from '../ReportComponent/DoneReportComponent';
import SubmitFeature from '../FeatureComponent/SubmitFeature';
import AccountComponent from '../AccountComponent';
import AccountEdit from '../AccountComponent/AccountEdit';
import UserEdit from '../UserComponent/UserEdit';
import ActivateSalesForceAccount from '../SalesForceComponent';
import SettingComponent from '../SettingComponent/SettingComponent';
import AddSfdcComponent from '../SettingComponent/AddSfdcComponent';
import AddJiraComponent from '../SettingComponent/AddJiraComponent';
import SelectedFeatureComponent from '../SelectedFeatureComponent';
import {
	powerAdminMenuList,
	tenantAdminMenuList,
	userMenuList,
} from '../DataSets/MenuDataSet';
import OpportunityComponent from '../DashboardComponent/Opportunity';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import UserService from '../../Services/api/User';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import ProductMetricDashboardComponent from '../MetricsProduct/ProductMetricDashboardComponent';
import SelectFeatureByIdComponent from '../SelectFeatureByIdComponent';
import AddCsvComponent from '../SettingComponent/AddCsvComponent';
import AddGsheetComponent from '../SettingComponent/AddGsheetComponent';
import getTitle from './title';
import BubbolChart from '../GraphsComponents/BubbolChart';

const drawerWidth = 240;
const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	avatar: {
		margin: 2,
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	my_link: {
		textDecoration: 'none',
	},
	appBar: {
		// zIndex: theme.zIndex.drawer + 1,
		zIndex: '1100',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// bottom: 0,
	},
	appBarbottom: {
		top: 'auto',
		bottom: 0,
		maxHeight: '40px',
	},
	menuStyle: {
		fontSize: '.875rem',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
		marginLeft: '100px',
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		height: '100vh',
		overflow: 'auto',
	},
	chartContainer: {
		marginLeft: -22,
	},
	tableContainer: {
		height: 320,
	},
	h5: {
		marginBottom: theme.spacing(2),
	},
	active: {
		backgroundColor: theme.palette.action.selected,
	},
	selected: {
		backgroundColor: 'turquoise !important',
		color: 'white',
		fontWeight: 600,
	},
	text: {
		padding: theme.spacing(2, 2, 0),
	},
	paper: {
		paddingBottom: 50,
	},
	list: {
		marginBottom: theme.spacing(2),
	},
	subheader: {
		backgroundColor: theme.palette.background.paper,
	},
	menuFont: {
		paddingTop: '.75rem',
		paddingBottom: '.75rem',
		fontSize: '0.875rem',
		lineHeight: 1.75,
		fontWeight: 500,
		color: '#6b778c',
	},
	grow: {
		flexGrow: 1,
	},
});

class Navbar extends React.Component {
	constructor(props) {
		super(props);
		this.localStorageUpdated = this.localStorageUpdated.bind(this);
		this.state = {
			open: false,
			menuList: '',
			mainlistListItems: '',
			title: 'Dashboard',
			themetype: 'light',
			anchorEl: null,
			selected: null,
			selectedIndex: null,
			adminSubmenuItem: [],
			showAdminSubmenuItem: false,
			submenu: [],
			// setAnchorEl: null
		};
	}

	updateSelected(selectedIndex) {
		this.setState({ selectedIndex: selectedIndex });
		// this.setState({selected:true});
		//   alert(selected)
	}
	toggleSubmenu = (menu) => {
		this.getMenu(menu);
		if (this.state.showAdminSubmenuItem) {
			this.setState({ showAdminSubmenuItem: false });
		} else {
			this.setState({ showAdminSubmenuItem: true });
		}
	};

	getMenu = (menu) => {
		// let menu = this.state.menuList;
		console.log('get menu called');
		console.log(menu);
		if (menu) {
			console.log(menu);
		}
		let menuItems = menu.map((item, index) => {
			const style = {
				textDecoration: 'none',
			};
			const { classes } = this.props;
			const { selectedIndex } = this.state;
			// const  { open,anchorEl } = Boolean(this.state.anchorEl);
			console.log(item);
			let submenu;
			if (item.submenu) {
				submenu = item.submenu.map((item, index) => {
					const style = {
						textDecoration: 'none',
					};
					const { classes } = this.props;
					const { selectedIndex } = this.state;
					console.log('submenu iteam called.');
					console.log(item);
					return (
						<Link
							key={item.name}
							style={style}
							to={item.path}
							// onClick={() => this.setTitleHandler(item.name)}
						>
							<ListItem
								name={index}
								key={item.name + 'listitem'}
								className={classes.menuFont}
								onClick={() => this.updateSelected(index)}
								id={selectedIndex}
							>
								<ListItemIcon>
									<Icon style={style} color="primary">
										{item.icon}
									</Icon>
								</ListItemIcon>
								<ListItemText style={style} primary={item.name} />
							</ListItem>
						</Link>
					);
				});
			}
			this.setState({
				submenu,
			});

			return (
				<>
					<Link
						key={index}
						style={style}
						to={item.path}
						// onClick={() => this.setTitleHandler(item.name)}
					>
						<ListItem
							name={index}
							key={index}
							className={classes.menuFont}
							onClick={() => this.updateSelected(index)}
							id={selectedIndex}
						>
							<ListItemIcon>
								<Icon
									style={style}
									color="primary"
									onClick={(item) => this.toggleSubmenu(menu)}
								>
									{item.icon}
								</Icon>
							</ListItemIcon>
							<ListItemText
								style={style}
								color="primary"
								onClick={(item) => this.toggleSubmenu(menu)}
								primary={item.name}
							/>
							{item.submenu && item.submenu.length > 1 && (
								<ListItemIcon onClick={(item) => this.toggleSubmenu(menu)}>
									{this.state.showAdminSubmenuItem && <IconExpandLess />}
									{!this.state.showAdminSubmenuItem && <IconExpandMore />}
									{/* {this.state.showAdminSubmenuItem + ' '} */}
								</ListItemIcon>
							)}
						</ListItem>
					</Link>
					{this.state.showAdminSubmenuItem && submenu}
				</>
			);
		});
		this.setState({ mainlistListItems: menuItems });
	};

	componentWillUnmount() {
		if (typeof window !== 'undefined') {
			window.removeEventListener('storage', this.localStorageUpdated);
		}
	}

	localStorageUpdated() {
		console.log('localstorage update');
		if (localStorage.getItem('title')) {
			this.setState({ title: localStorage.getItem('title') });
		}
	}

	componentDidMount() {
		console.log('navbar component did mount called');
		console.log(this.props.match);

		if (typeof window !== 'undefined') {
			window.addEventListener('storage', this.localStorageUpdated);
		}
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		UserService.get(user.id).then((response) => {
			if (response.status === 'SUCCESS') {
				localStorage.setItem('UserName', response.userDTOS[0]['firstName']);
			}
		});
		let roleName = undefined;
		let menu = tenantAdminMenuList;
		if (user.roles !== undefined) {
			roleName = user.roles[0];
		}
		if (roleName === 'SUPER_ADMIN') {
			menu = powerAdminMenuList;
			console.log(powerAdminMenuList);
			this.setState({ menuList: menu });
			this.getMenu(menu);
		} else if (roleName === 'USER') {
			menu = userMenuList;
			this.setState({ menuList: menu });
			this.getMenu(menu);
		} else if (roleName === 'ADMIN') {
			menu = tenantAdminMenuList;
			this.setState({ menuList: menu });
			this.getMenu(menu);
		}

		this.setState({ title: localStorage.getItem('title') });
	}

	// setTitleHandler = (name) => {
	// 	this.setState({ title: name });
	// };

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	logoutHandler = () => {
		localStorage.clear();
		sessionStorage.clear();
		window.location.reload();
		window.location.href = '/';
	};
	changePassword = () => {
		window.location.href = '/changepassword';
	};

	handleMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};
	handleClose = (event) => {
		this.setState({ anchorEl: null });
	};

	handleSelect = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		//alert(event.target.name)
	};

	themeHandler = () => {
		if (this.state.themetype === 'dark') {
			this.setState({ themetype: 'light' });
		} else {
			this.setState({ themetype: 'dark' });
		}
	};

	render() {
		const { classes } = this.props;
		const { open } = Boolean(this.state.anchorEl);

		const theme = createMuiTheme({
			typography: {
				useNextVariants: true,
			},
			palette: {
				type: 'light',
				primary: {
					main: '#0288d1',
				},
				secondary: {
					main: '#f06292',
				},
			},
		});
		const count = 5;

		return (
			<MuiThemeProvider theme={theme}>
				<div className={classes.root}>
					<CssBaseline />
					<Drawer
						variant="permanent"
						classes={{
							paper: classNames(
								classes.drawerPaper,
								!this.state.open && classes.drawerPaperClose
							),
						}}
						open={this.state.open}
					>
						<div>
							<List
								onMouseEnter={this.handleDrawerOpen}
								onMouseLeave={this.handleDrawerClose}
							>
								{this.state.mainlistListItems}
							</List>
						</div>
						<Divider />
					</Drawer>
					<main className={classes.content}>
						<div className={classes.appBarSpacer} />
						<div>
							<Route path="/" exact component={OpportunityComponent} />
							{/* <Route path="/dashboard" exact component={DashboardComponent} /> */}
							<Route path="/dashboard" exact component={OpportunityComponent} />
							<Route path="/module" exact component={ModuleComponent} />
							<Route path="/product" exact component={ProductComponent} />
							<Route path="/opportunities" exact component={FeatureComponent} />
							<Route
								path="/metrics"
								exact
								component={MetricDashboardComponent}
							/>
							<Route path="/metric" exact component={MetricHomeComponent} />
							<Route
								path="/productmetric"
								exact
								component={ProductMetricCreate}
							/>
							<Route path="/buckets" exact component={BucketComponent} />
							<Route
								path="/productbuckets"
								exact
								component={ProductBucketComponent}
							/>
							<Route
								path="/configuremetric"
								exact
								component={ConfigureMetricComponent}
							/>
							<Route
								path="/productmetricsummary"
								exact
								component={ProductMetricDashboardComponent}
							/>
							<Route path="/metricedit" exact component={MetricEdit} />
							<Route
								path="/productmetricedit/:metricId"
								exact
								component={ProductMetricEdit}
							/>
							<Route path="/users" exact component={UserComponent} />
							<Route path="/settings" exact component={SettingComponent} />
							<Route path="/reports" exact component={ReportComponent} />
							<Route
								path="/:role/reports/:status"
								exact
								component={ReportComponent}
							/>
							<Route
								path="/:role/reports/:status/oppstatus/:oppstatus"
								exact
								component={ReportComponent}
							/>
							<Route
								path="/donereports"
								exact
								component={DoneReportComponent}
							/>
							<Route path="/submitfeature" exact component={SubmitFeature} />
							<Route path="/accounts" exact component={AccountComponent} />
							<Route path="/accountsedit" exact component={AccountEdit} />
							<Route path="/addsfdc" exact component={AddSfdcComponent} />
							<Route path="/addjira" exact component={AddJiraComponent} />
							<Route path="/addcsv" exact component={AddCsvComponent} />
							<Route path="/addgsheet" exact component={AddGsheetComponent} />
							<Route path="/useredit" exact component={UserEdit} />
							<Route path="/loginpage" exact component={LoginPageComponent} />
							<Route
								path="/selectedfeature"
								exact
								component={SelectedFeatureComponent}
							/>
							<Route
								path="/selectfeature/:featureId"
								exact
								component={SelectFeatureByIdComponent}
							/>
							<Route path="/opptable" exact component={OpportunityComponent} />
							<Route path="/changepassword" exact component={ChangePassword} />
							<Route
								path="/salesforce/oauth/callback"
								exact
								component={ActivateSalesForceAccount}
							/>
							<Route
								path="/opportunity/report"
								exact
								component={OpportunityReportComponent}
							/>
							<Route path="/charts" exact component={BubbolChart} />
						</div>
					</main>
				</div>
				<AppBar
					position="fixed"
					color="default"
					className={classes.appBarbottom}
				>
					<Toolbar>
						<Typography
							variant="subtitle2"
							component={'div'}
							className={classes.title}
							gutterBottom
						>
							<b>{new Date().getFullYear()} © OT-Service</b> all rights reserved
							by OT-Services-MVP-1.1
						</Typography>

						<div className={classes.grow} />
					</Toolbar>
				</AppBar>
			</MuiThemeProvider>
		);
	}
}

Navbar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navbar);
