import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import JiraAccountService from '../../Services/api/JiraAccount';
import Swal from 'sweetalert2';
import FeatureService from '../../Services/api/Feature';
import PopupTable from '../DashboardComponent/Popuptable';

const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
});

class DoneReportComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			totalsize: 10,
			tableData: undefined,
			loading: false,
		};
	}
	tabledata = [];

	/*
  JIRA ID - Done
  APPENDS - Done
  JIRA STATUS -Done
  OT/FR SCORE - Done
  Opportunity Name  - Done
  Account Name - Done
  SUBMITTER SALES - Done
  $PROBABILITY NEEDED BY TIME -Done 
  PRIORITY OPPORTUNITY - Done
  STATUS - Donee
  PRODUCT SCORE - Done
	 */

	getJiraIdAsHyperLink(value, tableMeta, updateValue) {
		const { classes } = this.props;
		let jira_url = localStorage.getItem('jiraURL');
		if (jira_url.charAt(jira_url.length - 1) !== '/') jira_url = jira_url + '/';
		let jiraurl = `${jira_url}browse/${value}`;
		return (
			<a
				href={jiraurl}
				target="_blank"
				style={{ color: '#039be5', textDecoration: 'none' }}
			>
				{value}
			</a>
		);
	}

	getNumberOfAppendAsHyperLink(value, tableMeta, updateValue) {
		const open = Boolean(this.state.anchorEl);
		let { rowData } = tableMeta;
		const id = open ? 'simple-popover' : undefined;
		return <PopupTable numberofappend={value} jiraid={rowData[1]} />;
	}

	getJiraStatusWithCSS(value, tableMeta, updateValue) {
		const { classes } = this.props;

		if (value && value.toUpperCase() === 'to do'.toUpperCase())
			return (
				<p
					style={{
						display: 'inline',
						backgroundColor: '#9F6000',
						borderRadius: '3',
						color: '#ffffff',
					}}
				>
					{value}
				</p>
			);
		if (value && value.toUpperCase() === 'done'.toUpperCase())
			return (
				<p
					style={{
						display: 'inline',
						backgroundColor: '#4F8A10',

						color: '#ffffff',
					}}
				>
					{value}
				</p>
			);
		if (value && value.toUpperCase() === 'in progress'.toUpperCase())
			return (
				<p
					style={{
						display: 'inline',
						backgroundColor: '#00529B',
						color: '#ffffff',
					}}
				>
					{value}
				</p>
			);
		return <p>{value}</p>;
	}

	/*
	OT/FR SCORE -done
	Opportunity Name -done
	Account Name -done
	SUBMITTER SALES - done
	Amount 
	PROBABILITY - done
	NEEDED BY TIME - 
	PRIORITY -done 
	OPPORTUNITY STATUS - Done
	PRODUCT SCORE
*/

	frMyMenu = [
		{
			name: 'id',
			label: 'ID',
			options: {
				display: false,
			},
		},
		{
			name: 'frid',
			label: 'FR ID',
			options: {
				display: false,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getJiraIdAsHyperLink(value, tableMeta, updateValue),
			},
		},
		{
			name: 'jiraid',
			label: 'Jira ID',
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getJiraIdAsHyperLink(value, tableMeta, updateValue),
			},
		},
		{
			name: 'numberOfAppend',
			label: 'Appends',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getNumberOfAppendAsHyperLink(value, tableMeta, updateValue),
			},
		},
		{
			name: 'jiraStatus',
			label: 'Jira Status',
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getJiraStatusWithCSS(value, tableMeta, updateValue),
			},
		},
		{
			name: 'otFrScore',
			label: 'OT/FR Score',
		},
		{
			label: 'Opportunity Name',
			name: 'oppName',
		},
		{
			label: 'Account Name',
			name: 'oppAccountName',
		},
		{
			name: 'createdby',
			label: 'Created By',
		},

		{ name: 'oppAmount', label: 'Amount' },

		{
			label: 'Probability',
			name: 'oppProbability',
		},

		{ name: 'oppCloseDate', label: 'CloseDate' },

		{
			label: 'Priority',
			name: 'oppPriority',
		},

		{
			label: 'Status',
			name: 'oppStatus',
		},
		{
			label: 'Product Score',
			name: 'oppProductScore',
		},

		{
			name: 'name',
			label: 'Name',
			options: {
				display: true,
			},
		},
	];

	componentDidMount() {
		console.log('componeent did mount called');
		this.getDataByUser(0, 10);
	}

	getDataByUser = (pageindex, pagesize) => {
		this.setState({ loading: true });
		this.tabledata = [];
		JiraAccountService.getFeatureWithJiraOppDetailsByJirastatus(
			pageindex,
			pagesize,
			'Done'
		).then((response) => {
			if (response.status === 'SUCCESS') {
				let itemList = response.featurelist;
				this.allFeatureDataList = response.featurelist;
				this.setState({ totalsize: response.totalsize });
				itemList.forEach((item) => {
					console.log(item);
					let opp = {
						id: item.id,
						frid: item.customFeatureId,
						jiraid: item.destinationId,
						name: item.jiraDetails.issueName,
						score: parseFloat(item.score).toFixed(2), // item.score,
						rank: item.rank,
						createdby: item.createdBy,
						numberOfAppend: item.numberOfAppend,
						jiraStatus: item.jiraDetails.jiraStatus.name,
						oppName: item.oppDetails.name,
						oppAccountName: item.oppDetails.parameters.account.Name,
						oppProbability: item.oppDetails.parameters.probability,
						oppPriority: item.oppDetails.parameters.priority__c,
						oppStatus: item.oppDetails.parameters.stageName,
						oppProductScore: parseFloat(item.productScore).toFixed(2), //,
						oppAmount: item.oppDetails.parameters.amount,
						oppCloseDate: item.oppDetails.parameters.closeDate,
						otFrScore:
							parseFloat(item.oppDetails.otscore).toFixed(2) +
							'/' +
							parseFloat(item.score).toFixed(2),
					};
					this.tabledata.push(opp);
				});
				//"missingJira":["OTPROD-225"]
				if (response.missingJira && response.missingJira.length > 0) {
					Swal.fire({
						title: 'Missing Jira!',
						text:
							'Jira Id ' +
							response.missingJira +
							' seems to be deleted. Corresponding FR will be delinked.',
						icon: 'warning',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					}).then((result) => {
						if (result.isConfirmed) {
							console.log(response.missingJira);
							FeatureService.deleteFeaturesByJiraId(response.missingJira).then(
								(deleteResponse) => {
									if (deleteResponse === true) {
										Swal.fire(
											'Delinked!',
											// if we can grep the FR list. show FR here.
											'Your FR has been delinked.',
											'success'
										);
									}
								}
							);
						}
					});
				}

				this.setState({ tableData: this.tabledata });
				this.setState({ totalsize: response.totalsize });
				this.setState({ loading: false });
			}
		});
	};

	render() {
		let options = {
			selectableRows: false,
			print: false,
			download: true,
			confirmFilters: true,
			// viewColumns: false,
			// search: true,
			count: this.state.totalsize,
			filterType: 'textField',
			//filter: false,
			rowsPerPage: 4,
			// print: true,
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			serverSide: true,
			onTableChange: (action, tableState) => {
				console.log(action, tableState);
				switch (action) {
					case 'changePage':
						//this.changePage(tableState.page, tableState.sortOrder);
						this.getDataByUser(tableState.page, tableState.rowsPerPage);
						break;
					case 'changeRowsPerPage':
						this.getDataByUser(tableState.page, tableState.rowsPerPage);
						break;
				}
			},
		};

		return (
			<MUIDataTable
				title={
					<Typography variant="h6">
						Report
						{this.state.loading && (
							<CircularProgress
								size={24}
								style={{ marginLeft: 15, position: 'relative', top: 4 }}
							/>
						)}
					</Typography>
				}
				data={this.state.tableData}
				columns={this.frMyMenu}
				options={options}
			/>
		);
	}
}
export default withStyles(useStyles)(DoneReportComponent);
