export const menuList = [
	{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' },
	{ name: 'Product', icon: 'flip_to_back', path: '/product' },
	{ name: 'Module', icon: 'flip_to_front', path: '/module' },
	{ name: 'Submodule', icon: 'subject', path: '/opportunities' },
	{ name: 'Opportunity', icon: 'trending_up', path: '/metrics' },
	// { name: "CTI", icon: "flip_to_back", path: "cti" },
	{ name: 'Buckets', icon: 'list_alt', path: '/buckets' },
	{ name: 'Users', icon: 'people', path: '/users' },
	{ name: 'Configuration', icon: 'settings_applications', path: '/settings' },
	{ name: 'Orders', icon: 'bubble_chart', path: '/orders' },
	{ name: 'Customers', icon: 'people', path: '/customers' },
	{ name: 'Reports', icon: 'bar_chart', path: '/reports' },
	{ name: 'Account', icon: 'people', path: '/accounts' },
	{ name: 'Integrations', icon: 'layers', path: '/integrations' },
];

export const powerAdminMenuList = [
	{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' },
	{ name: 'Account', icon: 'people', path: '/accounts' },
	{ name: 'Users', icon: 'people', path: '/users' },
	{ name: 'Reports', icon: 'bar_chart', path: '/reports' },
];

export const tenantAdminMenuList = [
	{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' },
	{ name: 'Opportunity', icon: 'trending_up', path: '/opportunities' },

	{
		name: 'Reports',
		icon: 'bar_chart',
		path: '',
		submenu: [
			///reports
			{ name: 'Feature Report', icon: 'person_icon', path: '/reports' },
			{
				name: 'Opportunity Report',
				icon: 'person_icon',
				path: '/opportunity/reports',
			},
		],
	},

	{
		name: 'Admin',
		icon: 'supervisor_account',
		path: '',
		submenu: [
			{ name: 'Users', icon: 'person_icon', path: '/users' },
			{
				name: 'Extensions',
				icon: 'settings_applications',
				path: 'settings',
			},
			{ name: 'Sales Metrics', icon: 'flip_to_back', path: '/metrics' },
			{
				name: 'Product Metrics',
				icon: 'flip_to_back',
				path: '/productmetricsummary',
			},
			{ name: 'Sales Buckets', icon: 'list_alt', path: '/buckets' },
			{ name: 'Product Buckets', icon: 'list_alt', path: '/productbuckets' },
		],
	},
];

export const userMenuList = [
	{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' },
	{ name: 'Opportunity', icon: 'trending_up', path: '/opportunities' },
	{ name: 'Reports', icon: 'bar_chart', path: '/reports' },
	{ name: 'Extensions', icon: 'settings_applications', path: '/settings' },
];

//Metric data type
export const metricDataType = {
	Currency: 'Currency',
	Probability: 'Probability',
	Priority: 'Priority',
	Date: 'Date',
	Customer: 'Customer',
};

//User Roles - Tenant
export const powerUserRoleTypes = { SUPER_ADMIN: 'SUPER_ADMIN' };

//User Roles - Tenant
export const roleTypes = {
	ADMIN: 'ADMIN',
	PRODUCT_ADMIN: 'PRODUCT ADMIN',
	SALES_ADMIN: 'SALES ADMIN',
	USER: 'USER',
};

//Percentage 10 to 100 Range
export const percentageDataSet = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

//Probability 0.1 to 1.0 Range
// export const probabilityDataSet = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
export const probabilityDataSet = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
//Priority Range
export const priorityDataSet = ['Critical', 'High', 'Medium', 'Low'];

//Currency Range
export const currencyDataSet = [
	'is greater than',
	'in between',
	'is less than',
];

//Customer
export const customerDataSet = [
	{ label: 'Cisco' },
	{ label: 'TCS' },
	{ label: 'CTS' },
	{ label: 'BayInfotech' },
	{ label: 'Deeplore' },
];

export const featurePriority = [
	{ name: 'Must Have' },
	{ name: 'Should Have' },
	{ name: 'Could Have' },
	{ name: "Won't Have" },
];
