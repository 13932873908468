/**
 * @author Gaurav Sharma, Harshal
 * @desc Metric Edit Module
 */

import React, { Component } from 'react';
import { metricDataType } from '../DataSets/MenuDataSet';
import MetricService from '../../Services/api/Metric';
import AddForm from '../CommonComponents/UpdateTemplate';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import ToastServive from 'react-material-toast';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { FormGenerator } from 'dynamic-material-ui';
import * as MUI from '@material-ui/core/';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Header from '../CommonComponents/Header';
import CustomToolbar from '../RankingComponent/MetricToolBar';
import jwt_decode from 'jwt-decode';
const styles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
	paper: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '50%',
		margin: `${theme.spacing.unit}px auto`,
		marginTop: '77px',
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '50%',
		margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 2,
	},
	appBar: {
		padding: 0,
		//position: "fixed",
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
	flex: {
		flex: 1,
	},
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

/**
 * Feature Request - Metric {metric} module. This module will be
 * accessed only by Tenant Admin, Sales Manager & Product Manager
 */
class ProductMetricEdit extends Component {
	constructor(props) {
		super(props);
		var stateObj = this.props.location.state;
		this.state = {
			metricName: '',
			weightage: stateObj['data'][3],
			accounts: [],
			open: true,
		};
	}
	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
		//this.props.history.push("/ranking");
		localStorage.setItem('isRedirect', true);
		localStorage.setItem('redirectPath', '/productmetricsummary');
		this.props.history.push('/dashboard');
	};

	componentDidMount() {
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		if (role === 'user') return <CustomToolbar path="/dashboard" />;
		localStorage.setItem('title', 'Product Metric');
		const { metricId } = this.props.match.params;
	}
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});
	//Account update submit handler
	submitHandler = () => {
		let id = this.props.location.state['data'][0];
		let data = this.props.location.state['data'];
		let request = {
			id: id,
			name: data[1],
			weightage: this.state.weightage,
			metricType: 'PRODUCT',
		};
		MetricService.update(request).then((response) => {
			if (response.status === 'SUCCESS') {
				this.props.history.push('/productmetricsummary');
				this.toast.success('Metric updated successfully...', () => {
					console.log('closed');
				});
			} else if (response.status === 'FAILURE') {
				this.toast.error(response.errorMessage);
			} else {
				this.toast.error('Problem in updating metric...', () => {
					console.log('closed');
				});
			}
		});
	};

	//Metric remove delete handler
	deleteHandler = (data) => {
		let stateObj = this.props.location.state;
		let metricId = stateObj['data'][0];
		MetricService.remove(metricId).then((response) => {
			if (response.status === 'SUCCESS') {
				this.props.history.push('/productmetricsummary');
				this.toast.success('Metric removed successfully...', () => {
					console.log('closed');
				});
				window.location.reload();
			} else {
				this.toast.error('Problem in removing metric...', () => {
					console.log('closed');
				});
			}
		});
	};
	changeHandle = (e) => {
		console.log([e.target.name] + ':' + e.target.value);
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	render() {
		const { classes } = this.props;
		if (this.props.location.state === undefined) {
			this.props.history.push('/productmetricsummary');
		}
		var stateObj = this.props.location.state;
		let types = [];
		return (
			<React.Fragment>
				<Header title="Product Metric Edit" />
				<Dialog
					fullScreen
					open={this.state.open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar className={classes.appBar} position="absolute">
						<Toolbar>
							<IconButton
								color="inherit"
								onClick={this.handleClose}
								aria-label="Close"
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<React.Fragment>
						<Grid item>
							<Paper className={classes.paper}>
								<AppBar position="static" className={classes.appBar}>
									<Toolbar>
										<Typography variant="h6" color="inherit">
											{'Product  Metric Update'}
										</Typography>
									</Toolbar>
								</AppBar>
							</Paper>
							<Paper className={classes.paper1}>
								{/* <Grid container spacing={30}> */}
								<FormControl margin="normal" required fullWidth>
									<InputLabel htmlFor="text">Name </InputLabel>
									<Input
										id="metricName"
										name="metricName"
										disabled
										value={stateObj['data'][1]}
									/>
								</FormControl>
								<FormControl margin="normal" required fullWidth>
									<InputLabel htmlFor="text">Weightage </InputLabel>
									<Input
										id="weightage"
										name="weightage"
										value={this.state.weightage}
										onChange={this.changeHandle}
									/>
								</FormControl>
								<br></br>
								<Grid item align="center" xs={6} sm={12}>
									{/* <Button
                    variant="contained"
                    className={classes.button}
                    size="medium"
                    color="primary"
                    onClick={this.deleteHandler}
                  >
                    Delete
                    <DeleteIcon className={classes.rightIcon} />
                  </Button> */}

									<Button
										variant="contained"
										className={classes.button}
										size="medium"
										color="primary"
										onClick={this.submitHandler}
									>
										{'Update'}
										<SaveIcon className={classes.rightIcon} />
									</Button>
								</Grid>
								{/* </Grid> */}
							</Paper>
						</Grid>
					</React.Fragment>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(ProductMetricEdit);
