/**
 * @author Ramya Jhey
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import './custom.css';
import ConnectorService from '../../Services/api/Connectors';
import IntegrationService from '../../Services/api/Integration';
import Header from '../CommonComponents/Header';
import CustomToolbar from '../RankingComponent/MetricToolBar';
import jwt_decode from 'jwt-decode';
const useStyles = (theme) => ({
	appBar: {
		position: 'relative',
	},
	card: {
		// minWidth: 85,
		minWidth: 120,
		cursor: 'pointer',
		borderRadius: '10px',
		'&:hover': {
			background: '#ededed',
		},
	},
	carddisabled: {
		// minWidth: 75,
		minWidth: 120,
		cursor: 'default',
		borderRadius: '10px',
		'&:hover': {
			background: '#ededed',
		},
		background: '#ededed',
	},
	bigAvatarSFDC: {
		margin: 10,
		width: 75,
		height: 75,
	},
	bigAvatarJIRA: {
		background: '#94cc7d',
		margin: 10,
		width: 75,
		height: 75,
	},
	button: {
		margin: theme.spacing(1),
	},
	root: {
		width: 'auto',
		display: 'block',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
			width: 1000,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		padding: theme.spacing.unit * 3,
		textAlign: 'center',
	},
	gridList: {
		width: 500,
		height: 450,
	},
	titleBar: {
		background: '#0288d1',
		height: 25,
	},
	icon: {
		color: 'white',
	},
	gridListTile: {
		height: 120,
		width: 120,
	},
	img: {
		width: 50,
		height: 50,
		left: 20,
	},
	formHelperText: {
		textAlign: 'center',
		color: 'black',
	},
	loadingOverlay: {
		position: 'absolute',
		height: '100vh',
		width: '100vw',
	},
	cardContent: {
		textAlign: '-webkit-center',
		padding: '0px',
	},
	cardAction: {
		display: 'block',
		textAlign: '-webkit-center',
		padding: '0px',
	},
});

class SettingComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchSFDC: [],
			fetchJIRA: [],
			fetchCSV: [],
			isJiraChecked: false,

			isSFDCChecked: false,
			isSFDCShow: false,

			isGsheetChecked: false,
			isGsheetShow: false,

			isCSVChecked: false,
			isCSVShow: false,

			isShowAll: false,
		};
		this.fetchSFDC = this.fetchSFDC.bind(this);
		this.fetchJIRA = this.fetchJIRA.bind(this);
		this.sfdcdata = 'false';
		this.gsheetdata = 'false';
		this.jiradata = 'false';
	}

	submitHandler = (data) => {
		console.log(data);
	};
	fetchSFDC() {
		this.setState({
			sfdcdata: 'true',
		});
	}
	fetchCSV = () => {
		this.setState({
			csvdata: 'true',
		});
	};
	fetchGsheet = () => {
		this.setState({
			gsheetdata: 'true',
		});
	};
	fetchJIRA() {
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		if (role !== 'user') {
			this.setState({
				isCloud: false,
				isJiraChecked: true,
				jiradata: 'true',
			});
			ConnectorService.getJiraConnector().then((response) => {
				if (response.status === 'SUCCESS') {
					this.setState({
						jiradata: 'true',
						isJiraChecked: true,
					});
				} else {
				}
			});
		}
	}

	componentDidMount() {
		this.setState({
			isCloud: false,
			// isJiraChecked: true,
		});

		localStorage.setItem('title', 'Settings');

		IntegrationService.getList().then((response) => {
			localStorage.setItem('integrationList', JSON.stringify(response));
			if (localStorage.getItem('integrationList')) {
				const integrations = JSON.parse(
					localStorage.getItem('integrationList')
				);
				if (
					integrations.length > 0 &&
					integrations[0].toUpperCase() === 'sfdc'.toUpperCase()
				) {
					this.setState({ isSFDCChecked: true });
				}

				if (
					integrations.length > 0 &&
					integrations[0].toUpperCase() === 'csv'.toUpperCase()
				) {
					this.setState({ isCSVChecked: true });
				}
				if (
					integrations.length > 0 &&
					integrations[0].toUpperCase() === 'gsheet'.toUpperCase()
				) {
					this.setState({ isGsheetChecked: true });
				}
			}
		});

		IntegrationService.getListByTenant().then((response) => {
			localStorage.setItem('integrationListByTenant', JSON.stringify(response));
			if (localStorage.getItem('integrationListByTenant')) {
				const tenantIntegrations = JSON.parse(
					localStorage.getItem('integrationListByTenant')
				);
				if (
					tenantIntegrations.length > 0 &&
					tenantIntegrations[0].toUpperCase() === 'sfdc'.toUpperCase()
				) {
					this.setState({ isSFDCShow: true });
				}

				if (
					tenantIntegrations.length > 0 &&
					tenantIntegrations[0].toUpperCase() === 'csv'.toUpperCase()
				) {
					this.setState({ isCSVShow: true });
				}
				if (
					tenantIntegrations.length > 0 &&
					tenantIntegrations[0].toUpperCase() === 'gsheet'.toUpperCase()
				) {
					this.setState({ isGsheetShow: true });
				}
				if (!(tenantIntegrations.length > 0)) {
					this.setState({ isShowAll: true });
				}
			}
		});

		ConnectorService.getJiraConnector().then((response) => {
			if (response.status === 'SUCCESS') {
				console.log('sss', response);
				this.setState({
					isJiraChecked: true,
				});
				//  return <Redirect to='/addjira' />
			} else {
			}
		});
	}

	render() {
		const { classes } = this.props;

		if (this.state.sfdcdata === 'true') {
			return <Redirect to="/addsfdc" />;
		}
		if (this.state.jiradata === 'true') {
			return <Redirect to="/addjira" />;
		}
		if (this.state.csvdata === 'true') {
			return <Redirect to="/addcsv" />;
		}
		if (this.state.gsheetdata === 'true') {
			return <Redirect to="/addgsheet" />;
		}
		console.log(this.state);
		return (
			<div className={classes.root}>
				<Header title="Settings" />

				<Grid container spacing={2}>
					<ul>
						{(this.state.isShowAll ||
							!(!this.state.isSFDCChecked && !this.state.isSFDCShow)) && (
							<li>
								<input
									type="checkbox"
									id="cb1"
									checked={this.state.isSFDCChecked}
								/>
								<label for="cb1" className="imgcheckbox">
									{/* {listItems_one} */}
									<Grid item xs={2}>
										<Card className={classes.card}>
											<CardContent className={classes.cardContent}>
												<Typography variant="body2" component="p">
													<img
														src="assets/images/sfdc.png"
														alt="sfdc"
														className={classes.bigAvatarSFDC}
														onClick={this.fetchSFDC}
													/>
												</Typography>
											</CardContent>
											<CardActions className={classes.cardAction}>
												<h5>SFDC</h5>
											</CardActions>
										</Card>
									</Grid>
								</label>
							</li>
						)}
						<li>
							<input
								type="checkbox"
								id="cb2"
								checked={this.state.isJiraChecked}
							/>
							<label for="cb2" class="imgcheckbox">
								{' '}
								<Grid item xs={2}>
									<Card className={classes.card}>
										<CardContent className={classes.cardContent}>
											<Typography variant="body2" component="p">
												<img
													src="assets/images/jira.png"
													alt="jira"
													className={classes.bigAvatarSFDC}
													onClick={this.fetchJIRA}
												/>
											</Typography>
										</CardContent>
										<CardActions className={classes.cardAction}>
											<h5>JIRA</h5>
										</CardActions>
									</Card>
								</Grid>
							</label>
						</li>
						{(this.state.isShowAll ||
							!(!this.state.isCSVChecked && !this.state.isCSVShow)) && (
							<li>
								<input
									type="checkbox"
									id="cb2"
									checked={this.state.isCSVChecked}
								/>
								<label for="cb2" class="imgcheckbox">
									{' '}
									<Grid item xs={2}>
										<Card className={classes.card}>
											<CardContent className={classes.cardContent}>
												<Typography variant="body2" component="p">
													<img
														src="assets/images/csv.png"
														alt="csv"
														className={classes.bigAvatarSFDC}
														onClick={this.fetchCSV}
													/>
												</Typography>
											</CardContent>
											<CardActions className={classes.cardAction}>
												<h5>CSV</h5>
											</CardActions>
										</Card>
									</Grid>
								</label>
							</li>
						)}
						{(this.state.isShowAll ||
							!(!this.state.isGsheetChecked && !this.state.isGsheetShow)) && (
							<li>
								<input
									type="checkbox"
									id="cb1"
									checked={this.state.isGsheetChecked}
								/>
								<label for="cb1" className="imgcheckbox">
									{/* {listItems_one} */}
									<Grid item xs={2}>
										<Card className={classes.card}>
											<CardContent className={classes.cardContent}>
												<Typography variant="body2" component="p">
													<img
														src="assets/images/gsheet.png"
														alt="sfdc"
														className={classes.bigAvatarSFDC}
														onClick={this.fetchGsheet}
													/>
												</Typography>
											</CardContent>
											<CardActions className={classes.cardAction}>
												<h5>GoogleSheet</h5>
											</CardActions>
										</Card>
									</Grid>
								</label>
							</li>
						)}
					</ul>
				</Grid>
			</div>
		);
	}
}

export default withStyles(useStyles)(SettingComponent);
