/**
 * @author Raghav Prabhu
 * @desc Opportunity settings module
 */
import service from "../Common";
import constants from "../../../settings/shared/constants";
import jwt_decode from "jwt-decode";

function getOpportunities() {
  let jwtToken = localStorage.getItem("token");
  let user = jwt_decode(jwtToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
    method: "GET",
    headers: headers,
  });
}

function getOppById(id) {
  let jwtToken = localStorage.getItem("token");
  let user = jwt_decode(jwtToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.opportunity.get_opp_by_id + "/" + id,
    method: "GET",
    headers: headers,
  });
}

function getMyOpportunities() {
  let jwtToken = localStorage.getItem("token");
  // let user = jwt_decode(jwtToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.opportunity.get_my_opportunity,
    method: "GET",
    headers: headers,
  });
}

function getHistoryByOppId(id) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.opportunity.get_opp_history + "/" + id,
    method: "GET",
    headers: headers,
  });
}
//get_opp_roi

function getOpportunityRoi(data, role) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.opportunity.get_opp_roi + "/",
    method: "POST",
    headers: headers,
    data: data,
  });
}

function getOpportunitiesByPage(pageNumber, pageSize) {
  let jwtToken = localStorage.getItem("token");
  let user = jwt_decode(jwtToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url:
      constants.api.opportunity.get_opportunity +
      "/page/" +
      user.tenant +
      "?page=" +
      pageNumber +
      "&size=" +
      pageSize,
    method: "GET",
    headers: headers,
  });
}

function searchOpportunityByPage(text, pageNumber, pageSize) {
  pageNumber = pageNumber || 1;
  pageSize = pageSize || 10;

  let jwtToken = localStorage.getItem("token");
  let user = jwt_decode(jwtToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: `${constants.api.opportunity.get_opportunity}/${user.tenant}/search?text=${text}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    method: "GET",
    headers: headers,
  });
}

function getNumberOfTotalOpportunity() {
  let jwtToken = localStorage.getItem("token");
  let user = jwt_decode(jwtToken);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: `${constants.api.opportunity.get_opportunity}/${user.tenant}/opp/count`,
    method: "GET",
    headers: headers,
  });
}

//Making to publish all functions
const OpportunityService = {
  getOpportunities,
  getMyOpportunities,
  getOppById,
  getHistoryByOppId,
  getOpportunityRoi,
  getOpportunitiesByPage,
  searchOpportunityByPage,
  getNumberOfTotalOpportunity,
};

export default OpportunityService;
