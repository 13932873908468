import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import JiraAccountService from '../../Services/api/JiraAccount';
import MetricService from '../../Services/api/Metric';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from 'mui-datatables';
import ToastServive from 'react-material-toast';
import { featurePriority } from '../DataSets/MenuDataSet';
import AppendDialog from './AppendDialog';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
	paper: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '80%',
		margin: `${theme.spacing.unit}px auto`,
		marginTop: '77px',
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '80%',
		margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 2,
	},
	appBar: {
		padding: 0,
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	hyperlink: {
		color: '#039be5',
		textDecoration: 'none',
	},
	multilineColor: {
		color: 'black',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
});

class AppendFeature extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			jiraProjectList: [],
			jiraIssueTypeList: [],
			jiraissues: [],
			isIssueLoaded: false,
			isSubmitted: false,
			jiraURL: '',
			featurePriority: '',
			open: false,
			featuredetails: {},
			oppName: '',
			customMetrics: [],
			customMetricsvalue: {},
			isSubmitButtonDisable: false,
			searchContent: '',
		};
		this.myRef = React.createRef();
	}

	searchJiraIssue(searchText) {
		console.log(`seachJiraIssue Called ${searchText}`);
		const { submitdetails, feature } = this.props;
		let issues = [];
		this.setState({ isIssueLoaded: true });
		if (searchText.charAt(searchText.length - 1) === ' ')
			JiraAccountService.SearchJiraIssue(searchText).then((jiraresponse) => {
				if (jiraresponse.length > 0) {
					let jiraitemlist = jiraresponse;
					jiraitemlist.forEach((item) => {
						let issue = {
							jiraId: item.issueId,
							title: item.issueName,
							projectId: item.projectId,
							description: item.description,
							issueid: item.issueTypeId,
							score: parseFloat(item.score).toFixed(2),
						};
						issues.push(issue);
					});

					this.setState({
						isIssueLoaded: true,
						jiraissues: issues,
					});
				}
				this.setState({
					isIssueLoaded: true,
				});
			});
	}

	featureAppendHandler(data, featuredesc, title, id, featurePriority) {
		//  Featureid, status: append, tenentid,userid, salesforcenotesid,
		let dataFrom = JSON.parse(localStorage.getItem('integrationList'))[0];
		let { submitdetails, feature } = this.props;
		let featureappend = {};
		let { rowData } = data;
		let issueid = rowData[0];
		let pastdesc = rowData[3];
		let newdesc = `Appended Feature Details:
        Title-  ${title}
        Desc- ${featuredesc}
        `;
		let desc = newdesc;

		if (dataFrom === 'CSV') {
			//salesforceNoteId
			featureappend = {
				featureRequestRank: submitdetails.featureRequestRank,
				oppId: submitdetails.salesforceNoteId,
				description: desc,
				projectId: rowData[1],
				issueId: rowData[0],
				salesforceNoteId: submitdetails.salesforceNoteId,
				score: parseFloat(submitdetails.score).toFixed(2),
				featurePriority: featurePriority,
				source: 'CSV',
				oppUserId: submitdetails.oppUserId,
			};
		}
		if (dataFrom === 'GSHEET') {
			//salesforceNoteId
			featureappend = {
				featureRequestRank: submitdetails.featureRequestRank,
				oppId: submitdetails.salesforceNoteId,
				description: desc,
				projectId: rowData[1],
				issueId: rowData[0],
				salesforceNoteId: submitdetails.salesforceNoteId,
				score: parseFloat(submitdetails.score).toFixed(2),
				featurePriority: featurePriority,
				source: 'GSHEET',
				oppUserId: submitdetails.oppUserId,
			};
		}
		if (dataFrom == 'SFDC') {
			if (submitdetails.salesforceNoteId) {
				featureappend = {
					featureRequestRank: submitdetails.featureRequestRank,
					oppId: submitdetails.salesforceNoteId,
					description: desc,
					salesforceNoteId: submitdetails.salesforceNoteId,
					projectId: rowData[1],
					issueId: rowData[0],
					score: parseFloat(submitdetails.score).toFixed(2),
					source: 'SFDC',
					featurePriority: featurePriority,
					oppUserId: submitdetails.oppUserId,
				};
			}
		}
		console.log(featureappend);
		JiraAccountService.appendFeatureJiraIssue(issueid, featureappend).then(
			(response) => {
				this.toast.success('Issue Update Successfully');
				this.setState({ isSubmitButtonDisable: false });
				window.location.reload();
			}
		);
	}

	handleClickOpen = (tableMeta, body, title, id) => {
		this.setState({
			featuredetails: {
				tableMeta: tableMeta,
				body: body,
				title: title,
				id: id,
			},
			open: true,
		});
	};

	handleClose = (value) => {
		if (value) {
			this.featureAppendHandler(
				this.state.featuredetails.tableMeta,
				this.state.featuredetails.body,
				this.state.featuredetails.title,
				this.state.featuredetails.id,
				value
			);
		}
		this.setState({
			open: false,
		});
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	columns = [
		{
			label: 'Jira ID',
			name: 'jiraId',
			options: {
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const { classes } = this.props;
					let jira_url = localStorage.getItem('jiraURL');
					let jiraurl = `${jira_url}/browse/${value}`;
					return (
						<a href={jiraurl} target="_blank" className={classes.hyperlink}>
							{value}
						</a>
					);
				},
				serverSide: true,
				onTableChange: (action, tableState) => {
					console.log({ action, tableState });
				},
				searchPlaceholder: 'Your Custom Search Placeholder',
				customSearch: (searchQuery, currentRow, columns) => {
					let isFound = false;
					console.log({ searchQuery, currentRow, columns });
					currentRow.forEach((col) => {
						if (col.toString().indexOf(searchQuery) >= 0) {
							isFound = true;
						}
					});
					return isFound;
				},
			},
		},
		{
			name: 'projectId',
			label: 'Project ID',
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: 'title',
			label: 'FR Title',
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: 'description',
			label: 'Description',
			options: {
				filter: true,
				sort: false,
				display: false,
			},
		},
		{
			name: 'score',
			label: 'Score',
		},

		{
			name: 'issueid',
			label: 'Type',
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: 'Append',
			options: {
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					let { feature } = this.props;
					return (
						<div>
							<Button
								size="small"
								variant="contained"
								color="primary"
								onClick={() =>
									this.handleClickOpen(
										tableMeta,
										feature.body,
										feature.title,
										feature.id
									)
								}
							>
								Append
							</Button>
						</div>
					);
				},
			},
		},
	];

	featureSubmitHandler(
		feature,
		Title,
		Body,
		issueType,
		project,
		featurePriority,
		score,
		rank,
		noteid
	) {
		this.setState({ isSubmitButtonDisable: true });
		let dataFrom = JSON.parse(localStorage.getItem('integrationList'))[0];
		let { submitdetails, changeDialogBoxState } = this.props;
		let submitRequest = {
			...submitdetails,
			issueTypeId: issueType,
			projectId: project,
			featurePriority: featurePriority,
			source: dataFrom,
			customMetrics: JSON.stringify(this.state.customMetricsvalue),
		};
		console.log();
		if (this.state.customMetrics.length > 0) {
			console.log(this.state.customMetricsvalue);
			for (let i = 0; i < this.state.customMetrics.length; i++) {
				if (
					!this.state.customMetricsvalue[
						this.state.customMetrics[i].metricObjectId
					]
				) {
					this.toast.error('pease select all fields');
					return;
				}
				console.log();
			}
		}
		console.log(submitRequest);
		//this.toast.success('Feature submitted on JIRA successfully');
		JiraAccountService.submitFeature(submitRequest).then((response) => {
			this.toast.success('Feature submitted on JIRA successfully');
			window.location.reload();
			changeDialogBoxState();
			this.setState({ isSubmitted: true, isSubmitButtonDisable: false });
		});
	}

	handleChange = (name) => (event) => {
		if (name === 'projects') {
			this.setState({ project: event.target.value });
		} else if (name === 'issueType') {
			this.setState({ issueType: event.target.value });
		} else if (name === 'featurePriority') {
			this.setState({ featurePriority: event.target.value });
		}
	};

	handleCustomEvent = (event) => {
		let currentValue = this.state.customMetricsvalue;
		currentValue[event.target.name] = event.target.value;
		this.setState({ currentValue });
	};

	jiraissue = [];

	componentDidUpdate() {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		const { submitdetails, feature } = this.props;
		let { featureRequestRank } = submitdetails;
		this.setState({ oppName: featureRequestRank.name });
		JiraAccountService.getAllProjects().then((response) => {
			if (response != null) {
				let projects = [];
				for (let index = 0; index < response.length; index++) {
					projects.push(response[index]);
				}
				this.setState({ jiraProjectList: projects });
			}
		});
		MetricService.getAllCustom().then((response) => {
			if (response.status === 'SUCCESS')
				this.setState({ customMetrics: response.metricsDTOS });
		});
		JiraAccountService.getAllIssueType().then((response) => {
			if (response != null) {
				let issueTypes = [];
				for (let index = 0; index < response.length; index++) {
					issueTypes.push(response[index]);
				}
				this.setState({ jiraIssueTypeList: issueTypes });
			}
		});

		JiraAccountService.SearchJiraIssue(feature.title).then((jiraresponse) => {
			if (jiraresponse.length > 0) {
				let jiraitemlist = jiraresponse;
				jiraitemlist.forEach((item) => {
					let issue = {
						jiraId: item.issueId,
						title: item.issueName,
						projectId: item.projectId,
						description: item.description,
						issueid: item.issueTypeId,
						score: parseFloat(item.score).toFixed(2),
					};
					this.jiraissue.push(issue);
				});

				this.setState({
					isIssueLoaded: true,
					jiraissues: this.jiraissue,
				});
			}
			this.setState({
				isIssueLoaded: true,
			});
		});
	}

	showMetricsFields = (metric) => {
		const { classes, feature, submitdetails, changeDialogBoxState } =
			this.props;
		if (metric.type === 'Date') {
			return (
				<tr>
					<td>
						<Typography color="inherit"> {metric.name}</Typography>{' '}
					</td>
					<td>
						<TextField
							id="outlined-basic"
							label={metric.name}
							key={metric}
							required
							type="date"
							name={metric.metricObjectId}
							variant="outlined"
							style={{
								width: 400,
								marginLeft: '20px',
								marginTop: '10px',
							}}
							onChange={this.handleCustomEvent}
						/>
					</td>
				</tr>
			);
		}
		if (metric.type === 'Probability') {
			return (
				<tr>
					<td>
						<Typography color="inherit"> {metric.name}</Typography>{' '}
					</td>
					<td>
						<TextField
							id="outlined-basic"
							label={metric.name}
							key={metric}
							required
							name={metric.metricObjectId}
							variant="outlined"
							type="number"
							style={{
								width: 400,
								marginLeft: '20px',
								marginTop: '10px',
							}}
							onChange={this.handleCustomEvent}
						/>
					</td>
				</tr>
			);
		}
		return (
			<tr>
				<td>
					<Typography color="inherit"> {metric.name}</Typography>{' '}
				</td>
				<td>
					<Select
						native
						defaultValue=""
						// onChange={this.handleChange('featurePriority')}
						// inputProps={{
						// 	name: 'featurePriority',
						// 	id: 'feature-priority',
						// }}
						name={metric.metricObjectId}
						label={metric.name}
						onChange={this.handleCustomEvent}
						style={{ width: 150, marginLeft: 30 }}
						className={classes.selectField}
					>
						<option value="" disabled>
							Select One
						</option>
						{this.showOptions(metric)}
					</Select>
				</td>
			</tr>
		);
	};

	showOptions = (metric) => {
		if (metric.buckets != null && metric.type === 'Priority') {
			return metric.buckets.map((bucket) => (
				<option key={bucket.name} value={bucket.id}>
					{bucket.condition}
				</option>
			));
		}
		if (metric.buckets != null && metric.type === 'Customer') {
			return metric.buckets.map((bucket) => (
				<option key={bucket.name} value={bucket.id}>
					{bucket.name}
				</option>
			));
		}
		//Currency
		if (metric.buckets != null && metric.type === 'Currency') {
			return metric.buckets.map((bucket) => (
				<option key={bucket.name} value={bucket.id}>
					{!bucket.toValue && bucket.condition + ' ' + bucket.fromValue}
					{bucket.toValue &&
						bucket.condition + ' ' + bucket.fromValue + ' to ' + bucket.toValue}
				</option>
			));
		} //Currency
	};

	render() {
		const { classes, feature, submitdetails, changeDialogBoxState } =
			this.props;
		let data = JSON.parse(localStorage.getItem('selectedFeature'));
		let options = {
			selectableRows: false,
			print: false,
			download: true,
			searchText: this.state.searchContent,
			onSearchChange: (searchText) => {
				this.setState({ searchContent: searchText });
				this.searchJiraIssue(searchText);
			},
		};
		let oppName = '';

		if (data.opportunityName) {
			oppName = data.opportunityName;
		}
		if (data.name) {
			oppName = data.name;
		}
		return (
			<div ref={this.myRef}>
				<AppendDialog
					selectedValue={this.state.selectedValue}
					open={this.state.open}
					onClose={this.handleClose}
					featuredetails={this.state.featuredetails}
				/>
				<React.Fragment>
					<Grid item>
						<Paper className={classes.paper}>
							<AppBar position="static" className={classes.appBar}>
								<Toolbar>
									<Typography variant="h6" color="inherit">
										Submit and Append Feature
									</Typography>
								</Toolbar>
							</AppBar>
						</Paper>
						<Paper className={classes.paper1}>
							<List>
								<ListItem key="oppname">
									<TextField
										id="outlined-multiline-static"
										label="Opportunity Name"
										// multiline
										disabled
										InputProps={{
											classes: {
												input: classes.multilineColor,
											},
										}}
										size="small"
										rows={3}
										fullWidth
										color="secondary"
										defaultValue={oppName}
										variant="outlined"
									/>
								</ListItem>
								<ListItem key="featuretitle">
									<TextField
										id="outlined-multiline-static"
										label="Title: "
										// multiline
										disabled
										InputProps={{
											classes: {
												input: classes.multilineColor,
											},
										}}
										size="small"
										rows={3}
										fullWidth
										color="secondary"
										defaultValue={feature.title}
										variant="outlined"
									/>
								</ListItem>
								<ListItem key="featuredesc">
									<TextField
										id="outlined-multiline-static"
										label="Description: "
										multiline
										disabled
										InputProps={{
											classes: {
												input: classes.multilineColor,
											},
										}}
										size="small"
										rows={3}
										fullWidth
										color="secondary"
										defaultValue={feature.body}
										variant="outlined"
									/>
								</ListItem>
								<ListItem key="featureproject">
									<table>
										<tr>
											<td>
												<Typography color="inherit"> Project: </Typography>
											</td>
											<td>
												<Select
													native
													// value={this.state.project}
													defaultValue=""
													onChange={this.handleChange('projects')}
													inputProps={{
														name: 'projects',
														id: 'jira-project',
													}}
													style={{ width: 150, marginLeft: 30 }}
													className={classes.selectField}
												>
													<option value="" disabled>
														Select One
													</option>
													{this.state.jiraProjectList.map((row) => (
														<option key={row.key} value={row.key}>
															{row.name}
														</option>
													))}
												</Select>
											</td>
										</tr>
										<tr>
											<td>
												<Typography color="inherit"> Issue Type:</Typography>{' '}
											</td>
											<td>
												<Select
													native
													// value={this.state.issueType}
													defaultValue=""
													onChange={this.handleChange('issueType')}
													inputProps={{
														name: 'projects',
														id: 'jira-project',
													}}
													style={{ width: 150, marginLeft: 30 }}
													className={classes.selectField}
												>
													<option value="" disabled>
														Select One
													</option>
													{this.state.jiraIssueTypeList.map((row) => (
														<option key={row.key} value={row.id}>
															{row.name}
														</option>
													))}
												</Select>
											</td>
										</tr>
										<tr>
											<td>
												<Typography color="inherit">
													{' '}
													Feature Priority:{' '}
												</Typography>{' '}
											</td>
											<td>
												<Select
													native
													defaultValue=""
													onChange={this.handleChange('featurePriority')}
													inputProps={{
														name: 'featurePriority',
														id: 'feature-priority',
													}}
													style={{ width: 150, marginLeft: 30 }}
													className={classes.selectField}
												>
													<option value="" disabled>
														Select One
													</option>
													{featurePriority.map((row) => (
														<option key={row.name} value={row.name}>
															{row.name}
														</option>
													))}
												</Select>
											</td>
										</tr>
										{this.state.customMetrics.map((metric) =>
											this.showMetricsFields(metric)
										)}
									</table>
								</ListItem>
							</List>

							{!this.state.isSubmitted && (
								<Button
									style={{ marginTop: 20 }}
									size="small"
									variant="contained"
									color="primary"
									disabled={this.state.isSubmitButtonDisable}
									onClick={() =>
										this.featureSubmitHandler(
											data,
											feature.body,
											feature.title,
											this.state.issueType,
											this.state.project,
											this.state.featurePriority,
											feature.score,
											feature.rank
										)
									}
								>
									Submit New
								</Button>
							)}
							<hr />

							<MUIDataTable
								title={
									<Typography variant="h6">
										Jira Tickets one
										{!this.state.isIssueLoaded && (
											<CircularProgress
												size={24}
												style={{
													marginLeft: 15,
													position: 'relative',
													top: 4,
												}}
											/>
										)}
									</Typography>
								}
								data={this.state.jiraissues}
								columns={this.columns}
								options={options}
							/>
						</Paper>
					</Grid>
				</React.Fragment>
			</div>
		);
	}
}

AppendFeature.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppendFeature);
