/**
 * @author Gaurav Sharma
 * @create date 2019-04-15 17:57:18
 * @modify date 2021-10-26 11:14:15
 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
//import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import JiraAccountService from '../../Services/api/JiraAccount';
import { CircularProgress, Typography } from '@material-ui/core';
import OpportunityService from '../../Services/api/Opportunity';
import CsvService from '../../Services/api/Csv';
import GsheetService from '../../Services/api/Gsheet';
import { Link } from 'react-router-dom';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';
import Swal from 'sweetalert2';

const useStyles = (theme) => ({
	multilineColor: {
		color: 'black',
	},
	paper: {
		height: '100%',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			padding: theme.spacing(3),
		},
	},
});

class SelectFeatureByIdComponent extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.state = {
			jiraProjectList: [],
			jiraIssueTypeList: [],
			oppRank: '',
			oppScore: '',
			feature: {},
			isFeatureAvailable: false,
			oppName: '',
			jiraURL: '',
			jiraHistory: [],
		};
	}

	handleChange = (name) => (event) => {
		if (name === 'projects') {
			this.setState({ project: event.target.value });
		} else if (name === 'issueType') {
			this.setState({ issueType: event.target.value });
		}
	};

	comments = [];
	componentWillUnmount() {
		console.log('component will unmount');
		// localStorage.removeItem("selectedFeature");
		// localStorage.removeItem("selectedFeatureWithJiraDetails");
	}

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	getSFDCOppDetails = (response) => {
		OpportunityService.getOppById(response[0].sourceId).then(
			(oppresponse) => {
				console.log('232340------234-234-23');
				let { featureRequestRanks } = oppresponse;
				if (featureRequestRanks.length > 0) {
					console.log(featureRequestRanks[0]);
					console.log(featureRequestRanks[0].name);
					this.setState({
						oppName: featureRequestRanks[0].name,
						feature: response[0],
						isFeatureAvailable: true,
					});
				}

				console.log('232340------234-234-23');
			},
			(error) => {
				Swal.fire({
					title: 'Please Check Your SFDC Connection!',
					text: '',
					icon: 'warning',
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				}).then((result) => {
					if (result.isConfirmed) {
						this.props.history.push('/addsfdc');
					}
				});
			}
		);
	};

	getCSVOppDetails = (response) => {
		CsvService.getOpportunitiesByOppId(response[0].sourceId).then(
			(oppresponse) => {
				console.log('232340------234-234-23');
				console.log(oppresponse);
				let { opportunity } = oppresponse;
				if (opportunity.length > 0) {
					console.log(opportunity[0]);
					console.log(opportunity[0].opportunityName);
					this.setState({
						oppName: opportunity[0].opportunityName,
						feature: response[0],
						isFeatureAvailable: true,
					});
				}
				console.log('232340------234-234-23');
			},
			(error) => {
				Swal.fire({
					title: 'Please Check Your Csv Connection!',
					text: '',
					icon: 'warning',
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				}).then((result) => {
					if (result.isConfirmed) {
						this.props.history.push('/addcsv');
					}
				});
			}
		);
	};
	getGsheetOppDetails = (response) => {
		GsheetService.getOpportunitiesByOppId(response[0].sourceId).then(
			(oppresponse) => {
				let { opportunity } = oppresponse;
				if (opportunity.length > 0) {
					console.log(opportunity[0]);
					console.log(opportunity[0].opportunityName);
					this.setState({
						oppName: opportunity[0].opportunityName,
						feature: response[0],
						isFeatureAvailable: true,
					});
				}

				console.log('232340------234-234-23');
			},
			(error) => {
				Swal.fire({
					title: 'Please Check Your GSheet Connection!',
					text: '',
					icon: 'warning',
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				}).then((result) => {
					if (result.isConfirmed) {
						this.props.history.push('/addgsheet');
					}
				});
			}
		);
	};

	getJiraHistory = (jiraid) => {
		const { classes } = this.props;
		JiraAccountService.getJiraHistory(jiraid).then((response) => {
			console.log(response);
			let jiraHistory = response.map((item, index) => {
				return (
					<div key={index + 'frag'}>
						<ListItem key={index + ''} alignItems="flex-start">
							<ListItemText
								primary={item.content}
								secondary={
									<React.Fragment>
										<Typography
											component="span"
											variant="body2"
											className={classes.inline}
											color="textPrimary"
										>
											{item.field +
												' Updated from ' +
												item.from +
												' to ' +
												item.to}
										</Typography>
										{'- Date ' +
											item.date.dayOfMonth +
											'-' +
											item.date.monthOfYear +
											'-' +
											item.date.year}
									</React.Fragment>
								}
							/>
						</ListItem>
						<Divider
							variant="inset"
							key={item.commentId + 'div'}
							component="li"
						/>
					</div>
				);
			});
			this.setState({ jiraHistory });
		});
	};

	componentDidMount() {
		localStorage.setItem('title', 'Feature Detail');
		let stateObj = this.props.location.pathname;
		console.log('=======+Component did mount called=======');
		console.log(stateObj);
		if (localStorage.getItem('jiraURL')) {
			let jira_url = localStorage.getItem('jiraURL');
			if (jira_url.charAt(jira_url.length - 1) !== '/')
				jira_url = jira_url + '/';
			this.setState({ jiraURL: jira_url });
		}
		var featurepath = stateObj.split('/');

		console.log(featurepath);
		let featureid = '';
		if (featurepath.length > 2) {
			featureid = featurepath[2];
		}
		console.log(featureid);

		JiraAccountService.getFeaturesByFeatureidWithJiraDetails(featureid).then(
			(response) => {
				if (response != null && response.length > 0) {
					// this.setState({ feature: response[0], isFeatureAvailable: true });
					if (
						response[0].sourceId !== null ||
						response[0].sourceId !== undefined ||
						response[0].sourceId
					) {
						let dataFrom = JSON.parse(
							localStorage.getItem('integrationList')
						)[0];
						if (response[0].sourceType.toLowerCase() === 'SFDC'.toLowerCase()) {
							this.getSFDCOppDetails(response);
						} else if (
							response[0].sourceType.toLowerCase() === 'CSV'.toLowerCase()
						) {
							this.getCSVOppDetails(response);
							// this.getOppFromCSV();
						} else if (
							response[0].sourceType.toLowerCase() === 'GSHEET'.toLowerCase()
						) {
							this.getGsheetOppDetails(response);
							// this.getOppFromCSV();
						}
					}
				}
			},
			(error) => {
				console.log('error here...');
				console.log(error);
			}
		);

		//let opportunity = JSON.parse(localStorage.getItem("selectedFeature"));
		// ScoreService.getScoreBySFDCId(opportunity.id).then((response) => {
		//   if (response.status === "SUCCESS" && response.scores.length > 0) {
		//     this.setState({
		//       oppRank: response.scores[0].rank,
		//       oppScore: response.scores[0].otScore,
		//     });
		//   }
		// });
		// window.scrollTo(0, 100);
		// this.myRef.current.scrollTo(0, 0);
		JiraAccountService.getAllIssueType().then((response) => {
			if (response != null) {
				let issueTypes = [];
				for (let index = 0; index < response.length; index++) {
					issueTypes.push(response[index]);
				}
				this.setState({ jiraIssueTypeList: issueTypes });
			}
		});
	}

	componentDidUpdate() {
		// this.myRef.current.scrollTo(0, 0);
	}

	commentOnChangeHandler = (e) => {
		this.setState({ commentValue: e.target.value });
	};

	descOnChangeHandler = (e) => {
		this.setState({ descValue: e.target.value });
	};

	titleOnChangeHandler = (e) => {
		this.setState({ titleValue: e.target.value });
	};

	commentHandler = (projectID, commentBody) => {
		let commentRequest = {
			commentBody: commentBody,
			issueId: projectID,
		};
		let feature = JSON.parse(
			localStorage.getItem('selectedFeatureWithJiraDetails')
		);
		JiraAccountService.postJiraComment(commentRequest).then((response) => {
			this.toast.success('Comment Successfully posted on Jira');
			JiraAccountService.getCommentByIssueId(projectID).then(
				(commentresponse) => {
					feature.jiraDetails.comments = commentresponse;
					this.setState({ comments: commentresponse, commentValue: '' });
				}
			);
		});
	};

	updateHandler = (issueid, projectid) => {
		let updateissue = {
			description: this.state.descValue,
			issueTypeId: this.state.issueType,
			projectId: projectid,
			title: this.state.titleValue,
		};
		console.log('-------------------');
		console.log(updateissue);
		console.log('-------------------');
		JiraAccountService.UpdatIssueByIssueId(issueid, updateissue).then(
			(response) => {
				alert('Issue Update Successfully');
			}
		);
	};

	backHandler = () => {
		this.props.history.push('/submitfeature');
	};
	// render() {
	//   console.log("this is testing");
	//   console.log(this.props);
	//   return <h1>this is testing</h1>;
	// }
	listOfSubTask = () => {
		if (
			!(
				this.state.feature &&
				this.state.feature.jiraDetails.subtasks &&
				this.state.feature.jiraDetails.subtasks.length > 0
			)
		)
			return;
		let featuresubtask = this.state.feature.jiraDetails.subtasks.map(
			(item, index) => (
				<ListItem key={'subtask' + index}>
					<FormLabel component="legend">
						<b>JIRA ID : </b>
						<a
							href={`${this.state.jiraURL}browse/${item.issueKey}`}
							target="_blank"
							style={{ color: '#039be5', textDecoration: 'none' }}
						>
							{item.issueKey}
						</a>
					</FormLabel>
				</ListItem>
			)
		);
		return featuresubtask;
	};
	getIssueLinks = () => {
		if (
			!(
				this.state.feature &&
				this.state.feature.jiraDetails.issueLinks &&
				this.state.feature.jiraDetails.issueLinks.length > 0
			)
		)
			return;
		let featuresubtask = this.state.feature.jiraDetails.issueLinks.map(
			(item, index) => (
				<ListItem key={'subtask' + index}>
					<FormLabel component="legend">
						<b>JIRA ID : </b>
						<a
							href={`${this.state.jiraURL}browse/${item.targetIssueKey}`}
							target="_blank"
							style={{ color: '#039be5', textDecoration: 'none' }}
						>
							{item.targetIssueKey}
						</a>
					</FormLabel>
				</ListItem>
			)
		);
		return featuresubtask;
	};

	getIssueSubtaskLinks = () => {
		if (
			!(
				this.state.feature &&
				this.state.feature.jiraDetails.jiraFeatureDetailsSubtask &&
				this.state.feature.jiraDetails.jiraFeatureDetailsSubtask.length > 0
			)
		)
			return;
		let featuresubtask =
			this.state.feature.jiraDetails.jiraFeatureDetailsSubtask.map(
				(item, index) => (
					<ListItem key={'subtask' + index}>
						<FormLabel component="legend">
							<b>JIRA ID : </b>
							<a
								href={`${this.state.jiraURL}browse/${item.issueId}`}
								target="_blank"
								style={{ color: '#039be5', textDecoration: 'none' }}
							>
								{item.issueId}
							</a>
						</FormLabel>
					</ListItem>
				)
			);
		return featuresubtask;
	};

	render() {
		if (!this.state.isFeatureAvailable) {
			return (
				<CircularProgress
					size={40}
					style={{ marginLeft: 15, position: 'relative', top: 4 }}
				/>
			);
		}
		const { classes } = this.props;
		let feature = this.state.feature;
		let isupdate = false;
		let displayName = 'Not Assigned';

		if (feature.jiraDetails.user) {
			displayName = feature.jiraDetails.user.displayName;
		}
		// let comments = feature.jiraDetails.comments;
		if (this.state.comments) {
			this.comments = this.state.comments;
		} else {
			this.comments = feature.jiraDetails.comments;
		}
		let comments = this.comments;
		let commentsList = comments.map((item, index) => {
			return (
				<div key={index + 'frag'}>
					<ListItem key={index + ''} alignItems="flex-start">
						<ListItemAvatar>
							<Avatar
								alt="item.author"
								src={JSON.parse(item.photoURL).author.avatarUrls['48x48']}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={item.content}
							secondary={
								<React.Fragment>
									<Typography
										component="span"
										variant="body2"
										className={classes.inline}
										color="textPrimary"
									>
										{item.author}
									</Typography>
									{' — Added a Comment'}
								</React.Fragment>
							}
						/>
					</ListItem>
					<Divider
						variant="inset"
						key={item.commentId + 'div'}
						component="li"
					/>
				</div>
			);
		});

		return (
			<div ref={this.myRef}>
				<Header title="Feature " />
				<Paper>
					<Box display="flex" height={'100vh'} width="100%" flexDirection="row">
						<Box height="100%" width={'70%'}>
							<Paper>
								<List>
									<ListItem key="oppname">
										<TextField
											id="outlined-multiline-static"
											label="Opportunity Name"
											disabled={!isupdate}
											InputProps={{
												classes: {
													input: classes.multilineColor,
												},
											}}
											rows={3}
											size="small"
											fullWidth
											onChange={this.titleOnChangeHandler}
											value={this.value}
											defaultValue={this.state.oppName}
											variant="outlined"
										/>
									</ListItem>
									<ListItem key="featurename">
										<TextField
											id="outlined-multiline-static"
											label="Feature Name"
											disabled={!isupdate}
											InputProps={{
												classes: {
													input: classes.multilineColor,
												},
											}}
											rows={3}
											size="small"
											fullWidth
											onChange={this.titleOnChangeHandler}
											value={this.value}
											defaultValue={feature.jiraDetails.issueName}
											variant="outlined"
										/>
									</ListItem>
									<ListItem key="description">
										<TextField
											id="outlined-multiline-static"
											label="Description"
											multiline
											inputProps={{ color: 'red' }}
											disabled={!isupdate}
											InputProps={{
												classes: {
													input: classes.multilineColor,
												},
											}}
											rows={3}
											size="small"
											fullWidth
											style={{ color: 'red' }}
											defaultValue={feature.jiraDetails.description}
											onChange={this.descOnChangeHandler}
											value={this.value}
											variant="outlined"
										/>
									</ListItem>
									<Paper>
										<FormLabel
											component="legend"
											style={{ margin: 10 }}
											color="primary"
										>
											<b>History: </b>
										</FormLabel>

										{!isupdate && (
											<List className={classes.root}>
												{this.state.jiraHistory}
											</List>
										)}
									</Paper>
									<Paper>
										{!isupdate && (
											<FormLabel
												component="legend"
												style={{ margin: 10 }}
												color="primary"
											>
												<b>Comments: </b>
											</FormLabel>
										)}
										{!isupdate && (
											<List className={classes.root}>{commentsList}</List>
										)}

										{!isupdate && (
											<ListItem key="comment">
												<TextField
													id="outlined-multiline-static"
													label="Add Comment"
													multiline
													rows={2}
													size="small"
													name="comment"
													value={this.state.commentValue}
													onChange={this.commentOnChangeHandler}
													fullWidth
													variant="outlined"
												/>
												<Button
													variant="contained"
													style={{ margin: 10 }}
													color="primary"
													size="large"
													onClick={() =>
														this.commentHandler(
															feature.jiraDetails.issueId,
															this.state.commentValue
														)
													}
												>
													Comment
												</Button>
											</ListItem>
										)}
									</Paper>
								</List>
							</Paper>
						</Box>
						<Box height="100%" width={'30%'}>
							<Paper
								style={{
									margin: 10,
								}}
							>
								<List>
									<ListItem key="frid">
										<FormLabel component="legend">
											<b>FR ID : </b>
											<Link
												key={feature.customFeatureId}
												to={'/selectfeature/' + feature.customFeatureId}
											>
												<Typography
													className={classes.typography}
													color="primary"
													// aria-describedby={id}
													variant="contained"
												>
													{feature.customFeatureId}
												</Typography>
											</Link>
										</FormLabel>
										{/* <ListItemText primary={displayName} /> */}
									</ListItem>
									<ListItem key="jiraid">
										<FormLabel component="legend">
											<b>JIRA ID : </b>
											<a
												href={`${this.state.jiraURL}browse/${feature.jiraDetails.issueId}`}
												target="_blank"
												style={{ color: '#039be5', textDecoration: 'none' }}
											>
												{feature.jiraDetails.issueId}
											</a>
										</FormLabel>
										{/* <ListItemText primary={displayName} /> */}
									</ListItem>
									<ListItem key="assignee">
										<FormLabel component="legend">
											<b>Assignee : {displayName}</b>
										</FormLabel>
									</ListItem>
									<ListItem key="priority">
										<FormLabel component="legend">
											<b>Priority : {feature.jiraDetails.priority}</b>
										</FormLabel>
									</ListItem>
									<ListItem key="status">
										<FormLabel component="legend">
											<b>Status : {feature.jiraDetails.jiraStatus.name}</b>
										</FormLabel>
									</ListItem>
									<ListItem key="project">
										<FormLabel component="legend">
											<b>Project : {feature.jiraDetails.projectId}</b>
										</FormLabel>
									</ListItem>
									<ListItem key="otscore">
										<FormLabel component="legend">
											<b>OT Score : {feature.score + ''} </b>
										</FormLabel>
									</ListItem>
									<ListItem key="actualscore">
										<FormLabel component="legend">
											<b>Sales Score : {feature.salesScore + ''} </b>
										</FormLabel>
									</ListItem>
									<ListItem key="actualscore">
										<FormLabel component="legend">
											<b>Product Score : {feature.productScore + ''} </b>
										</FormLabel>
									</ListItem>
									<ListItem key="actualscore">
										<FormLabel component="legend">
											<b>Actual Score : {feature.actualScore + ''} </b>
										</FormLabel>
									</ListItem>
									<ListItem key="actualscore">
										<FormLabel component="legend">
											<Button
												variant="contained"
												style={{ margin: 2 }}
												color="primary"
												size="small"
												onClick={() =>
													this.getJiraHistory(feature.jiraDetails.issueId)
												}
											>
												History
											</Button>
										</FormLabel>
									</ListItem>
								</List>
							</Paper>
							<Paper
								style={{
									margin: 10,
								}}
							>
								<List>
									<ListItem key="actualscore">
										<FormLabel component="legend">
											<b>Dependencies :</b>
											<List>
												<ListItem key="actualscore">
													<FormLabel component="legend">
														<b>Subtasks :</b>
														<List>{this.listOfSubTask()}</List>
													</FormLabel>
												</ListItem>
												<ListItem key="actualscore">
													<FormLabel component="legend">
														<b>Issue Links :</b>
														<List>{this.getIssueLinks()}</List>
													</FormLabel>
												</ListItem>
												<ListItem key="actualscore">
													<FormLabel component="legend">
														<b>Issues in Epic :</b>
														<List>{this.getIssueSubtaskLinks()}</List>
													</FormLabel>
												</ListItem>
											</List>
										</FormLabel>
									</ListItem>
								</List>
							</Paper>
						</Box>
					</Box>
				</Paper>
			</div>
		);
	}
}

export default withStyles(useStyles)(SelectFeatureByIdComponent);
