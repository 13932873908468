/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:52:03
 * @modify date 2019-04-15 17:52:03
 */

import React, { Component } from 'react';
import './App.css';
import Login from '../LoginComponent/LoginComponent'
import theme from '../../settings/theme';
// import { MuiThemeProvider} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';


// please do not delete the commented code in this file...
// we will use this code when we enable multiple theme in this project.



class App extends Component {

  theamColorChangeHandler(){
      console.log('theme Color Change Handler Called.');
  }

  render() {
    
    return (
        <MuiThemeProvider theme={theme}>
        <div className="App">
        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/login_bg.jpg'})`,
    height: '100vh',   backgroundPosition: 'center',  backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
    }}>
          <Login> </Login>
        </div>
        </div>
        </MuiThemeProvider>
    );
  }
}

export default App;
