import React, { PureComponent } from 'react';
import {
	ScatterChart,
	Scatter,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Cell,
	ResponsiveContainer,
} from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import moment from 'moment';
const colors = scaleOrdinal(schemeCategory10).range();

const dummyData = [
	{ date: 1621867452071, score: 33.6, test: 'gaurav' },
	{ date: 1622012845732, score: 33.6, test: 'gaurav' },
	{ date: 1622174254281, score: 14.53, test: 'gaurav' },
	{ date: 1622555339513, score: 14.57, test: 'gaurav' },
];

const data1 = {
	status: 'SUCCESS',
	errorCode: null,
	errorMessage: null,
	featurelist: [
		{
			id: '60abbbbce754472ccf00ac12',
			status: true,
			createdBy: 'Demo Test',
			createdDate: '2021-05-24T14:44:12.071+0000',
			updatedBy: 'Demo Test',
			updatedDate: '2021-05-24T14:44:12.071+0000',
			tenantId: 'demo',
			userId: '605dc50c79da1208431b06ad',
			salesforceNotesId: '1',
			sourceType: 'GSHEET',
			sourceId: '1',
			score: 33.6,
			rank: null,
			destinationType: 'JIRA',
			destinationId: 'OT-112',
			featureStatus: 'SUBMITTED',
			projectId: 'OT',
			featureid: null,
			append: false,
			commentId: null,
			numberOfAppend: 0,
			isDelete: true,
			jiraStatus: 'To Do',
			featurePriority: 'Should Have',
			actualScore: 33.6,
			customFeatureId: 'FR-20',
			oppUserId: '605dc50c79da1208431b06ad',
			oppDetails: null,
			csvOppResponse: null,
			jiraDetails: null,
		},
		{
			id: '60adf3adfbde953aa94faba9',
			status: true,
			createdBy: 'Demo Test',
			createdDate: '2021-05-26T07:07:25.732+0000',
			updatedBy: 'Demo Test',
			updatedDate: '2021-05-26T07:07:25.732+0000',
			tenantId: 'demo',
			userId: '605dc50c79da1208431b06ad',
			salesforceNotesId: '2',
			sourceType: 'GSHEET',
			sourceId: '1',
			score: 33.6,
			rank: null,
			destinationType: 'JIRA',
			destinationId: 'OT-116',
			featureStatus: 'SUBMITTED',
			projectId: 'OT',
			featureid: null,
			append: false,
			commentId: null,
			numberOfAppend: 0,
			isDelete: true,
			jiraStatus: 'To Do',
			featurePriority: 'Should Have',
			actualScore: 33.6,
			customFeatureId: 'FR-21',
			oppUserId: '605dc50c79da1208431b06ad',
			oppDetails: null,
			csvOppResponse: null,
			jiraDetails: null,
		},
		{
			id: '60b06a2ef8eb8d4f1043b82e',
			status: true,
			createdBy: 'Demo Test',
			createdDate: '2021-05-28T03:57:34.281+0000',
			updatedBy: 'Demo Test',
			updatedDate: '2021-05-28T03:57:34.281+0000',
			tenantId: 'demo',
			userId: '605dc50c79da1208431b06ad',
			salesforceNotesId: '1-11',
			sourceType: 'GSHEET',
			sourceId: '1-1',
			score: 14.53,
			rank: null,
			destinationType: 'JIRA',
			destinationId: 'OT-118',
			featureStatus: 'SUBMITTED',
			projectId: 'OT',
			featureid: null,
			append: false,
			commentId: null,
			numberOfAppend: 0,
			isDelete: true,
			jiraStatus: 'To Do',
			featurePriority: 'Could Have',
			actualScore: 14.53,
			customFeatureId: 'FR-22',
			oppUserId: '605dc50c79da1208431b06ad',
			oppDetails: null,
			csvOppResponse: null,
			jiraDetails: null,
		},
		{
			id: '60b63acbb596fc2b98462de1',
			status: true,
			createdBy: 'Demo Test',
			createdDate: '2021-06-01T13:48:59.513+0000',
			updatedBy: 'Demo Test',
			updatedDate: '2021-06-01T13:48:59.513+0000',
			tenantId: 'demo',
			userId: '605dc50c79da1208431b06ad',
			salesforceNotesId: '1-11',
			sourceType: 'GSHEET',
			sourceId: '1-1',
			score: 14.57,
			rank: null,
			destinationType: 'JIRA',
			destinationId: 'OT-119',
			featureStatus: 'SUBMITTED',
			projectId: 'OT',
			featureid: null,
			append: false,
			commentId: null,
			numberOfAppend: 0,
			isDelete: false,
			jiraStatus: 'To Do',
			featurePriority: 'Could Have',
			actualScore: 14.57,
			customFeatureId: 'FR-23',
			oppUserId: '605dc50c79da1208431b06ad',
			oppDetails: null,
			csvOppResponse: null,
			jiraDetails: null,
		},
	],
	missingJira: null,
	totalsize: 0,
};
const finalData = data1.featurelist;

export default class BubbolChart extends PureComponent {
	render() {
		let data2 = [];
		for (let i = 0; i < finalData.length; i++) {
			let obj = {};
			let newDate = new Date(finalData[i].createdDate);
			obj['date'] = newDate.getTime();
			obj['score'] = finalData[i].score;
			obj['test'] = 'gaurav';
			data2.push(obj);
		}
		return (
			<div style={{ width: '100%', height: 300 }}>
				<p>{JSON.stringify(data2)}</p>
				<ResponsiveContainer width="100%" height="100%">
					<ScatterChart
						width={400}
						height={400}
						margin={{
							top: 20,
							right: 20,
							bottom: 20,
							left: 20,
						}}
					>
						<CartesianGrid />
						{/* <XAxis
							type="number"
							dataKey="createdDate"
							tickFormatter={(createdDate) => moment(createdDate).format('YY')}
							name="stature"
							unit="cm"
						/> */}
						<XAxis
							dataKey="date"
							tickFormatter={(unixTime) =>
								moment(unixTime).format('DD-MM-YYYY')
							}
							type="number"
							domain={['auto', 'auto']}
						/>
						<YAxis type="number" dataKey="score" name="score" />
						<Tooltip cursor={{ strokeDasharray: '3 3' }} />
						<Scatter name="A Feature" data={data2} fill="#8884d8">
							{data2.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={colors[index % colors.length]}
								/>
							))}
						</Scatter>
					</ScatterChart>
				</ResponsiveContainer>
			</div>
		);
	}
}
