/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:52:47
 * @modify date 2021-09-02 10:49:41
 * @desc Login Component.
 * @edited Ramya Jhey
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { BrowserRouter } from 'react-router-dom';
import Navbar from '../NavbarComponent/navBar';
import LoginService from '../../Services/api/Login';
import ToastServive from 'react-material-toast';
import jwt_decode from 'jwt-decode';
// import Background from '../../public/assets/images/sfdc.png';
// import Background from './assets/img/bg/login_bg.jpg';
import GoogleLogin from 'react-google-login';
import MicrosoftLogin from 'react-microsoft-login';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import JiraAccountService from '../../Services/api/JiraAccount';
import IntegrationService from '../../Services/api/Integration';
import constants from '../../settings/shared/constants';

const styles = (theme) => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
			paddingTop: 50,
		},
		backgroundImage: `url(${
			process.env.PUBLIC_URL + '/assets/images/login_bg.jpg'
		})`,
		// backgroundImage: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg"),
		height: '100vh',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	paper: {
		// marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
			theme.spacing.unit * 3
		}px`,
		background: 'rgba(250, 250, 250, 0.8)',
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	micro: {
		width: 20,
		paddingTop: 2,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
	textcenter: {
		textAlign: 'center',
		padding: 10,
		fontSize: 16,
		fontWeight: 600,
	},
	btnGooglemicro: {
		backgroundColor: '#fff',
		borderColor: '#fff',
		marginLeft: 50,
		height: 40,
	},
	btn_text_space: {
		marginLeft: 8,
		textTransform: 'none',
		color: '#039be5',
		fontWeight: 600,
	},
	bglogin: {
		backgroundImage: `url(${
			process.env.PUBLIC_URL + '/assets/images/login_bg.jpg'
		})`,
		// backgroundImage: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg"),
		height: '100vh',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	bg: {
		// backgroundImage: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg"),
		height: '100vh',
		backgroundPosition: 'center',
		backgroundColor: '#fff !important',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
});

class Login extends Component {
	state = {
		user: {},
		showMicrobtn: false,
	};

	changeHandle = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});
	// Sign in with google account
	responseGoogle = (response) => {
		// send this id_token to our backend server for access.
		let token = response.tokenObj.id_token;
		this.loginHandler(token);
		console.log(response.tokenObj.id_token);
		console.log(response);
		let decodedUser = jwt_decode(response.tokenObj.id_token);
		console.log('Name   :' + decodedUser.name);
		console.log('Email  :' + decodedUser.email);
	};

	// Sign in with Microsoft account
	authHandler = (err, data) => {
		console.log(
			JSON.stringify(data.authResponseWithAccessToken.idToken.rawIdToken)
		);
		var decoded = jwt_decode(
			data.authResponseWithAccessToken.idToken.rawIdToken
		);
		console.log('Microsoft Email  :' + decoded.preferred_username);
	};

	// DO not delete for future use.
	//   wait(ms){
	//     var start = new Date().getTime();
	//     var end = start;
	//     while(end < start + ms) {
	//       end = new Date().getTime();
	//    }
	//  }

	handleMicro = () => {
		const id = document.getElementById('prime2');
		console.log('id' + id);
	};

	loginHandler = async (authToken) => {
		let user = {};
		console.log(typeof authToken);
		if (typeof authToken === 'string') {
			console.log('in if');
			user.authToken = authToken;
		} else {
			console.log('in else');
			user = {
				email: this.state.email,
				password: this.state.password,
				tenant: this.state.tenant,
			};
		}
		console.log(user);
		//Login service integration
		await LoginService.auth(user).then((response) => {
			if (response.status === 'SUCCESS') {
				console.log(response);
				let token = response.token;
				let decodedUser = jwt_decode(token);
				//Store the objects into browser local storage
				localStorage.setItem('user', decodedUser);
				localStorage.setItem('token', response.token);
				let jiraurl = '';
				JiraAccountService.GetJiraConnector().then((response) => {
					if (response.status === 'SUCCESS') {
						jiraurl = response.jiraConnectorDO.url;
						localStorage.setItem('jiraURL', jiraurl);
					}
					IntegrationService.getList().then((response) => {
						localStorage.setItem('integrationList', JSON.stringify(response));
						IntegrationService.getListByTenant().then((response) => {
							localStorage.setItem(
								'integrationListByTenant',
								JSON.stringify(response)
							);
							this.setState({ auth: true });
						});
					});
				});
			} else {
				this.toast.error(response.errorMessage, () => {
					console.log('closed');
				});
				this.setState({ isError: true });
				this.setState({ error: response.errorMessage });
			}
		});
	};

	componentDidMount() {
		console.log('login component did mount');
		const fpPromise = FingerprintJS.load({ token: '`tgkdxWLW54NORP3KmHAf`' });

		// Get the visitor identifier when you need it.
		fpPromise
			.then((fp) => fp.get())
			.then((result) => console.log(result.visitorId));

		console.log('login component did mount end');
	}

	render() {
		if (this.state.auth) {
			localStorage.setItem('isAuth', true);
		}
		const { classes } = this.props;
		if (this.state.isError) {
		}
		console.log(constants);
		console.log(process.env);
		return (
			<div>
				<Fragment>
					{!localStorage.getItem('isAuth') && (
						<main className={classes.main}>
							<CssBaseline />
							<div>
								<Paper className={classes.paper}>
									<Avatar className={classes.avatar}>
										<LockOutlinedIcon />
									</Avatar>
									<Typography component="h1" variant="h5">
										Sign in
									</Typography>
									<form className={classes.form}>
										<FormControl margin="normal" required fullWidth>
											<InputLabel htmlFor="email">Email Address</InputLabel>
											<Input
												id="email"
												name="email"
												value={this.state.user.email}
												autoComplete="email"
												onChange={this.changeHandle}
												autoFocus
											/>
										</FormControl>
										<FormControl margin="normal" required fullWidth>
											<InputLabel htmlFor="password">Password</InputLabel>
											<Input
												name="password"
												type="password"
												id="password"
												value={this.state.user.password}
												onChange={this.changeHandle}
												autoComplete="current-password"
											/>
										</FormControl>
										<FormControl margin="normal" required fullWidth>
											<InputLabel htmlFor="tenant">Tenant</InputLabel>
											<Input
												id="tenant"
												name="tenant"
												autoComplete="tenant"
												value={this.state.user.tenant}
												onChange={this.changeHandle}
											/>
										</FormControl>
										<FormControlLabel
											control={<Checkbox value="remember" color="primary" />}
											label="Remember me"
										/>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit}
											onClick={this.loginHandler}
										>
											Sign in
										</Button>
										<div className={classes.textcenter}>
											<small>Or Sign in with</small>
										</div>
										<GoogleLogin
											clientId={constants.api.googleauth.token}
											render={(renderProps) => (
												<Button
													color="default"
													className={classes.btnGooglemicro}
													onClick={renderProps.onClick}
												>
													<span>
														<img
															className={classes.micro}
															alt="..."
															src={
																process.env.PUBLIC_URL +
																'/assets/images/google.svg'
															}
														/>
													</span>
													<span className={classes.btn_text_space}>Google</span>
												</Button>
											)}
											onSuccess={this.responseGoogle}
											onFailure={this.responseGoogle}
											cookiePolicy={'single_host_origin'}
										/>
										{this.state.showMicrobtn && (
											<MicrosoftLogin
												id="prime2"
												clientId="b341e724-e313-4c03-ae0d-78371cb1cc2d"
												authCallback={this.authHandler}
											/>
										)}
										<Button
											className={classes.btnGooglemicro}
											color="default"
											id="prime"
											onClick={this.handleMicro}
										>
											<span>
												<img
													className={classes.micro}
													alt="..."
													src={
														process.env.PUBLIC_URL + '/assets/images/micro.png'
													}
												/>
											</span>
											<span className={classes.btn_text_space}>
												Work Account
											</span>
										</Button>
									</form>
								</Paper>
							</div>
						</main>
					)}

					{localStorage.getItem('isAuth') && (
						<div className={classes.bg}>
							<BrowserRouter>
								<Navbar></Navbar>
							</BrowserRouter>
						</div>
					)}
				</Fragment>
			</div>
		);
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
