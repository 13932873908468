/**
 * @author Ramya
 * @desc Metric management service
 */
import service from '../Common/';
import constants from '../../../settings/shared/constants';
import jwt_decode from 'jwt-decode';

/**
 * This function helps to create metrics list
 * @param {*} metric
 */
function create(metrics) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(headers, metrics);
	return service({
		url: constants.api.metric.create + '/',
		method: 'POST',
		headers: headers,
		data: metrics,
	});
}

/**
 * This function helps to get all metrics by tenant
 */
function getAll(type) {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	console.log(JSON.stringify(user));
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};

	return service({
		url: constants.api.metric.get_all + '/' + user.tenant + '/type/' + type,
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function helps to get all metrics by tenant
 */
function getAllCustom() {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	let type = 'PRODUCT';
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};

	return service({
		url:
			constants.api.metric.get_all +
			'/' +
			user.tenant +
			'/type/' +
			type +
			'/' +
			'custom',
		method: 'GET',
		headers: headers,
	});
}

function getAllMetricsWithBuckets() {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	console.log(JSON.stringify(user));
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log('in header start metric1 service');
	console.log(headers);
	console.log('in header end metric service');
	return service({
		url: constants.api.metric.get_all_with_bucket + '/' + user.tenant,
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function helps to update the metric service.
 *
 * @param {*} metric
 */
function update(metric) {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	console.log(JSON.stringify(user));
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.metric.update + '/',
		method: 'PUT',
		headers: headers,
		data: metric,
	});
}

function remove(id) {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.metric.delete + '/' + id,
		method: 'DELETE',
		headers: headers,
	});
}

function deleteByMetricsId(metricsTypeId, type) {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.metric.delete + '/' + type + '/' + metricsTypeId,
		method: 'DELETE',
		headers: headers,
	});
}

//Making to publish all functions
const MetricService = {
	create,
	getAll,
	update,
	remove,
	getAllMetricsWithBuckets,
	deleteByMetricsId,
	getAllCustom,
	//, get, getAll, remove
};

export default MetricService;
