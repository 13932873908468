/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:50:20
 * @modify date 2019-04-15 17:50:20
 * @desc this is navbar component and this is just like a root of our project as of now.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationService from '../../Services/api/Notification';
import { Redirect } from 'react-router-dom';

const drawerWidth = 240;
const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	avatar: {
		margin: 2,
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	my_link: {
		textDecoration: 'none',
	},
	appBar: {
		// zIndex: theme.zIndex.drawer + 1,
		zIndex: '1100',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// bottom: 0,
	},
	appBarbottom: {
		top: 'auto',
		bottom: 0,
		maxHeight: '40px',
	},
	menuStyle: {
		fontSize: '.875rem',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
		marginLeft: '100px',
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		height: '100vh',
		overflow: 'auto',
	},
	chartContainer: {
		marginLeft: -22,
	},
	tableContainer: {
		height: 320,
	},
	h5: {
		marginBottom: theme.spacing(2),
	},
	active: {
		backgroundColor: theme.palette.action.selected,
	},
	selected: {
		backgroundColor: 'turquoise !important',
		color: 'white',
		fontWeight: 600,
	},
	text: {
		padding: theme.spacing(2, 2, 0),
	},
	paper: {
		paddingBottom: 50,
	},
	list: {
		marginBottom: theme.spacing(2),
	},
	subheader: {
		backgroundColor: theme.palette.background.paper,
	},
	menuFont: {
		paddingTop: '.75rem',
		paddingBottom: '.75rem',
		fontSize: '.65rem',
		color: 'primary',
	},
	grow: {
		flexGrow: 1,
	},
});

class Header extends React.Component {
	constructor(props) {
		super(props);
		// this.localStorageUpdated = this.localStorageUpdated.bind(this);
		this.state = {
			open: false,
			menuList: '',
			mainlistListItems: '',
			title: 'Dashboard',
			themetype: 'light',
			anchorEl: null,
			anchorNotificationEl: null,
			selected: null,
			selectedIndex: null,
			adminSubmenuItem: [],
			showAdminSubmenuItem: false,
			submenu: [],
			notifications: [],
			notificationCount: 0,
			// setAnchorEl: null
		};
	}

	updateSelected(selectedIndex) {
		this.setState({ selectedIndex: selectedIndex });
		// this.setState({selected:true});
		//   alert(selected)
	}
	toggleSubmenu(menu) {
		this.getMenu(menu);
		console.log(this.state.showAdminSubmenuItem);
		this.setState({ showAdminSubmenuItem: !this.state.showAdminSubmenuItem });
	}

	componentWillUnmount() {
		if (typeof window !== 'undefined') {
			window.removeEventListener('storage', this.localStorageUpdated);
		}
	}

	updateNotification(id, path) {
		console.log(id);
		NotificationService.update(id)
			.then((response) => {
				console.log(response);
			})
			.catch((err) => {
				console.log(err);
			});
		//this.props.history.push(path);
		//this.props.history.push('/accounts');
		window.location.href = path;
	}

	getNotification() {
		NotificationService.getNotifications()
			.then((response) => {
				if (response.status === 'SUCCESS') {
					console.log('================================');
					console.log(response.notificationDTO);
					let notifications = [];
					for (let i = 0; i < response.notificationDTO.length; i++) {
						console.log(response.notificationDTO[i]);
						notifications.push(response.notificationDTO[i]);
					}
					console.log('notificationrender called');
					console.log(notifications);
					if (notifications.length === 0) return;

					const { classes } = this.props;
					let notificationsList = notifications.map((item, index) => {
						console.log(item + ' ==');
						return (
							<MenuItem
								key={item.id}
								onClick={() => this.updateNotification(item.id, item.path)}
								className={classes.menuStyle}
							>
								{item.message}
							</MenuItem>
						);
					});
					let count = notificationsList.length;
					this.setState({
						notifications: notificationsList,
						notificationCount: count + '',
					});
					console.log('================================');
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	componentDidMount() {
		console.log('header called');
		console.log(this.props);
		this.setState({ title: localStorage.getItem('title') });
		this.getNotification();
	}

	// setTitleHandler = (name) => {
	// 	this.setState({ title: name });
	// };

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	logoutHandler = () => {
		localStorage.clear();
		sessionStorage.clear();
		window.location.reload();
		window.location.href = '/';
	};
	changePassword = () => {
		window.location.href = '/changepassword';
	};

	handleMenu = (event) => {
		console.log(event.currentTarget);
		this.setState({ anchorEl: event.currentTarget });
	};

	notificationHandle = (event) => {
		console.log(event.currentTarget);
		this.setState({ anchorNotificationEl: event.currentTarget });
	};
	notificationHandleClose = (event) => {
		this.setState({ anchorNotificationEl: null });
	};

	handleClose = (event) => {
		this.setState({ anchorEl: null });
	};

	handleSelect = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		//alert(event.target.name)
	};

	themeHandler = () => {
		if (this.state.themetype === 'dark') {
			this.setState({ themetype: 'light' });
		} else {
			this.setState({ themetype: 'dark' });
		}
	};

	render() {
		const { classes } = this.props;
		const { open } = Boolean(this.state.anchorEl);

		const theme = createMuiTheme({
			typography: {
				useNextVariants: true,
			},
			palette: {
				type: 'light',
				primary: {
					main: '#0288d1',
				},
				secondary: {
					main: '#f06292',
				},
			},
		});
		const count = 5;

		return (
			<MuiThemeProvider theme={theme}>
				<div className={classes.root}>
					<CssBaseline />
					<AppBar
						position="absolute"
						className={classNames(
							classes.appBar,
							this.state.open && classes.appBarShift
						)}
					>
						<Toolbar
							disableGutters={!this.state.open}
							className={classes.toolbar}
						>
							<Typography
								component="h1"
								variant="h6"
								color="inherit"
								noWrap
								className={classes.title}
							>
								{this.props.title}
							</Typography>
							<IconButton color="inherit">
								<Badge
									badgeContent={this.state.notificationCount}
									color="error"
									onClick={this.notificationHandle}
								>
									<NotificationsIcon />
								</Badge>
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={this.state.anchorNotificationEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(this.state.anchorNotificationEl)}
								onClose={this.notificationHandleClose}
							>
								{this.state.notifications}
								{/* <MenuItem className={classes.menuStyle}>
									Welcome, {localStorage.getItem('UserName')}
								</MenuItem> */}
							</Menu>

							<IconButton
								aria-controls="menu-appbar"
								aria-haspopup="true"
								color="inherit"
								onClick={this.handleMenu}
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={this.state.anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(this.state.anchorEl)}
								onClose={this.handleClose}
							>
								<MenuItem className={classes.menuStyle}>
									Welcome, {localStorage.getItem('UserName')}
								</MenuItem>
								<MenuItem
									className={classes.menuStyle}
									onClick={this.changePassword}
								>
									Change Password
								</MenuItem>

								<MenuItem
									className={classes.menuStyle}
									onClick={this.logoutHandler}
								>
									Log out
								</MenuItem>
							</Menu>
						</Toolbar>
					</AppBar>
				</div>
			</MuiThemeProvider>
		);
	}
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
