/**
 * @author Raghav Prabhu
 * @desc User management service
 */
import service from '../Common/';
import constants from '../../../settings/shared/constants';
import jwt_decode from 'jwt-decode';

/**
 * This function helps to create a new user by tenant
 * @param {*} user
 */
function create(user) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.user.create,
		method: 'POST',
		headers: headers,
		data: user,
	});
}

/**
 * This function helps to update user by id
 */
function update(id, user) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.user.update + '/' + id,
		method: 'PUT',
		headers: headers,
		data: user,
	});
}

/**
 * This function helps to get user by tenant by id
 */
function get(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.user.get + '/' + id,
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function helps to get all organisation/tenant
 */
function getAll() {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	console.log(JSON.stringify(user));
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.user.get_all + '/' + user.tenant,
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function helps to update user by id
 * @param {*} user id
 */
function remove(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.user.delete + '/' + id,
		method: 'DELETE',
		headers: headers,
	});
}

/**
 * This function helps to update user password
 */
function updatepassword(user) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.user.update_password,
		method: 'POST',
		headers: headers,
		data: user,
	});
}

/**
 * This function helps to active a user
 */
function activateUserById(user) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.user.activeUserById + '/' + user,
		method: 'GET',
		headers: headers,
	});
}

//Making to publish all functions
const UserService = {
	create,
	update,
	get,
	getAll,
	remove,
	updatepassword,
	activateUserById,
};

export default UserService;
