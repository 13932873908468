/**
 * @author Ramya Jhey
 * @desc DashboardComponent
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { green } from '@material-ui/core/colors';
import { Redirect } from 'react-router-dom';
import DashboardService from '../../Services/api/Dashboard';
import LoadingOverlay from 'react-loading-overlay';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import OpportunityComponent from './Opportunity';
import Header from '../CommonComponents/Header';

const stacks = [{ series: ['👶 Accepted', '🧑 Rejected', '🧓 Submitted'] }];
const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	paper: {
		height: 140,
		width: 150,
		// marginTop: '70px',
	},
	control: {
		padding: theme.spacing(2),
	},
	submittedmargin: {
		margin: theme.spacing(1),
		minWidth: 130,
		backgroundColor: '#039be5',
		justifyContent: 'center',
	},
	approvedmargin: {
		margin: theme.spacing(1),
		minWidth: 130,
		backgroundColor: green[600],
		justifyContent: 'center',
	},
	rejectedmargin: {
		margin: theme.spacing(1),
		minWidth: 130,
		backgroundColor: theme.palette.error.dark,
		justifyContent: 'center',
	},
	divider: {
		margin: theme.spacing(2, 0),
		width: '100%',
	},
	breadcrumbs: {
		paddingLeft: 35,
	},
	dollarbadge: {
		paddingRight: '10px',
	},
	chartcss: {
		height: '300px !important',
	},
	table: {
		minWidth: 650,
	},
	tabletitle: {
		color: 'black',
		fontWeight: 700,
	},
});

function createData(
	Number,
	Account,
	Submitter,
	Sales,
	Probability,
	NeededByTime,
	Priority,
	Competitor,
	Docs,
	Notes
) {
	return {
		Number,
		Account,
		Submitter,
		Sales,
		Probability,
		NeededByTime,
		Priority,
		Competitor,
		Docs,
		Notes,
	};
}
const rows = [
	createData(
		1,
		'Cisco',
		'Carl',
		'$40000',
		0.7,
		'3/1/2018',
		'Critical',
		'Axia/Breaker'
	),
	createData(2, 'Juniper', 'John', '$1000000', 1.0, '12/1/2018', 'P1', 'Axia'),
	createData(
		3,
		'Broadcom',
		'Neil',
		'$50000',
		1.0,
		'12/1/2018',
		'P2',
		'Breaking Point'
	),
];

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`,
	};
}

class DashboardComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			setValue: 0,
			setFrValue: 0,
			frValue: 0,
			targetItem: undefined,
			dashboardTable: undefined,
			featureDetails: [],
			loading: true,
			totalsize: 10,
		};
		this.submitFeature = this.submitFeature.bind(this);
		this.changeTargetItem = (targetItem) => this.setState({ targetItem });
	}

	frMenu = [
		{
			name: 'id',
			label: 'ID',
			options: {
				display: false,
			},
		},
		{
			name: 'name',
			label: 'Name',
		},
		{
			name: 'score',
			label: 'Score',
		},
		{
			name: 'rank',
			label: 'Rank',
		},
		{
			name: 'createdby',
			label: 'Created By',
		},
	];

	submitFeature() {
		this.setState({
			submitFeaturedata: 'true',
		});

		if (this.state.submitFeaturedata === 'true') {
			return <Redirect to="/submitfeature" />;
		}
	}

	allFeatureDataList = [];
	tabledata = [];

	componentDidMount() {
		console.log('component did mount called');
	}

	render() {
		const { classes } = this.props;
		const {
			setValue,
			value,
			myOTData: myOTData,
			allOTData: allOTData,
			targetItem,
			totalsize,
			setFrValue,
			frValue,
		} = this.state;
		let isRedirect = localStorage.getItem('isRedirect');
		let redirectPath = localStorage.getItem('redirectPath');
		if (isRedirect) {
			localStorage.removeItem('isRedirect');
			localStorage.removeItem('redirectPath');
			this.props.history.push(redirectPath);
		}
		console.log(totalsize);
		console.log(this.state.totalsize);

		return (
			<>
				<LoadingOverlay
					active={false}
					spinner
					className={classes.loadingOverlay}
				>
					<div className={classes.root}>
						<Header title="Dashboard" />
						<OpportunityComponent />
					</div>
				</LoadingOverlay>
			</>
		);
	}
}
DashboardComponent.propTypes = {
	classes: PropTypes.object,
};
export default withStyles(useStyles)(DashboardComponent);
