/**
 * @author Gaurav Sharma
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ToastServive from 'react-material-toast';
import Select from '@material-ui/core/Select';
import { reportType } from './ReportData';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FinalReportComponent from './finnallReport';
import Toolbar from '@material-ui/core/Toolbar';
import Header from '../CommonComponents/Header';
import TextField from '@material-ui/core/TextField';

const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: `0 ${theme.spacing.unit * 3}px`,
	},
	paper1: {
		maxHeight: '100%',
		overflow: 'auto',
		maxWidth: '100%',
		margin: '20px',
		height: '100',
		// margin: `${theme.spacing.unit}px auto`,
		padding: theme.spacing.unit * 5,
	},
	loadingOverlay: {
		position: 'absolute',
		height: '100vh',
		width: '90vw',
	},
	button: {
		margin: theme.spacing(1),
	},
});

class ReportComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			reportName: 'submitted',
			showReportName: 'Submitted',
			oppstatus: '',
			reportDateType: 'year',
			startDate: new Date().getFullYear() + '-' + '0' + 1 + '-' + '0' + 1,
			endDate: new Date().toISOString().slice(0, 10),
			testDate: new Date(2013, 0, 1),
			isShowReport: false,
			reportData: {},
			reporttitle: 'Report',
		};
	}
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	handleChange = (event) => {
		const name = event.target.name;
		console.log('==============');
		console.log(event.target.value);
		console.log('==============');
		let reportName = JSON.parse(event.target.value);
		if (reportName.oppValue) {
			let oppStatus = reportName.oppValue;
			reportName = reportName.value;
			this.setState({
				...this.state,
				showReportName: event.target.value,
				reportName: reportName,
				oppStatus: oppStatus,
			});
		} else {
			//let reportName = JSON.parse(event.target.value);
			// let oppStatus = reportName.oppValue;
			reportName = reportName.value;
			this.setState({
				...this.state,
				showReportName: event.target.value,
				reportName: reportName,
			});
		}

		console.log(this.state.reportName);
	};

	reportDateTypeChange = (event) => {
		this.setState({
			...this.state,
			reportDateType: event.target.value,
		});
	};

	submittButtonHandler = () => {
		let startDate = this.state.startDate;
		let endDate = this.state.endDate;
		let reportName = this.state.reportName;
		let oppstatus = this.state.oppStatus;
		if (startDate > endDate) {
			this.toast.error('End date must be grater then start date.');
		} else {
			this.setState({
				...this.state,
				reportData: { startDate, endDate, reportName, oppstatus },
				isShowReport: true,
			});
		}
	};

	handleDateChangeStartDate = (event) => {
		this.setState({ ...this.state, startDate: event.target.value });
	};

	handleDateChangeEndDate = (event) => {
		this.setState({ ...this.state, endDate: event.target.value });
	};

	descriptionTable() {
		let options = {
			selectableRows: false,
			print: false,
			download: false,
			viewColumns: false,
			search: false,
			count: this.state.totalsize,
			filterType: 'textField',
			filter: false,
			rowsPerPage: 10,
			// print: true,
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			serverSide: false,
		};
		return (
			<MUIDataTable
				title={
					<Typography variant="h6">
						{this.state.reporttitle}
						{this.state.loading && (
							<CircularProgress
								size={24}
								style={{ marginLeft: 15, position: 'relative', top: 4 }}
							/>
						)}
					</Typography>
				}
				data={reportType}
				columns={this.frMyMenu}
				options={options}
			/>
		);
	}

	reportDropDown() {
		console.log(this.state.reportName);
		const { classes } = this.props;
		return (
			<>
				<Toolbar>
					<Typography variant="h5" color="inherit">
						Reports
					</Typography>
				</Toolbar>
				<FormControl className={classes.formControl} fullWidth>
					<InputLabel htmlFor="age-native-simple">
						Select Report To be Displayed
					</InputLabel>
					<Select
						native
						value={this.state.showReportName}
						onChange={this.handleChange}
						inputProps={{
							name: 'reportName',
							id: 'reportName',
						}}
					>
						{reportType.map((item) => (
							<option value={JSON.stringify(item)}>{item.name}</option>
						))}
					</Select>
					<br></br>

					<FormLabel component="legend">Report Type</FormLabel>
					<RadioGroup
						aria-label="gender"
						name="gender1"
						value={this.state.reportDateType}
						onChange={this.reportDateTypeChange}
					>
						<FormControlLabel
							value="year"
							control={<Radio />}
							label="Year to date"
						/>
						<FormControlLabel
							value="selectDate"
							control={<Radio />}
							label="Selete Date"
						/>
					</RadioGroup>

					{this.state.reportDateType === 'selectDate' && (
						<form className={classes.container} noValidate>
							<TextField
								id="startdate"
								label="Start Date"
								type="date"
								defaultValue={this.state.startDate}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={this.handleDateChangeStartDate}
								style={{ margin: '10px' }}
							/>
							<TextField
								id="enddate"
								label="End Date"
								type="date"
								defaultValue={this.state.endDate}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={this.handleDateChangeEndDate}
								style={{ margin: '10px' }}
							/>
						</form>
					)}

					<Button
						color="primary"
						variant="contained"
						className={classes.button}
						onClick={this.submittButtonHandler}
					>
						Submit
					</Button>
				</FormControl>
			</>
		);
	}

	frMyMenu = [
		{
			name: 'name',
			label: 'Name',
			options: {
				filter: false,
			},
		},
		{
			name: 'desc',
			label: 'Description',
			options: {
				filter: false,
			},
		},
		{
			name: 'insights',
			label: 'Insights',
			options: {
				filter: false,
			},
		},
	];

	componentDidMount() {
		if (this.props.match && this.props.match.params.status) {
			const { status } = this.props.match.params;
			console.log(this.props.match);
			console.log(status);
			let startDate = this.state.startDate;
			let endDate = this.state.endDate;
			let reportName = status;
			if (this.props.match.params.oppstatus) {
				let { role, oppstatus } = this.props.match.params;

				this.setState({
					...this.state,
					reportData: { startDate, endDate, reportName, oppstatus, role },
					reporttitle: status + ' report',
					isShowReport: true,
				});
			} else {
				let oppstatus = this.state.oppstatus;
				console.log(this.state);
				console.log(oppstatus);
				this.setState({
					...this.state,
					reportData: {
						startDate,
						endDate,
						reportName,
						oppstatus,
						role: this.props.match.params.role,
					},
					isShowReport: true,
				});
				console.log(this.state);
			}
		}
	}

	clickOnBackButton = () => {
		console.log('back to Report Called in ReportComponent.');
		this.setState({
			isShowReport: false,
		});
	};

	render() {
		const { classes } = this.props;
		if (!this.state.isShowReport) {
			return (
				<React.Fragment>
					<Header title="Reports" />
					<LoadingOverlay
						active={this.state.loading}
						spinner
						className={classes.loadingOverlay}
					>
						<Grid item>
							<Paper className={classes.paper1}>
								{/* <Grid container spacing={8}></Grid> */}
								<Paper style={{ marginBottom: '20px', padding: '20px' }}>
									<Grid item xs={6}>
										{/* <Paper className={classes.paper}>xs=6</Paper> */}
										{this.reportDropDown()}
									</Grid>
								</Paper>
								{/* {this.descriptionTable()} */}
							</Paper>
						</Grid>
					</LoadingOverlay>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<Header title="Reports" />
					<FinalReportComponent
						clickOnBackButton={this.clickOnBackButton}
						reportData={this.state.reportData}
					/>
				</React.Fragment>
			);
		}
	}
}

export default withStyles(useStyles)(ReportComponent);
