/**
 * @author Ramya Jhey
 * @desc New Object Add Form
 */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {FormGenerator} from 'dynamic-material-ui';
import * as MUI from '@material-ui/core/';
import SaveIcon from '@material-ui/icons/Save';


const styles = theme => ( {
    root: {
      flexGrow: 1,
      padding: `0 ${theme.spacing.unit * 3}px`,
    },
    paper: {
      maxHeight: '100%',
      overflow: 'auto',
      maxWidth: "50%",
      margin: `${theme.spacing.unit}px auto`,
      marginTop: "77px",
    },
    paper1:{
      maxHeight: '100%',
      overflow: 'auto',
      maxWidth: "50%",
      margin: `${theme.spacing.unit}px auto`,
      padding: theme.spacing.unit * 2,
    },
    appBar: {
      padding:0
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
  });


class AddForm extends React.PureComponent {

    constructor(props) {
      super(props) 
      this.state = {formObj:props}
      this.updateForm({props})
      this.onSubmit = this.onSubmit.bind(this);
      this.triggerSubmit = this.triggerSubmit.bind(this);
      
    }
    
    updateForm(props) { 
      this.setState((props) => {
        return {formObj:props};  
      })
    }

    onSubmit(response, errors, formData) {
      if(errors.length==0){
        this.props.submitHandler(response)
      }
    }

    componentWillUnmount(){
      
      this.onSubmit = null
      this.state.formObj = null

    }



    triggerSubmit(data) {
      this.formRef.click();
    }
          
   render(){  
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item> 
          <Paper className={classes.paper} >
            <AppBar position='static' className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" color="inherit">
                  {this.state.formObj.formdata.title}
                </Typography>
              </Toolbar>
            </AppBar>
          </Paper>
          <Paper className={classes.paper1}> 
            {/* <Grid container spacing={30}> */}
              
            <FormGenerator
              guid="layout"
              data={this.state.formObj.formdata.field}
              displayErrors={this.state.displayErrors}
              library={MUI} 
              formRef={
                (form) => {
                  this.formRef = form;
                }
              }
              onSubmit={this.onSubmit}     
             />
            
              <Grid item align='center' xs={6} sm={12} >
                <br></br>
                <Button variant="contained" 
                  size="medium" 
                  color="primary" 
                  onClick={this.triggerSubmit}
                >
                {this.props.formdata.buttonName}
                  <SaveIcon className={classes.rightIcon}  />
                
                </Button>
              </Grid>     
            {/* </Grid> */}
          </Paper>
        </Grid> 
      </React.Fragment>
    )
  }
}

AddForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddForm);
