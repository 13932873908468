/**
 * @author Gaurav Sharma
 * @desc Bucket management service
 */
import service from '../Common/';
import constants from '../../../settings/shared/constants';

/**
 * This function helps to create a new organisation/tenant
 * @param {*} bucket management
 */
function create(data) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(headers, data);
	return service({
		url: constants.api.csv.add_csv + '/',
		method: 'POST',
		headers: headers,
		data: data,
	});
}

function getOpportunities() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.csv.get_all_opp,
		method: 'GET',
		headers: headers,
	});
}

function getOpportunitiesNotesByOppId(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.csv.get_all_notes_by_oppid + '/' + id,
		method: 'GET',
		headers: headers,
	});
}

function getOpportunitiesByOppId(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.csv.get_opp_by_id + '/' + id,
		method: 'GET',
		headers: headers,
	});
}


function getCSVFileByYType(type) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.csv.get_csv_by_type + '/' + type,
		method: 'GET',
		headers: headers,
	});
}

function deleteCSVFile(fileid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.csv.add_csv + '/' + fileid,
		method: 'DELETE',
		headers: headers,
	});
}

//Making to publish all functions
const CsvService = {
	create,
	getOpportunities,
	getOpportunitiesNotesByOppId,
	getCSVFileByYType,
	deleteCSVFile,
	getOpportunitiesByOppId,
};

export default CsvService;
