/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 18:02:06
 * @modify date 2021-10-04 16:48:43
 * @desc [description]
 */

import React from 'react';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../../settings/theme';
import AddButton from '../CommonComponents/AddButton';

export default (props) => {
	const options = {
		customToolbar: () => {
			return (
				<AddButton
					tooltip={props.tooltip}
					formdata={props.formdata}
					submitHandler={props.submitHandler}
				/>
			);
		},
		sort: true,
		filterType: 'dropdown',
		//responsive: "scroll",  // comment this line when we need to disable scroll on table
		print: false,
		filter: false,
		download: false,
		selectableRows: 'none',
		onRowClick: (rowData) => console.log(rowData),
	};

	return (
		//Source - https://www.material-ui-datatables.com/
		<MuiThemeProvider theme={theme}>
			<MUIDataTable
				title={props.title}
				data={props.data}
				columns={props.columns}
				options={options}
			/>
		</MuiThemeProvider>
	);
};
