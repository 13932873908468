/**
 * @author Gaurav  Sharma
 * @desc Submit Feature
 */
import React from 'react';

import SubmitFeatureSFDC from './SubmitFeatureSFDC';
import SubmitFeatureCSV from './SubmitFeatureCSV';
import SubmitFeatureGSHEET from './SubmitFeatureGsheet';

class SubmitFeature extends React.Component {
	render() {
		let dataFrom = JSON.parse(localStorage.getItem('integrationList'))[0];
		if (dataFrom === 'SFDC') {
			return <SubmitFeatureSFDC history={this.props.history} />;
		} else if (dataFrom === 'CSV') {
			return <SubmitFeatureCSV history={this.props.history} />;
		} else if (dataFrom === 'GSHEET') {
			// return <h1>this is testing</h1>;
			return <SubmitFeatureGSHEET history={this.props.history} />;
		}
		return <h1>this is testing</h1>;
	}
}
export default SubmitFeature;
