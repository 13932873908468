/**
 * @author Gaurav Sharma
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RankingService from '../../Services/api/Ranking';
import MetricService from '../../Services/api/Metric';
import FormHelperText from '@material-ui/core/FormHelperText';
import jwt_decode from 'jwt-decode';
import LoadingOverlay from 'react-loading-overlay';
import SFDCService from '../../Services/api/SFDC';
import { default as UUID } from 'node-uuid';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';
const useStyles = (theme) => ({
	appBar: {
		position: 'relative',
	},
	root: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
			width: 1000,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			marginTop: theme.spacing.unit * 6,
			marginBottom: theme.spacing.unit * 6,
			padding: theme.spacing.unit * 3,
		},
	},
	loadingOverlay: {
		// position: 'absolute',
		// height: '100vh',
		// width: '100vw',
	},
});

class RankingComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: [],
			metrics: {},
			loading: false,
			newfield_object: [],
			newfield_object_jira: [],
		};
	}

	initMetrics = null;
	deleteMetrics = [];

	handleChange = (name, label, type, weightage) => (event) => {
		console.log('handle change called - start');
		console.log(field_object);
		console.log(name);
		console.log(label);
		console.log(type);
		console.log(weightage);
		console.log('handle change called- end');
		console.log(event.target.checked);
		let field_object = this.state.newfield_object;

		if (event.target.checked) {
			for (let i = 0; i < field_object.length; i++) {
				if (name === field_object[i].name) {
					field_object[i].ischecked = !field_object[i].ischecked;
					break;
				}
			}
			this.setState({ newfield_object: field_object });
			this.state.metrics[name] = name;
			this.addMetricsStorage(label, name, type, weightage);
		} else {
			this.deleteMetricsStorage(name);
		}
	};

	jiraHandleChange = (name, label, type, weightage) => (event) => {
		console.log('handle change called - start');
		console.log(field_object);
		console.log(name);
		console.log(label);
		console.log(type);
		console.log(weightage);
		console.log('handle change called- end');
		console.log(event.target.checked);
		let field_object = this.state.newfield_object_jira;

		if (event.target.checked) {
			for (let i = 0; i < field_object.length; i++) {
				if (name === field_object[i].name) {
					field_object[i].ischecked = !field_object[i].ischecked;
					break;
				}
			}
			this.setState({ newfield_object_jira: field_object });
			this.state.metrics[name] = name;
			this.addMetricsStorage(label, name, type, weightage);
		} else {
			this.deleteMetricsStorage(name);
		}
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	addMetricsStorage(name, id, type, weightage) {
		if (this.deleteMetrics.indexOf(id) !== -1) {
			this.deleteMetrics.splice(this.deleteMetrics.indexOf(id), 1);
		}

		let metrics = sessionStorage.getItem('metrics_summary');
		let uuid = UUID.v4();
		let metricsJSON = {};
		if (metrics != null) {
			metricsJSON = JSON.parse(metrics);
			if (metricsJSON[id] !== undefined) {
				delete metricsJSON[id];
			}
		}
		console.log(metricsJSON);
		var jsonObject = {
			uuid: uuid,
			name: name,
			id: id,
			datatype: type,
			weightage: weightage,
		};
		metricsJSON[id] = jsonObject;
		sessionStorage.setItem('metrics_summary', JSON.stringify(metricsJSON));
		console.log('1234i98765432348765432345675433456');
		console.log(this.initMetrics);
		console.log(metricsJSON);
		console.log('1234i98765432348765432345675433456');
	}

	candeleteMetric(id) {
		let tempmetrics = JSON.parse(this.initMetrics);
		if (tempmetrics[id]) {
			console.log('already available in init metrics ');
			MetricService.deleteByMetricsId(id, 'SALES')
				.then((response) => {
					return response;
				})
				.catch((error) => {
					console.log('something went wrong1');
				});
		}
		return true;
	}

	deleteMetricsStorage(id) {
		/**
		 * check delete metrcis are availabel in init metrics.
		 */

		let tempmetrics = JSON.parse(this.initMetrics);

		if (tempmetrics !== null && tempmetrics[id]) {
			console.log('already available in init metrics');
			MetricService.deleteByMetricsId(id, 'SALES')
				.then((response) => {
					if (response) {
						let field_object = this.state.newfield_object;
						for (let i = 0; i < field_object.length; i++) {
							if (id === field_object[i].name) {
								field_object[i].ischecked = !field_object[i].ischecked;
								break;
							}
						}
						this.setState({ newfield_object: field_object });
						this.deleteMetrics.push(id);
						console.log(this.deleteMetrics);
						sessionStorage.setItem(
							'deleteMetricID',
							JSON.stringify(this.deleteMetrics)
						);
						let metrics = sessionStorage.getItem('metrics_summary');
						let metricsJSON = {};
						if (metrics != null) {
							metricsJSON = JSON.parse(metrics);
							if (metricsJSON[id] !== undefined) {
								delete metricsJSON[id];
								sessionStorage.setItem(
									'metrics_summary',
									JSON.stringify(metricsJSON)
								);
								delete this.state.metrics[id];
							}
						}
					} else {
						this.toast.error('you cannot delete this metrics.');
					}
				})
				.catch((error) => {
					console.log('something went wrong1');
				});
		} else {
			let field_object = this.state.newfield_object;
			for (let i = 0; i < field_object.length; i++) {
				if (id === field_object[i].name) {
					field_object[i].ischecked = !field_object[i].ischecked;
					break;
				}
				console.log(field_object[i].ischecked);
			}

			// delee from the locallstorage allOtSubmittedCount
			let metrics = sessionStorage.getItem('metrics_summary');
			let metricsJSON = JSON.parse(metrics);
			console.log(metricsJSON);
			delete metricsJSON[id];
			console.log(metricsJSON);
			sessionStorage.setItem('metrics_summary', JSON.stringify(metricsJSON));
			this.setState({ newfield_object: field_object });
		}
		console.log(this.state.newfield_object);
	}

	//Component mount life cycle
	componentDidMount() {
		this.setState({ loading: false });
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		console.log(user);
		sessionStorage.removeItem('metrics_summary');
		sessionStorage.removeItem('deleteMetricID');
		//sessionStorage.removeItem('deleteMetricID');
		// delete sessionStorage.deleteMetricID;
		RankingService.getAllFields(user.id).then((response) => {
			console.log(response);
			if (response.status === 'SUCCESS') {
				let metrics = [];
				MetricService.getAll('SALES').then((metricResponse) => {
					let metricsDTO = metricResponse.metricsDTOS;
					metricsDTO.forEach((element) => {
						metrics.push(element.name);
						this.addMetricsStorage(
							element.name,
							element.metricObjectId,
							element.type,
							element.weightage
						);
					});
					this.initMetrics = sessionStorage.getItem('metrics_summary');
					if (sessionStorage.getItem('metrics')) {
						metrics = metrics.concat(
							Object.keys(JSON.parse(sessionStorage.getItem('metrics')))
						);
					}
					let newfield_object = [];
					response.fieldsObjects.map((item, index) => {
						item['ischecked'] = metrics.indexOf(item.label) != -1;
						newfield_object.push(item);
					});
					console.log(newfield_object);
					this.setState({ newfield_object: newfield_object });
					console.log(this.state.newfield_object);
					this.setState({ loading: false });
				});
			} else {
				SFDCService.oauth_login_url().then((response) => {
					let url = response + '&state=/metrics';
					//alert(url)
					window.location = url;
					// window.location.href = url
					// window.location.reload();
				});
			}
		});
	}

	render() {
		const { classes } = this.props;
		let itemList = this.state.newfield_object.map((item, index) => (
			<Grid item xs={2} sm={6} key={index}>
				<FormControlLabel
					control={
						<Checkbox
							color="secondary"
							checked={item.ischecked}
							onChange={this.handleChange(
								item.name,
								item.label,
								item.type,
								item.weightage
							)}
							name={item.name}
							value="yes"
						/>
					}
					label={item.label}
					value={item.name}
				/>
				<FormHelperText>Data Type: {item.type}</FormHelperText>
			</Grid>
		));
		return (
			<LoadingOverlay
				active={this.state.loading}
				spinner
				className={classes.loadingOverlay}
			>
				<React.Fragment>
					<CssBaseline />
					<Grid container spacing={16}>
						{itemList}
					</Grid>
				</React.Fragment>
			</LoadingOverlay>
		);
	}
}
export default withStyles(useStyles)(RankingComponent);
