/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:59:37
 * @modify date 2019-04-15 17:59:37
 * @desc AddButton
 */


import React, { Component } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddForm from './AddTemplate';



const styles = theme => ({
    appBar: {
      position: 'fixed',
    },
    flex: {
      flex: 1,
    },
  });
  
  function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

class AddButton extends Component {
    state = {
        open: false,
    };
    
    handleClickOpen = () => {
        console.log("test");
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    
    

    render(){
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Tooltip title={this.props.tooltip}>
                    <IconButton 
                        onClick={ () => {
                            this.handleClickOpen()
                        }
                    }
                    >
                    <AddIcon color='secondary'/>
                    </IconButton>
                </Tooltip>
                
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar} position="absolute">
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    
                    <AddForm formname={this.props.formdata.title} formdata={this.props.formdata} submitHandler={this.props.submitHandler} />
                    
                </Dialog>
            </React.Fragment>
        )
    }
}

AddButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddButton);