/**
 * @author Gaurav Sharma
 * @desc Opportunity settings module
 */
import service from '../Common';
import constants from '../../../settings/shared/constants';
import jwt_decode from 'jwt-decode';

function getNotifications() {
	let jwtToken = localStorage.getItem('token');
	let user = jwt_decode(jwtToken);
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.notification.get_notification,
		method: 'GET',
		headers: headers,
	});
}

function update(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.notification.update_notification + '/' + id,
		method: 'PUT',
		headers: headers,
	});
}

//Making to publish all functions
const NotificationService = {
	getNotifications,
	update,
};

export default NotificationService;
