/**
 * @author: Ramya Jhey
 */

import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

export const accountMenu = [
	{
		name: 'id',
		options: {
			filter: false,
			display: 'excluded',
			print: false,
		},
	},
	{
		name: 'identifier',
		options: {
			filter: false,
			display: 'excluded',
			print: false,
		},
	},
	{
		name: 'Name',
		options: {
			filter: true,
		},
	},
	{
		name: 'Email',
		options: {
			filter: true,
		},
	},
	{
		name: 'Office Address',
		options: {
			filter: true,
		},
	},
	{
		name: 'City',
		options: {
			filter: true,
		},
	},
	{
		name: 'Description',
		options: {
			filter: true,
		},
	},
	{
		name: 'URL',
		options: {
			filter: true,
		},
	},
	{
		name: 'Edit',
		options: {
			filter: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<div>
						<Link
							to={{
								pathname: '/accountsedit',
								state: { data: tableMeta.rowData },
							}}
						>
							<IconButton color="primary" aria-label="Edit">
								<Icon>edit_icon</Icon>
							</IconButton>
						</Link>
					</div>
				);
			},
		},
	},
];

export const accountform = {
	title: 'Add Organisation',
	buttonName: 'Create Account',
	field: [
		{
			id: 'emailAddress',
			type: 'textfield',
			visible: true,
			props: {
				id: 'emailAddress',
				label: 'Email Address',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'email',
						message: 'Please enter valid email.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'organisationName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'organisationName',
				label: 'Organisation Name',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the organisation Name.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'officeAddress',
			type: 'textfield',
			visible: true,
			props: {
				id: 'officeAddress',
				label: 'Office Address',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 10,
				},
				lg: {
					col: 10,
				},
			},
		},
		{
			id: 'city',
			type: 'textfield',
			visible: true,
			props: {
				id: 'city',
				label: 'City',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				// validation: [
				// 	{
				// 		rule: 'mandatory',
				// 		message: 'Please enter the City.',
				// 	},
				// ],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'url',
			type: 'textfield',
			visible: true,
			props: {
				id: 'url',
				label: 'URL',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'firstName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'firstName',
				label: 'First Name',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the Your Name.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'lastName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'lastName',
				label: 'Last Name',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the Last Name.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'password',
			type: 'textfield',
			visible: true,
			props: {
				id: 'password',
				label: 'Password',
				type: 'password',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the Last Name.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'passwordConfirm',
			type: 'textfield',
			visible: true,
			props: {
				id: 'passwordConfirm',
				label: 'Password Confirmation',
				type: 'password',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Confirm password.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'phoneNumber',
			type: 'textfield',
			visible: true,
			props: {
				id: 'phoneNumber',
				label: 'Phone Number',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'description',
			type: 'textfield',
			visible: true,
			props: {
				id: 'description',
				label: 'Description',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 15,
				},
				lg: {
					col: 15,
				},
			},
		},
	],
};

export const accountedit = {
	title: 'Edit Organisation',
	buttonName: 'Update',
	field: [
		{
			id: 'organisationName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'organisationName',
				disabled: true,
				label: 'Organisation Name',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the organisation Name.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 10,
				},
				lg: {
					col: 10,
				},
			},
		},
		{
			id: 'officeAddress',
			type: 'textfield',
			visible: true,
			props: {
				id: 'officeAddress',
				label: 'Office Address',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				// validation: [
				// 	{
				// 		rule: 'mandatory',
				// 		message: 'Please enter the Office Address.',
				// 	},
				// ],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 10,
				},
				lg: {
					col: 10,
				},
			},
		},
		{
			id: 'city',
			type: 'textfield',
			visible: true,
			props: {
				id: 'city',
				label: 'City',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the City.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'url',
			type: 'textfield',
			visible: true,
			props: {
				id: 'url',
				label: 'URL',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'phoneNumber',
			type: 'textfield',
			visible: true,
			props: {
				id: 'phoneNumber',
				label: 'Phone Number',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'description',
			type: 'textfield',
			visible: true,
			props: {
				id: 'description',
				label: 'Description',
				fullWidth: true,
				value: '',
				errorText: '',
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 15,
				},
				lg: {
					col: 15,
				},
			},
		},
	],
};
