import service from '../Common/';
import constants from '../../../settings/shared/constants';

function oauth() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.gsheet.oauth,
		method: 'GET',
		headers: headers,
	});
}

function callback(code) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.gsheet.callback + '?code=' + code,
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function helps to create a new organisation/tenant
 * @param {*} bucket management
 */
function create(data) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(headers, data);
	return service({
		url: constants.api.gsheet.create,
		method: 'POST',
		headers: headers,
		data: data,
	});
}

function check(data) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	console.log(headers, data);
	return service({
		url: constants.api.gsheet.create + 'check',
		method: 'POST',
		headers: headers,
		data: data,
	});
}

function getOpportunities() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.gsheet.getOpp,
		method: 'GET',
		headers: headers,
	});
}

function getOpportunitiesNotesByOppId(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.gsheet.get_all_notes_by_oppid + '/' + id,
		method: 'GET',
		headers: headers,
	});
}

function getOpportunitiesByOppId(id) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.gsheet.get_opp_by_id + '/' + id,
		method: 'GET',
		headers: headers,
	});
}

function getGsheetFileByYType(type) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		// url: constants.api.opportunity.get_opportunity + "/" + user.tenant,
		url: constants.api.gsheet.get_file_by_type + '/' + type,
		method: 'GET',
		headers: headers,
	});
}
function deleteGsheetFile(fileid) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.gsheet.delete_details + '/' + fileid,
		method: 'DELETE',
		headers: headers,
	});
}

//Making to publish all functions
const GsheetService = {
	oauth,
	callback,
	create,
	getOpportunities,
	getOpportunitiesByOppId,
	getOpportunitiesNotesByOppId,
	getGsheetFileByYType,
	deleteGsheetFile,
	check,
};

export default GsheetService;
