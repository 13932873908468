import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/AppComponent/App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';



// if we want multiple baseURL then we need to go for axios instance. :-) (just reminder.)

axios.defaults.baseURL='https://theitstack.com/api/'
//axios.defaults.baseURL='http://172.20.10.3:8090/otservice'

axios.defaults.headers.post['Content-Type']='application/json' 

axios.interceptors.request.use(request=>{
    request.headers = {
        'Authorization': localStorage.getItem('token'),
    }
    return request;
}, error => {
    return Promise.reject(error);
})

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
