/**
 * @author Raghav Prabhu
 * @desc User Management
 */

import React, { Component } from 'react';
import { OTTable } from '..';
import UserService from '../../Services/api/User';
import { roleTypes, powerUserRoleTypes } from '../DataSets/MenuDataSet';
import jwt_decode from 'jwt-decode';
import ToastServive from 'react-material-toast';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Checkbox } from '@material-ui/core';
import Header from '../CommonComponents/Header';
import Swal from 'sweetalert2';
import CustomToolbar from '../RankingComponent/MetricToolBar';

const userform = {
	title: 'Add User',
	buttonName: 'Create User',
	field: [
		{
			id: 'emailAddress',
			type: 'textfield',
			visible: true,
			props: {
				id: 'emailAddress',
				label: 'Email Address',
				fullWidth: true,
				value: '',
				errortext: '',
				type: 'email',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter email address.',
					},
					{
						rule: 'email',
						message: 'Please enter valid email address.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'role',
			type: 'selectfield',
			visible: true,
			props: {
				id: 'Role',
				label: 'Role',
				fullWidth: true,
				selected: 'ADMIN',
				errortext: '',
				style: {
					display: 'table',
				},
			},
			options: [
				{
					value: null,
					primaryText: '',
				},
			],
			rules: {
				validation: [
					{
						rule: 'equals',
						value: 'ADMIN',
						message: 'Please select role.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'firstName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'firstName',
				label: 'First Name',
				fullWidth: true,
				value: '',
				errortext: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the Your Name.',
					},
					{
						rule: 'alpha',
						message: 'Please enter character only',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'lastName',
			type: 'textfield',
			visible: true,
			props: {
				id: 'lastName',
				label: 'Last Name',
				fullWidth: true,
				value: '',
				errortext: '',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the Your Name.',
					},
					{
						rule: 'alpha',
						message: 'Please enter character only',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'password',
			type: 'textfield',
			visible: true,
			props: {
				id: 'password',
				label: 'Password',
				fullWidth: true,
				value: '',
				errortext: '',
				type: 'password',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter valid password',
					},
					{
						rule: 'length',
						value: 8,
						message: 'password length must have 8 character',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'passwordConfirm',
			type: 'textfield',
			visible: true,
			props: {
				id: 'passwordConfirm',
				label: 'Password Confirmation',
				fullWidth: true,
				value: '',
				errortext: '',
				type: 'password',
			},
			rules: {
				validation: [
					{
						rule: 'mandatory',
						message: 'Please enter the valid confirmation password.',
					},
					{
						rule: 'length',
						value: 8,
						message: 'Confirm password length must have 8 character',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'phoneNumber',
			type: 'textfield',
			visible: true,
			props: {
				id: 'phoneNumber',
				label: 'Phone Number',
				fullWidth: true,
				value: '',
				errortext: '',
				type: 'number',
			},
			rules: {
				validation: [
					{
						rule: 'numeric',
						message: 'Please enter numbers only',
					},
					{
						rule: 'length',
						value: 10,
						message: 'Phone Number must have 10 number.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
		{
			id: 'homePhone',
			type: 'textfield',
			visible: true,
			props: {
				id: 'homePhone',
				label: 'Home Phone Number',
				fullWidth: true,
				value: '',
				errortext: '',
				type: 'number',
			},
			rules: {
				validation: [
					{
						rule: 'numeric',
						message: 'Please enter numbers only',
					},
					{
						rule: 'length',
						value: 10,
						message: 'Phone Number must have 10 number.',
					},
				],
			},
			layout: {
				row: 1,
				xs: {
					col: 12,
				},
				sm: {
					col: 12,
				},
				md: {
					col: 5,
				},
				lg: {
					col: 5,
				},
			},
		},
	],
};

class UserComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			myValue: false,
		};
	}

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	userMenu = [
		{
			name: 'id',
			options: {
				filter: false,
				display: 'excluded',
				print: false,
			},
		},
		{
			name: 'firstName',
			label: 'First Name',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'lastName',
			label: 'Last Name',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'email',
			label: 'Email',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'organisation',
			label: 'Company',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'role',
			label: 'Role',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'active',
			label: 'Active',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					console.log(value);
					console.log(tableMeta);
					console.log(updateValue);
					if (value)
						return (
							<Checkbox
								defaultChecked
								disabled
								color="primary"
								onChange={() =>
									this.toogleUserActiveStatus(value, tableMeta, updateValue)
								}
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						);
					else
						return (
							<Checkbox
								color="primary"
								onChange={() =>
									this.toogleUserActiveStatus(value, tableMeta, updateValue)
								}
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
						);
				},
			},
		},
		{
			name: 'homePhone',
			options: {
				filter: false,
				display: 'excluded',
				print: false,
			},
		},
		{
			name: 'mobileNumber',
			options: {
				filter: false,
				display: 'excluded',
				print: false,
			},
		},

		{
			name: 'Edit',
			options: {
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							<Link
								to={{
									pathname: '/useredit',
									state: { data: tableMeta.rowData },
								}}
							>
								<IconButton color="primary" aria-label="Edit">
									<Icon>edit_icon</Icon>
								</IconButton>
							</Link>
						</div>
					);
				},
			},
		},
	];

	componentDidMount() {
		let token = localStorage.getItem('token');
		let decodedUser = jwt_decode(token);
		let role = decodedUser.roles[0];
		if (role === 'user') return <CustomToolbar path="/dashboard" />;
		localStorage.setItem('title', 'User');
		let roles = [];
		userform.field.forEach((element) => {
			if (element.id === 'role') {
				let jwtToken = localStorage.getItem('token');
				let user = jwt_decode(jwtToken);
				let roleList = undefined;
				if (user.roles[0] === 'SUPER_ADMIN') {
					roleList = powerUserRoleTypes;
				} else {
					roleList = roleTypes;
				}
				for (var key in roleList) {
					let type = {};
					if (roleList.hasOwnProperty(key)) {
						type = {
							value: key,
							primaryText: roleList[key],
						};
						roles.push(type);
					}
				}
				element.options = roles;
			}
			// this.myValue = true;
		});
		this.getAllUser();
	}

	getAllUser = () => {
		UserService.getAll().then((response) => {
			if (response.status === 'SUCCESS') {
				let data = [];
				let user = [];
				let users = response.userDTOS;
				console.log(users);
				users.forEach((element) => {
					user = [];
					user.push(element.id);
					user.push(element.firstName);
					user.push(element.lastName);
					user.push(element.email);
					user.push(element.organisation);
					user.push(element.role);
					user.push(element.active);
					user.push(element.homePhone);
					user.push(element.mobileNumber);
					data.push(user);
				});
				this.setState({ users: data });
				console.log(data);
			} else {
			}
		});
	};

	toogleUserActiveStatus = (value, tableMeta, updateValue) => {
		console.log(value);
		console.log('user id is ' + tableMeta.rowData[0]);
		console.log('user active method called ');
		/**
		 * if value is false means user is not a active user we need to active that user
		 *
		 * if value is true means user is active and admin want to deactive that user.
		 */
		if (value) {
			// deactive user
			console.log('deactive user');
		} else {
			Swal.fire({
				title: 'Are you sure ?',
				text: '',
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'ok',
			}).then((result) => {
				if (result.isConfirmed) {
					UserService.activateUserById(tableMeta.rowData[0]).then(
						(response) => {
							console.log(response);
							if (response === true) {
								this.getAllUser();
								this.toast.success('User Activated ');
							}
						}
					);
					//active user
				}
			});
		}
	};

	myuserHandler = () => {
		console.log('myuserHandlerCalled');
	};

	submitHandler = (data) => {
		console.log(data);
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);

		let request = {
			email: data.layout.emailAddress,
			firstName: data.layout.firstName,
			lastName: data.layout.lastName,
			password: data.layout.password,
			role: data.layout.role,
			tenant: user.tenant,
			organisation: user.organisation,
			homePhone: data.layout.homePhone,
			mobileNumber: data.layout.mobileNumber,
		};

		UserService.create(request).then((response) => {
			if (response.status === 'SUCCESS') {
				this.toast.success('User Created successfully...', () => {
					console.log(response);
				});
				this.props.history.push('/users');
				let users = this.state.users;
				users.push(response.userDTOS[0]);
				console.log(users);
				this.setState({ users: users });
				localStorage.setItem('isRedirect', true);
				localStorage.setItem('redirectPath', '/users');
				this.props.history.push('/dashboard');
				//window.location.reload();
			} else {
				this.toast.error(response.errorMessage, () => {
					console.log(response);
				});
			}
		});
	};

	render() {
		return (
			<>
				<Header title="Users" />
				<OTTable
					formdata={userform}
					title="User List"
					data={this.state.users}
					columns={this.userMenu}
					options={this.options}
					tooltip="Add User"
					handleClick={this.myuserHandler}
					submitHandler={this.submitHandler}
				></OTTable>
			</>
		);
	}
}

export default UserComponent;
