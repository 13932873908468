import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import JiraAccountService from '../../Services/api/JiraAccount';
import Swal from 'sweetalert2';
import FeatureService from '../../Services/api/Feature';
import PopupTable from '../DashboardComponent/Popuptable';
import { Route, Link } from 'react-router-dom';
import ToastServive from 'react-material-toast';
import { Breadcrumbs } from '@material-ui/core';

const useStyles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	typography: {
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
});

class FinalReportComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			totalsize: 10,
			tableData: undefined,
			loading: false,
			todayDate: '',
			reporttitle: 'Report',
		};
	}
	tabledata = [];

	/*
	getDataByUserWithDate(pageindex, pagesize, filterList, rowsPerPage) {
		let { status } = this.props.match.params;
		console.log(this.props.match.params);
		if (status === 'todo') status = 'To Do';
		if (status === 'done') status = 'Done';

		console.log('getDataByUserWithDate called');
		if (
			filterList === null ||
			filterList === undefined ||
			filterList[18] === '' ||
			filterList[18].length <= 0
		) {
			this.toast.error('please Select Start Date End Date');
		}
		if (filterList[18].length > 0) {
			let startDate = new Date(filterList[18][0]);
			let endDate =
				filterList[18][1] === '' || filterList[18][1] === undefined
					? new Date(filterList[18][1])
					: new Date(this.state.todayDate);
			if (startDate > endDate) {
				this.toast.error('End date must be grater then start date.');
			}
		}
		this.getDataByUser(pageindex, pagesize, status);
	}
	*/

	getDataByUser = (
		pageindex,
		pagesize,
		status,
		role,
		oppstatus,
		startDate,
		endDate
	) => {
		this.setState({ loading: true });
		this.tabledata = [];

		let data = {
			endDate: endDate,
			jiraStatus: status,
			startDate: startDate,
			pageNum: pageindex,
			oppStatus: oppstatus,
			size: pagesize,
		};
		console.log(data);
		JiraAccountService.getFeatureWithJiraOppDetailsByJirastatus1(
			data,
			role
		).then((response) => {
			if (response.status === 'SUCCESS') {
				let itemList = response.featurelist;
				this.allFeatureDataList = response.featurelist;
				let dataFrom = JSON.parse(localStorage.getItem('integrationList'))[0];

				this.setState({ totalsize: response.totalsize });
				if (dataFrom === 'SFDC') {
					itemList.forEach((item) => {
						let opp = {
							id: item.id,
							frid: item.customFeatureId,
							jiraid: item.destinationId,
							name: item.jiraDetails.issueName,
							score: parseFloat(item.score).toFixed(2), //item.score,
							rank: item.rank,
							createdby: item.createdBy,
							numberOfAppend: item.numberOfAppend,
							jiraStatus: item.jiraDetails.jiraStatus.name,
							oppName: item.oppDetails.name,
							oppAccountName: item.oppDetails.parameters.account.Name,
							oppProbability: item.oppDetails.parameters.probability,
							oppPriority: item.oppDetails.parameters.priority__c,
							oppStatus: item.oppDetails.parameters.stageName,
							oppProductScore: parseFloat(item.productScore).toFixed(2),
							oppSalesScore: parseFloat(item.salesScore).toFixed(2),
							oppActualScore: parseFloat(item.actualScore).toFixed(2),
							oppAmount: item.oppDetails.parameters.amount,
							oppCloseDate: item.oppDetails.parameters.closeDate,
							otFrScore: parseFloat(item.score).toFixed(2),
						};
						this.tabledata.push(opp);
					});
				} else {
					// return <h1>this is testing</h1>;
					itemList.forEach((item) => {
						let opp = {
							id: item.id,
							frid: item.customFeatureId,
							jiraid: item.destinationId,
							name: item.jiraDetails.issueName,
							score: parseFloat(item.score).toFixed(2),
							rank: item.rank,
							createdby: item.createdBy,
							numberOfAppend: item.numberOfAppend,
							jiraStatus: item.jiraDetails.jiraStatus.name,
							oppName: item.csvOppResponse.opportunityName,
							oppAccountName: item.csvOppResponse.accountName,
							oppProbability: item.csvOppResponse.probability,
							oppPriority: item.csvOppResponse.priority,
							oppStatus: null,
							oppProductScore: parseFloat(item.productScore).toFixed(2),
							oppSalesScore: parseFloat(item.salesScore).toFixed(2),
							oppActualScore: parseFloat(item.actualScore).toFixed(2),
							oppAmount: item.csvOppResponse.amount,
							oppCloseDate: item.csvOppResponse.closeDate,
							otFrScore: parseFloat(item.score).toFixed(2),
						};
						this.tabledata.push(opp);
					});
				}

				//"missingJira":["OTPROD-225"]
				if (response.missingJira && response.missingJira.length > 0) {
					Swal.fire({
						title: 'Missing Jira!',
						text:
							'Jira Id ' +
							response.missingJira +
							' seems to be deleted. Corresponding FR will be delinked.',
						icon: 'warning',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					}).then((result) => {
						if (result.isConfirmed) {
							console.log(response.missingJira);
							FeatureService.deleteFeaturesByJiraId(response.missingJira).then(
								(deleteResponse) => {
									if (deleteResponse === true) {
										Swal.fire(
											'Delinked!',
											// if we can grep the FR list. show FR here.
											'Your FR has been delinked.',
											'success'
										);
									}
								}
							);
						}
					});
				}

				this.setState({ tableData: this.tabledata });
				this.setState({ totalsize: response.totalsize });
				this.setState({ reporttitle: status.toUpperCase() });
				this.setState({ loading: false });
			}
		});
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	getNumberOfAppendAsHyperLink(value, tableMeta, updateValue) {
		const open = Boolean(this.state.anchorEl);
		let { rowData } = tableMeta;
		const id = open ? 'simple-popover' : undefined;
		return <PopupTable numberofappend={value} jiraid={rowData[2]} />;
	}

	componentDidMount() {
		console.log('componeent did mount called');
		console.log('============================');
		console.log(this.props);
		console.log('============================');
		//{startDate: "2020-01-01", endDate: "2020-12-23", reportName: ""}
		if (this.props.reportData) {
			console.log('final report component');
			console.log(this.props.reportData);
			console.log(this.props.reportData.oppstatus);
			console.log('final report component End');
			this.getDataByUser(
				0,
				10,
				this.props.reportData.reportName,
				this.props.reportData.role,
				this.props.reportData.oppstatus,
				this.props.reportData.startDate,
				this.props.reportData.endDate
			);
			this.setState({
				reporttitle: this.props.reportData.reportName.toUpperCase(),
			});
		}

		if (this.props.match) {
			const { status } = this.props.match.params;
			console.log(this.props.match.params);
			if (status === 'todo') this.getDataByUser(0, 10, 'To Do');
			if (status === 'done') this.getDataByUser(0, 10, 'Done');
			let todayDate = new Date().toISOString().slice(0, 10);
			this.setState({ todayDate });
		}
	}

	render() {
		let options = {
			selectableRows: false,
			print: false,
			download: true,

			// viewColumns: false,
			search: true,
			count: this.state.totalsize,
			filterType: 'textField',
			//filter: false,
			rowsPerPage: 10,
			// print: true,
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			serverSide: false,
			downloadOptions: {
				filename: 'report.csv',
				separator: ',',
			},
			// downloadOptions: () => {
			// 	if (this.state.tableData && this.state.tableData.length > 0) {
			// 		return { filename: 'report.csv', separator: ',' };
			// 	}
			// 	return false;
			// },
			onTableChange: (action, tableState) => {
				console.log(action, tableState);
				switch (action) {
					case 'filterChange':
						// console.log(tableState);
						this.getDataByUser(
							tableState.page,
							tableState.rowsPerPage,
							this.props.reportData.reportName,
							this.props.reportData.startDate,
							this.props.reportData.endDate
						);

						break;
					case 'changePage':
						//this.changePage(tableState.page, tableState.sortOrder);
						this.getDataByUser(
							tableState.page,
							tableState.rowsPerPage,
							this.props.reportData.reportName,
							this.props.reportData.startDate,
							this.props.reportData.endDate
						);
						break;
					case 'changeRowsPerPage':
						this.getDataByUser(
							tableState.page,
							tableState.rowsPerPage,
							this.props.reportData.reportName,
							this.props.reportData.startDate,
							this.props.reportData.endDate
						);
						break;
				}
			},
		};

		return (
			<MUIDataTable
				title={
					<Typography variant="h6">
						<div>
							<Breadcrumbs aria-label="breadcrumb">
								<Link color="inherit" onClick={this.props.clickOnBackButton}>
									Report
								</Link>
								<Typography
									color="textPrimary"
									//href="/getting-started/installation/"
									// onClick={this.props.clickOnBackButton}
								>
									{this.state.reporttitle}
								</Typography>
								{/* <Typography color="textPrimary">Breadcrumb</Typography> */}

								{this.state.loading && (
									<CircularProgress
										size={24}
										style={{ marginLeft: 15, position: 'relative', top: 4 }}
									/>
								)}
							</Breadcrumbs>
							{/* <p onClick={this.props.clickOnBackButton}>
								{this.state.reporttitle}
							</p> */}
						</div>
					</Typography>
				}
				data={this.state.tableData}
				columns={this.frMyMenu}
				options={options}
			/>
		);
	}

	getJiraIdAsHyperLink(value, tableMeta, updateValue) {
		const { classes } = this.props;
		let jira_url = localStorage.getItem('jiraURL');
		if (jira_url.charAt(jira_url.length - 1) !== '/') jira_url = jira_url + '/';
		let jiraurl = `${jira_url}browse/${value}`;
		return (
			<a
				href={jiraurl}
				target="_blank"
				style={{ color: '#039be5', textDecoration: 'none' }}
			>
				{value}
			</a>
		);
	}

	getFeatureIdAsHyperLink(value, tableMeta, updateValue) {
		const { classes } = this.props;
		return (
			<Link key={value} to={'/selectfeature/' + value}>
				<Typography
					className={classes.typography}
					color="primary"
					variant="contained"
				>
					{value}
				</Typography>
			</Link>
		);
	}

	getJiraStatusWithCSS(value, tableMeta, updateValue) {
		const { classes } = this.props;

		if (value && value.toUpperCase() === 'to do'.toUpperCase())
			return (
				<p
					style={{
						display: 'inline',
						backgroundColor: '#9F6000',
						borderRadius: '3',
						color: '#ffffff',
					}}
				>
					{value}
				</p>
			);
		if (value && value.toUpperCase() === 'done'.toUpperCase())
			return (
				<p
					style={{
						display: 'inline',
						backgroundColor: '#4F8A10',

						color: '#ffffff',
					}}
				>
					{value}
				</p>
			);
		if (value && value.toUpperCase() === 'in progress'.toUpperCase())
			return (
				<p
					style={{
						display: 'inline',
						backgroundColor: '#00529B',
						color: '#ffffff',
					}}
				>
					{value}
				</p>
			);
		return <p>{value}</p>;
	}

	frMyMenu = [
		{
			name: 'id',
			label: 'ID',
			options: {
				display: false,
				filter: false,
			},
		},
		{
			name: 'frid',
			label: 'FR ID',
			options: {
				display: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getFeatureIdAsHyperLink(value, tableMeta, updateValue),
			},
		},
		{
			name: 'jiraid',
			label: 'Jira ID',
			options: {
				display: true,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getJiraIdAsHyperLink(value, tableMeta, updateValue),
			},
		},
		{
			name: 'numberOfAppend',
			label: 'Appends',
			options: {
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getNumberOfAppendAsHyperLink(value, tableMeta, updateValue),
			},
		},
		{
			name: 'jiraStatus',
			label: 'Jira Status',
			options: {
				display: true,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) =>
					this.getJiraStatusWithCSS(value, tableMeta, updateValue),
			},
		},

		{
			label: 'Opportunity Name',
			name: 'oppName',
			options: {
				filter: true,
			},
		},

		{
			name: 'name',
			label: 'FR Name',
			options: {
				display: true,
				filter: true,
			},
		},
		{
			label: 'Account Name',
			name: 'oppAccountName',
			options: {
				filter: true,
			},
		},
		{
			name: 'createdby',
			label: 'Created By',
			//,
			options: {
				display: true,
				filter: true,
				filterType: 'textField',
			},
		},

		{
			name: 'oppAmount',
			label: 'Amount',
			options: {
				filter: true,
			},
		},

		{
			label: 'Probability',
			name: 'oppProbability',
			options: {
				filter: true,
			},
		},

		{
			name: 'oppCloseDate',
			label: 'CloseDate',
			options: {
				filter: true,
			},
		},

		{
			label: 'Priority',
			name: 'oppPriority',
			options: {
				filter: true,
			},
		},

		// {
		// 	label: 'Status',
		// 	name: 'oppStatus',
		// 	options: {
		// 		filter: true,
		// 	},
		// },
		{
			name: 'otFrScore',
			label: 'OT Score',
			options: {
				filter: true,
			},
		},
		{
			label: 'Product Score',
			name: 'oppProductScore',
			options: {
				filter: true,
			},
		},

		{
			label: 'Sales Score',
			name: 'oppSalesScore',
			options: {
				filter: true,
			},
		},
		{
			label: 'Actual Score',
			name: 'oppActualScore',
			options: {
				filter: true,
			},
		},

		// {
		// 	name: 'oppStatus',
		// 	label: 'Opp Status',
		// 	options: {
		// 		filter: true,
		// 	},
		// },
	];
}
export default withStyles(useStyles)(FinalReportComponent);
