import service from '../Common';
import constants from '../../../settings/shared/constants';
import jwt_decode from 'jwt-decode';

function getList() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.integration.get_list,
		method: 'GET',
		headers: headers,
	});
}

function getListByTenant() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	let user = jwt_decode(jwtToken);
	return service({
		url: constants.api.integration.get_list + '/' + user.tenant,
		method: 'GET',
		headers: headers,
	});
}

//Making to publish all functions
const IntegrationService = {
	getList,
	getListByTenant,
};

export default IntegrationService;
