/**
 * @author Gaurav Sharma
 * @create date 2019-04-15 17:57:18
 * @modify date 2019-04-15 17:57:18
 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import JiraAccountService from '../../Services/api/JiraAccount';
import ScoreService from '../../Services/api/Score';
import Select from '@material-ui/core/Select';
import { Route, Link } from 'react-router-dom';
import ToastServive from 'react-material-toast';
import Header from '../CommonComponents/Header';

const useStyles = (theme) => ({
	multilineColor: {
		color: 'black',
	},
	paper: {
		height: '100%',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			padding: theme.spacing(3),
		},
	},
});

class SelectedFeatureComponent extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.state = {
			jiraProjectList: [],
			jiraIssueTypeList: [],
			oppRank: '',
			oppScore: '',
			jiraURL: '',
			issueType: '',
		};
	}

	handleChange = (name) => (event) => {
		if (name === 'projects') {
			this.setState({ project: event.target.value });
		} else if (name === 'issueType') {
			this.setState({ issueType: event.target.value });
		}
	};

	comments = [];
	componentWillUnmount() {
		console.log('component will unmount');
		// localStorage.removeItem("selectedFeature");
		// localStorage.removeItem("selectedFeatureWithJiraDetails");
	}

	componentDidMount() {
		localStorage.setItem('title', 'Feature Detail');
		let featureissueType = '';
		if (localStorage.getItem('selectedFeatureWithJiraDetails')) {
			let featurejson = JSON.parse(
				localStorage.getItem('selectedFeatureWithJiraDetails')
			);
			if (featurejson.jiraDetails.issueTypeId) {
				featureissueType = featurejson.jiraDetails.issueTypeId;
				this.setState({ issueType: featurejson.jiraDetails.issueTypeId });
			}
		}
		let feature = JSON.parse(
			localStorage.getItem('selectedFeatureWithJiraDetails')
		);
		if (feature) {
			JiraAccountService.getCommentByIssueId(feature.jiraDetails.issueId).then(
				(commentresponse) => {
					feature.jiraDetails.comments = commentresponse;
					this.setState({ comments: commentresponse, commentValue: '' });
				}
			);
		}

		if (localStorage.getItem('jiraURL')) {
			let jira_url = localStorage.getItem('jiraURL');
			if (jira_url.charAt(jira_url.length - 1) !== '/')
				jira_url = jira_url + '/';
			this.setState({ jiraURL: jira_url });
		}

		let opportunity = JSON.parse(localStorage.getItem('selectedFeature'));

		ScoreService.getScoreBySFDCId(opportunity.id).then((response) => {
			if (response.status === 'SUCCESS' && response.scores.length > 0) {
				this.setState({
					oppRank: response.scores[0].rank,
					oppScore: response.scores[0].otScore,
				});
			}
		});
		window.scrollTo(0, 100);
		this.myRef.current.scrollTo(0, 0);
		JiraAccountService.getAllIssueType().then((response) => {
			if (response != null) {
				let issueTypes = [];
				for (let index = 0; index < response.length; index++) {
					if (response[index].name === featureissueType) {
						issueTypes.unshift(response[index]);
						//issueTypes.push(response[index]);
					} else {
						issueTypes.push(response[index]);
					}
				}
				this.setState({ jiraIssueTypeList: issueTypes });
			}
		});
	}

	componentDidUpdate() {
		this.myRef.current.scrollTo(0, 0);
	}

	commentOnChangeHandler = (e) => {
		this.setState({ commentValue: e.target.value });
	};

	descOnChangeHandler = (e) => {
		this.setState({ descValue: e.target.value });
	};

	titleOnChangeHandler = (e) => {
		this.setState({ titleValue: e.target.value });
	};
	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	commentHandler = (projectID, commentBody) => {
		let commentRequest = {
			commentBody: commentBody,
			issueId: projectID,
		};
		let feature = JSON.parse(
			localStorage.getItem('selectedFeatureWithJiraDetails')
		);
		JiraAccountService.postJiraComment(commentRequest).then((response) => {
			this.toast.success('Comment Successfully posted on Jira');
			this.setState({ commentValue: '' });
			JiraAccountService.getCommentByIssueId(projectID).then(
				(commentresponse) => {
					feature.jiraDetails.comments = commentresponse;
					this.setState({ comments: commentresponse, commentValue: '' });
				}
			);
		});
	};

	updateHandler = (issueid, projectid) => {
		let updateissue = {};
		if (localStorage.getItem('selectedFeatureWithJiraDetails')) {
			let featurejson = JSON.parse(
				localStorage.getItem('selectedFeatureWithJiraDetails')
			);
			if (featurejson.jiraDetails.issueTypeId) {
				let featureissueType = featurejson.jiraDetails.issueTypeId;
				console.log('-1-1-1-1-1-1-1-1-1-1-');
				console.log(featurejson);
				console.log(issueid);
				console.log(this.state.jiraIssueTypeList);
				console.log(this.state.issueType);
				console.log(featurejson.jiraDetails.issueTypeId);
				console.log('-1-1-1-1-1-1-1-1-1-1-');
				if (this.state.issueType === featurejson.jiraDetails.issueTypeId) {
					let issueTypes = [];
					console.log('--------------');
					console.log(this.state.issueType);
					console.log(featurejson.jiraDetails.issueTypeId);
					console.log(
						this.state.issueType === featurejson.jiraDetails.issueTypeId
					);
					console.log(featureissueType);
					console.log(this.state.jiraIssueTypeList);
					console.log('--------------');
					// console.log(this.state.featurejson.jiraDetails.issueTypeId);
					for (
						let index = 0;
						index < this.state.jiraIssueTypeList.length;
						index++
					) {
						console.log(this.state.jiraIssueTypeList[index].name);
						console.log(this.state.issueType);
						if (
							this.state.jiraIssueTypeList[index].name === this.state.issueType
						) {
							updateissue = {
								description: this.state.descValue,
								issueTypeId: this.state.jiraIssueTypeList[index].id,
								projectId: projectid,
								title: this.state.titleValue,
							};
						}
					}
				} else {
					updateissue = {
						description: this.state.descValue,
						issueTypeId: this.state.issueType,
						projectId: projectid,
						title: this.state.titleValue,
					};
				}
			}
		}

		console.log('-------------------');
		console.log(updateissue);
		console.log('-------------------');
		JiraAccountService.UpdatIssueByIssueId(issueid, updateissue).then(
			(response) => {
				this.toast.success('Issue Update Successfully');
			}
		);
	};

	backHandler = () => {
		this.props.history.push('/submitfeature');
	};

	render() {
		const { classes } = this.props;
		let opportunity = JSON.parse(localStorage.getItem('selectedFeature'));
		let oppName = '';
		if (opportunity.opportunityName) {
			oppName = opportunity.opportunityName;
		}
		if (opportunity.name) {
			oppName = opportunity.name;
		}
		let feature = JSON.parse(
			localStorage.getItem('selectedFeatureWithJiraDetails')
		);
		let isupdate = localStorage.getItem('isupdate') === 'true';
		let displayName = 'Not Assigned';
		if (feature.jiraDetails.user) {
			displayName = feature.jiraDetails.user.displayName;
		}
		//let comments = feature.jiraDetails.comments
		if (this.state.comments) {
			this.comments = this.state.comments;
		} else {
			this.comments = feature.jiraDetails.comments;
		}
		let comments = this.comments;
		let commentsList = comments.map((item, index) => {
			return (
				<React.Fragment key={index + 'frag'}>
					<ListItem key={index + ''} alignItems="flex-start">
						<ListItemAvatar>
							<Avatar
								alt="item.author"
								src={JSON.parse(item.photoURL).author.avatarUrls['48x48']}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={item.content}
							secondary={
								<React.Fragment>
									<Typography
										component="span"
										variant="body2"
										className={classes.inline}
										color="textPrimary"
									>
										{item.author}
									</Typography>
									{' — Added a Comment'}
								</React.Fragment>
							}
						/>
					</ListItem>
					<Divider
						variant="inset"
						key={item.commentId + 'div'}
						component="li"
					/>
				</React.Fragment>
			);
		});

		return (
			<div ref={this.myRef}>
				<Header title="Feature" />
				<Paper>
					<Box display="flex" height={'100vh'} width="100%" flexDirection="row">
						<Box height="100%" width={'70%'}>
							<Paper>
								<List>
									{/* <ListItem key="opportunityName">
                    <TextField
                      id="outlined-multiline-static"
                      label="Opportunity Name :"
                      // multiline
                      disabled
                      InputProps={{
                        classes: {
                          input: classes.multilineColor,
                        },
                      }}
                      size="small"
                      rows={3}
                      fullWidth
                      color="secondary"
                      defaultValue={opportunity.parameters.name}
                      variant="outlined"
                    />
                  </ListItem> */}
									<ListItem key="jiraid">
										<TextField
											id="outlined-multiline-static"
											label="Opportunity Name"
											// multiline
											disabled
											InputProps={{
												classes: {
													input: classes.multilineColor,
												},
											}}
											size="small"
											rows={3}
											fullWidth
											color="secondary"
											defaultValue={oppName}
											variant="outlined"
										/>
									</ListItem>
									<ListItem key="featurename">
										<TextField
											id="outlined-multiline-static"
											label="Feature Name"
											// multiline
											disabled={!isupdate}
											InputProps={{
												classes: {
													input: classes.multilineColor,
												},
											}}
											rows={3}
											size="small"
											fullWidth
											//color="secondary"
											onChange={this.titleOnChangeHandler}
											value={this.value}
											defaultValue={feature.jiraDetails.issueName}
											variant="outlined"
										/>
									</ListItem>

									{
										<ListItem key="description">
											<TextField
												id="outlined-multiline-static"
												label="Description"
												multiline
												inputProps={{ color: 'red' }}
												disabled={!isupdate}
												InputProps={{
													classes: {
														input: classes.multilineColor,
													},
												}}
												rows={3}
												size="small"
												fullWidth
												style={{ color: 'red' }}
												defaultValue={feature.jiraDetails.description}
												onChange={this.descOnChangeHandler}
												value={this.value}
												variant="outlined"
											/>
										</ListItem>
									}
									{isupdate && (
										<ListItem key="issueType">
											<Typography>Issue Type: </Typography>
											<Select
												native
												// value={this.state.issueType}
												defaultValue={'New Feature'}
												onChange={this.handleChange('issueType')}
												inputProps={{
													name: 'projects',
													id: 'jira-project',
												}}
												style={{ marginLeft: 20 }}
												className={classes.selectField}
											>
												{this.state.jiraIssueTypeList.map((row) => (
													<option value={row.id}>{row.name}</option>
												))}
											</Select>
											<Button
												variant="contained"
												style={{ margin: 10, marginLeft: '20%' }}
												color="primary"
												size="large"
												onClick={() =>
													this.updateHandler(
														feature.jiraDetails.issueId,
														feature.jiraDetails.projectId
													)
												}
											>
												Update
											</Button>
											<Button
												variant="contained"
												style={{ margin: 10 }}
												color="primary"
												size="large"
												onClick={() => this.backHandler()}
											>
												Back
											</Button>
										</ListItem>
									)}
									<Paper>
										{
											<FormLabel
												component="legend"
												style={{ margin: 10 }}
												color="primary"
											>
												<b>Comments: </b>
											</FormLabel>
										}
										{<List className={classes.root}>{commentsList}</List>}

										{
											<ListItem key="comment">
												<TextField
													id="outlined-multiline-static"
													label="Add Comment"
													multiline
													rows={2}
													size="small"
													name="comment"
													value={this.state.commentValue}
													onChange={this.commentOnChangeHandler}
													fullWidth
													variant="outlined"
												/>
												<Button
													variant="contained"
													style={{ margin: 10 }}
													color="primary"
													size="large"
													onClick={() =>
														this.commentHandler(
															feature.jiraDetails.issueId,
															this.state.commentValue
														)
													}
												>
													Comment
												</Button>
											</ListItem>
										}
									</Paper>
								</List>
							</Paper>
						</Box>
						<Box
							height="100%"
							//bgcolor="green"
							width={'30%'}
						>
							<Paper>
								<List>
									<ListItem key="frid">
										<FormLabel component="legend">
											<b>FR ID : </b>
											<Link
												key={feature.customFeatureId}
												to={'/selectfeature/' + feature.customFeatureId}
											>
												<Typography
													className={classes.typography}
													color="primary"
													// aria-describedby={id}
													variant="contained"
												>
													{feature.customFeatureId}
												</Typography>
											</Link>
										</FormLabel>
										{/* <ListItemText primary={displayName} /> */}
									</ListItem>
									<ListItem key="jiraid">
										<FormLabel component="legend">
											<b>JIRA ID : </b>
											<a
												href={`${this.state.jiraURL}browse/${feature.jiraDetails.issueId}`}
												target="_blank"
												style={{ color: '#039be5', textDecoration: 'none' }}
											>
												{feature.jiraDetails.issueId}
											</a>
										</FormLabel>
										{/* <ListItemText primary={displayName} /> */}
									</ListItem>

									<ListItem key="assignee">
										<FormLabel component="legend">
											<b>Assignee : {displayName}</b>
										</FormLabel>
										{/* <ListItemText primary={displayName} /> */}
									</ListItem>
									<ListItem key="priority">
										<FormLabel component="legend">
											<b>Priority : {feature.jiraDetails.priority}</b>
										</FormLabel>
										{/* <ListItemText primary={feature.jiraDetails.priority} /> */}
									</ListItem>
									<ListItem key="status">
										<FormLabel component="legend">
											<b>Status : {feature.jiraDetails.jiraStatus.name}</b>
										</FormLabel>
										{/* <ListItemText
                      primary={feature.jiraDetails.jiraStatus.name}
                    /> */}
									</ListItem>
									<ListItem key="project">
										<FormLabel component="legend">
											<b>Project : {feature.jiraDetails.projectId}</b>
										</FormLabel>
										{/* <ListItemText primary={feature.jiraDetails.projectId} /> */}
									</ListItem>
									<ListItem key="otscore">
										<FormLabel component="legend">
											<b>FR Score : {feature.score + ''} </b>
										</FormLabel>
										{/* <ListItemText primary={opportunity.score + ""} /> */}
									</ListItem>
									<ListItem key="otscore">
										<FormLabel component="legend">
											<b>Actual Score : {feature.actualScore + ''} </b>
										</FormLabel>
										{/* <ListItemText primary={opportunity.score + ""} /> */}
									</ListItem>
								</List>
							</Paper>
						</Box>
					</Box>
				</Paper>
			</div>
		);
	}
}

export default withStyles(useStyles)(SelectedFeatureComponent);
