/**
 * @author Gaurav Sharma
 * @desc Organisation management service
 */
import service from "../Common/";
import constants from "../../../settings/shared/constants";

/**
 * This function helps to create a new organisation/tenant
 * @param {*} account
 */
function create(account) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  console.log(headers, account);
  return service({
    url: constants.api.organisation.create + "/",
    method: "POST",
    headers: headers,
    data: account,
  });
}

/**
 * This function helps to get organisation/tenant by id
 */
function get(id) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.organisation.get + "/" + id,
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to get all organisation/tenant
 */
function getAll() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.organisation.get + "/all",
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to update organisation/tenant by id
 * @param {*} id, account
 */
function update(id, account) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.organisation.update + "/" + id,
    method: "PUT",
    headers: headers,
    data: account,
  });
}

/**
 * This function helps to update organisation/tenant by id
 * @param {*} account
 */
function remove(id) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.organisation.delete + "/" + id,
    method: "DELETE",
    headers: headers,
  });
}

//Making to publish all functions
const AccountService = {
  create,
  get,
  getAll,
  update,
  remove,
};

export default AccountService;
