import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { blue } from '@material-ui/core/colors';
import { featurePriority } from '../DataSets/MenuDataSet';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';;

const featurepriority = featurePriority;
const useStyles = makeStyles({
	avatar: {
		backgroundColor: blue[100],
		color: blue[600],
	},
	multilineColor: {
		color: 'black',
	},
});

export default function AppendDialog(props) {
	const classes = useStyles();
	const { onClose, selectedValue, open } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};

	const handleChange = (name) => (event) => {
		if (name === 'featurePriority') {
			//this.setState({ featurePriority: event.target.value });
			onClose(event.target.value);
		}
	};

	console.log(props.featuredetails.tableMeta);
	let projectid = '';
	let issueid = '';
	let issuetype = '';
	let issueDesc = '';
	let issueTitle = '';
	if (props.featuredetails.tableMeta !== undefined) {
		let rowdata = props.featuredetails.tableMeta.rowData;
		projectid = rowdata[1];
		issueid = rowdata[0];
		issueTitle = rowdata[2];
		issuetype = rowdata[5];
		issueDesc = rowdata[3];
	}

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
		>
			<DialogTitle id="simple-dialog-title" style={{ width: 500 }}>
				Select Feature Priority
			</DialogTitle>

			<List>
				<ListItem key="jiraissuetype">
					<TextField
						id="outlined-multiline-static"
						label="Title: "
						// multiline
						disabled
						InputProps={{
							classes: {
								input: classes.multilineColor,
							},
						}}
						size="small"
						rows={3}
						fullWidth
						color="secondary"
						defaultValue={issueTitle}
						variant="outlined"
					/>
				</ListItem>
				<ListItem key="jiraissuetype">
					<TextField
						id="outlined-multiline-static"
						label="Description: "
						multiline
						disabled
						InputProps={{
							classes: {
								input: classes.multilineColor,
							},
						}}
						size="small"
						rows={3}
						fullWidth
						color="secondary"
						defaultValue={issueDesc}
						variant="outlined"
					/>
				</ListItem>
				<ListItem key="jiraprojectid">
					<TextField
						id="outlined-multiline-static"
						label="Project: "
						// multiline
						disabled
						InputProps={{
							classes: {
								input: classes.multilineColor,
							},
						}}
						size="small"
						rows={3}
						fullWidth
						color="secondary"
						defaultValue={projectid}
						variant="outlined"
					/>
				</ListItem>
				<ListItem key="jiraissueid">
					<TextField
						id="outlined-multiline-static"
						label="Jira ID: "
						// multiline
						disabled
						InputProps={{
							classes: {
								input: classes.multilineColor,
							},
						}}
						size="small"
						rows={3}
						fullWidth
						color="secondary"
						defaultValue={issueid}
						variant="outlined"
					/>
				</ListItem>
				<ListItem key="jiraissuetype">
					<TextField
						id="outlined-multiline-static"
						label="Issue Type: "
						// multiline
						disabled
						InputProps={{
							classes: {
								input: classes.multilineColor,
							},
						}}
						size="small"
						rows={3}
						fullWidth
						color="secondary"
						defaultValue={issuetype}
						variant="outlined"
					/>
				</ListItem>
				<ListItem>
					<Typography color="inherit"> Feature Priority2: </Typography>{' '}
					<Select
						native
						// value={this.state.project}
						defaultValue=""
						onChange={handleChange('featurePriority')}
						inputProps={{
							name: 'featurePriority',
							id: 'feature-priority',
						}}
						style={{ width: 150, marginLeft: 30 }}
						className={classes.selectField}
					>
						<option value="" disabled>
							Select One
						</option>
						{featurePriority.map((row) => (
							<option key={row.name} value={row.name}>
								{row.name}
							</option>
						))}
					</Select>
				</ListItem>
				{/* {featurepriority.map((featurepri) => (
          <ListItem
            button
            onClick={() => handleListItemClick(featurepri.name)}
            key={featurepri.name}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                {/* <PersonIcon /> */}
				{/* {featurepri.name.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={featurepri.name} />
          </ListItem> */}
				{/*))} */}
			</List>
		</Dialog>
	);
}

AppendDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedValue: PropTypes.string.isRequired,
};
