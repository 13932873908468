/**
 * @author Raghav Prabhu
 * @description Dashboard APIs
 */

import service from "../Common/";
import constants from "../../../settings/shared/constants";

/**
 * Get my opportunities dashboard
 */
function getMyOpportunities() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.dashboard.get_my_opportunity + "/",
    method: "GET",
    headers: headers,
  });
}
/**
 * Get my opportunities dashboard
 */
function getAllOpportunities() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.dashboard.get_all_opportunity + "/",
    method: "GET",
    headers: headers,
  });
}

function getFeatureDetails() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.dashboard.get_featurue_summary + "/",
    method: "GET",
    headers: headers,
  });
}

function getMyFeatureDetails() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.dashboard.get_my_featurue_summary + "/",
    method: "GET",
    headers: headers,
  });
}

function getMyAmountDetails() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.opportunity.get_amount_details,
    method: "GET",
    headers: headers,
  });
}

//

function getMyAmountDetailsByTenant() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.opportunity.get_amount_details_by_tenant,
    method: "GET",
    headers: headers,
  });
}


//Making to publish all functions
const DashboardService = {
  getMyOpportunities,
  getAllOpportunities,
  getFeatureDetails,
  getMyFeatureDetails,
  getMyAmountDetails,
  getMyAmountDetailsByTenant
};

export default DashboardService;
