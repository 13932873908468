/**
 * @author Raghav Prabhu
 * @desc Ranking settings module
 */
import service from '../Common/';
import constants from '../../../settings/shared/constants';

/**
 * This function to get all objects fields from configured source.
 * For example, this will get SFDC field attributes, if consifured source is SFDC.
 */
function getAllFields(tenant) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.sfdc.get_fields_all + '/' + tenant,
		method: 'GET',
		headers: headers,
	});
}

function getAllJiraFields(tenant) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.sfdc.get_fields_jira + '/' + tenant,
		method: 'GET',
		headers: headers,
	});
}

/**
 * This function to get all objects fields from configured source.
 * For example, this will get SFDC field attributes, if consifured source is SFDC.
 */
function getOpportunityFields(tenant) {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.sfdc.get_fields_opportunity + '/' + tenant,
		method: 'GET',
		headers: headers,
	});
}

function getAuthTemplate() {
	let jwtToken = localStorage.getItem('token');
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + jwtToken,
	};
	return service({
		url: constants.api.sfdc.get_auth_template + '/',
		method: 'GET',
		headers: headers,
	});
}

//Making to publish all functions
const RankingService = {
	getAllFields,
	getOpportunityFields,
	getAuthTemplate,
	getAllJiraFields,
};

export default RankingService;
