/**
 * @author Ramya Jhey
* @desc LoginPageComponent
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Pagination from "material-ui-flat-pagination";


const useStyles = theme => ({
  appBar: {
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  tabletitle:{
    color: 'black',
    fontWeight: 700,
  },
  cardHeader: {
    backgroundColor: '#ededed',
    borderBottom: '1px solid #000',

  },
  cardHeadertop: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #ededed',
    borderTop: '1px solid #000',
  },
  cardHeader1: {
    backgroundColor: '#ededed',
    borderBottom: '1px solid #ededed',

  },
  cardHeadertop1: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #ededed',
    borderTop: '2px solid #0288d1',

  },
  card: {
    minWidth: 75,
    fontSize: '1rem'
  },

  cardAction: {
    display: 'block',
    textAlign: '-webkit-center',
    padding: '0px',
    borderTop: '1px solid #ededed',
  },
  cardContent: {
    padding: '0px',
    paddingBottom: '0px !important',
}, 

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  Dialogwidth:{
    minWidth:'600px !important'
  },
  selectField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 220,
    
  },
  list:{
       paddingTop: '0px',
   paddingBottom: '0px',
  },
  listtext:{
    minWidth: '170px',
}

});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}


class LoginPageComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  offset: 0  }
  }
  handleChange = (event, offset) => {
    this.setState({offset:offset})
    };
  
  render() {
    const { classes } = this.props;
    const {open,setOpen} = this.state
    return (

      <div className={classes.root}>

<Card>
          <CardHeader title="Features" className={classes.cardHeadertop1} titleTypographyProps={{ variant: 'subtitle1' }} 
/>
          <CardContent>

      <Card className={classes.card}>
          <CardHeader title="Need Support for ATM Protocol" className={classes.cardHeadertop} titleTypographyProps={{ variant: 'subtitle1' }} 
action={
  <Button
  variant="contained"
  color="primary"
  size="small"
  className={classes.button}
  startIcon={<ArrowDownwardIcon /> }
  endIcon={<ArrowUpwardIcon />}
  onClick={this.handleOpen}
 ></Button>
}/>
          <CardContent className={classes.cardContent}>
          <List component="nav" aria-label="main mailbox folders" className={classes.list}>
        <ListItem button>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText className={classes.listtext} primary="$" />
          <ListItemText className={classes.listtext} primary="Time" />
          <ListItemText className={classes.listtext} primary="Probability" />
          <ListItemText className={classes.listtext} primary="Accounts" />
          <ListItemText className={classes.listtext} primary="Add On's" />
          <ListItemText className={classes.listtext} primary="Comments" />
        </ListItem>
       </List>
       <List component="nav" aria-label="main mailbox folders" className={classes.list}>
        <ListItem button>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText className={classes.listtext} primary="2000" />
          <ListItemText className={classes.listtext} primary="20 Feb 2020" />
          <ListItemText className={classes.listtext} primary="0.5" />
          <ListItemText className={classes.listtext} primary="Accounts" />
          <ListItemText className={classes.listtext} primary="Add On's" />
          <ListItemText className={classes.listtext} primary="Comments 1" />
        </ListItem>
       </List>
      <Divider />
           </CardContent>
      </Card>
      <Card className={classes.card}>
          <CardHeader title="Need Support for ATM Protocol" className={classes.cardHeadertop} titleTypographyProps={{ variant: 'subtitle1' }} 
action={
  <Button
  variant="contained"
  color="primary"
  size="small"
  className={classes.button}
  startIcon={<ArrowDownwardIcon /> }
  endIcon={<ArrowUpwardIcon />}
  onClick={this.handleOpen}
 ></Button>
}/>
          <CardContent className={classes.cardContent}>
          <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText className={classes.listtext} primary="$" />
          <ListItemText className={classes.listtext} primary="Time" />
          <ListItemText className={classes.listtext} primary="Probability" />
          <ListItemText className={classes.listtext} primary="Accounts" />
          <ListItemText className={classes.listtext} primary="Add On's" />
          <ListItemText className={classes.listtext} primary="Comments" />
        </ListItem>
       </List>
      <Divider />
           </CardContent>
      </Card>
      <Card className={classes.card}>
          <CardHeader title="Need Support for ATM Protocol" className={classes.cardHeadertop} titleTypographyProps={{ variant: 'subtitle1' }} 
action={
  <Button
  variant="contained"
  color="primary"
  size="small"
  className={classes.button}
  startIcon={<ArrowDownwardIcon /> }
  endIcon={<ArrowUpwardIcon />}
  onClick={this.handleOpen}
 ></Button>
}/>
          <CardContent className={classes.cardContent}>
          <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText className={classes.listtext} primary="$" />
          <ListItemText className={classes.listtext} primary="Time" />
          <ListItemText className={classes.listtext} primary="Probability" />
          <ListItemText className={classes.listtext} primary="Accounts" />
          <ListItemText className={classes.listtext} primary="Add On's" />
          <ListItemText className={classes.listtext} primary="Comments" />
        </ListItem>
       </List>
      <Divider />
           </CardContent>
      </Card>
      <Card className={classes.card}>
          <CardHeader title="Need Support for ATM Protocol" className={classes.cardHeadertop} titleTypographyProps={{ variant: 'subtitle1' }} 
action={
  <Button
  variant="contained"
  color="primary"
  size="small"
  className={classes.button}
  startIcon={<ArrowDownwardIcon /> }
  endIcon={<ArrowUpwardIcon />}
  onClick={this.handleOpen}
 ></Button>
}/>
          <CardContent className={classes.cardContent}>
          <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText className={classes.listtext} primary="$" />
          <ListItemText className={classes.listtext} primary="Time" />
          <ListItemText className={classes.listtext} primary="Probability" />
          <ListItemText className={classes.listtext} primary="Accounts" />
          <ListItemText className={classes.listtext} primary="Add On's" />
          <ListItemText className={classes.listtext} primary="Comments" />
        </ListItem>
       </List>
      <Divider />
           </CardContent>
      </Card>
     
      <Pagination
          limit={5}
          offset={this.state.offset}
          total={10}
          onClick={(e, offset) => this.handleClick}
        />
        </CardContent>
        </Card>
    </div>
    )
  }
}

export default withStyles(useStyles)(LoginPageComponent);
