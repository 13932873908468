/**
 * @author Ramya Jhey
 * @desc JIRA management service
 */
import service from "../Common/";
import constants from "../../../settings/shared/constants";

/**
 * This function helps to  get all projects from JIRA
 *
 */
function getAllProjects() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.jira.get_jira_connector + "/",
    method: "GET",
    headers: headers,
  });
}

/**
 * This function helps to get JIRA Details
 */
function getJiraConnector() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.jira.get_jira_connector + "/",
    method: "GET",
    headers: headers,
  });
}
/**
 * This function helps to get JIRA auth details
 */
function deleteJiraConnector() {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.jira.get_jira_connector + "/",
    method: "DELETE",
    headers: headers,
  });
}
/**
 * This function helps to post JIRA auth details
 */
function postJiraConnector(request) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.jira.get_jira_connector + "/",
    method: "POST",
    headers: headers,
    data: request,
  });
}

function getJiraConnectionStatus(request) {
  let jwtToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };
  return service({
    url: constants.api.jira.get_jira_connection_status + "/",
    method: "POST",
    headers: headers,
    data: request,
  });
}

//Making to publish all functions
const ConnectorService = {
  getAllProjects,
  getJiraConnector,
  deleteJiraConnector,
  postJiraConnector,
  getJiraConnectionStatus,
};

export default ConnectorService;
