/**
 * @author Gaurav Sharma
 * @email Gaurav6421@gmail.com
 * @create date 2019-04-15 17:56:48
 * @modify date 2019-04-15 17:56:48
 * @desc Product Module.
 */

import React, {Component} from 'react';
import MaterialTable from 'material-table';


const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Surname', field: 'surname' },
    { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
    {
      title: 'Birth Place',
      field: 'birthCity',
      lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
    },
  ];

 const data = [
    { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
    {
      name: 'Zerya Betül',
      surname: 'Baran',
      birthYear: 2017,
      birthCity: 34,
    },
  ]; 

class ProductComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {columns : []}
    }

    submitHandler = () => {
        console.log("this is submit handler in product handler");
    } 

    render(){
        return (
           // <GridDataComponent />
           <div>
           <MaterialTable
                title="Editable Example"
                columns={columns}
                data={data}
           />
         </div>
        )
    }
};

export default ProductComponent;
