/**
 * @author Gaurav Sharma
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RankingService from '../../Services/api/Ranking';
import MetricService from '../../Services/api/Metric';
import JiraAccountService from '../../Services/api/JiraAccount';
import FormHelperText from '@material-ui/core/FormHelperText';
import jwt_decode from 'jwt-decode';
import LoadingOverlay from 'react-loading-overlay';
import SFDCService from '../../Services/api/SFDC';
import { default as UUID } from 'node-uuid';
import ToastServive from 'react-material-toast';
import Button from '@material-ui/core/Button';
// import Header from '../CommonComponents/Header';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Icon from '@material-ui/core/Icon';
const useStyles = (theme) => ({
	appBar: {
		position: 'relative',
	},
	root: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1000 + theme.spacing.unit * 3 * 2)]: {
			width: 1000,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			marginTop: theme.spacing.unit * 6,
			marginBottom: theme.spacing.unit * 6,
			padding: theme.spacing.unit * 3,
		},
	},
	loadingOverlay: {
		// position: 'absolute',
		// height: '100vh',
		// width: '100vw',
	},
});

class ProductRankingComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: [],
			metrics: {},
			loading: false,
			newfield_object: [],
			newfield_object_jira: [],
			open: false,
			customField: {
				type: 'com.atlassian.jira.plugin.system.customfieldtypes:grouppicker',
			},
			customFieldsObject: {},
			customFieldItems: [],
		};
	}

	initMetrics = null;
	deleteMetrics = [];

	handleSubmit = () => {
		console.log(this.state.customField);
		JiraAccountService.createCustomField(this.state.customField).then(
			(response) => {
				this.toast.success('Custom field Create Successfully');
			},
			(error) => {
				this.toast.error('Not able to create Custom field');
			}
		);
		this.setState({ open: false });
	};

	createNew = () => {
		let productkeys = [];
		let number = 0;
		if (sessionStorage.getItem('metrics_summary') != null) {
			let metricsJSON = JSON.parse(sessionStorage.getItem('metrics_summary'));
			productkeys = Object.keys(metricsJSON);

			productkeys.map((item, index) => {
				item = metricsJSON[item];
				if (item.isCustom) number++;
			});
			let newNumber = number + 1;
			let currentCustomFieldItems = this.state.customFieldItems;
			let newItem = {
				key: 'otcustom' + newNumber,
				name: 'otcustom' + newNumber,
			};
			currentCustomFieldItems.push(newItem);
			console.log(newItem);
			this.setState({
				customFieldItems: currentCustomFieldItems,
			});
		}
	};

	handleClose = () => {
		this.setState({ open: false });
	};
	handleCustomEvent = (e) => {
		this.setState({
			customFieldsObject: {
				...this.state.customFieldsObject,
				[e.target.name]: e.target.value,
			},
		});
		//this.addMetricsStorage(label, name, type, weightage, false);
		this.addMetricsStorage(e.target.value, e.target.name, null, null, true);
	};
	handleEvent = (e) => {
		this.setState({
			customField: {
				...this.state.customField,
				[e.target.name]: e.target.value,
			},
		});
	};

	handleChange = (name, label, type, weightage) => (event) => {
		let field_object = this.state.newfield_object;

		if (event.target.checked) {
			for (let i = 0; i < field_object.length; i++) {
				if (name === field_object[i].name) {
					field_object[i].ischecked = !field_object[i].ischecked;
					break;
				}
			}
			this.setState({ newfield_object: field_object });
			this.state.metrics[name] = name;
			this.addMetricsStorage(label, name, type, weightage, false);
		} else {
			this.deleteMetricsStorage(name);
		}
	};

	jiraHandleChange = (name, label, type, weightage) => (event) => {
		let field_object = this.state.newfield_object_jira;

		if (event.target.checked) {
			for (let i = 0; i < field_object.length; i++) {
				if (name === field_object[i].name) {
					field_object[i].ischecked = !field_object[i].ischecked;
					break;
				}
			}
			this.setState({ newfield_object_jira: field_object });
			this.state.metrics[name] = name;
			this.addMetricsStorage(label, name, type, weightage, false);
		} else {
			this.deleteMetricsJiraStorage(name);
		}
	};

	toast = ToastServive.new({
		place: 'topRight',
		duration: 2,
		maxCount: 8,
	});

	addMetricsStorage(name, id, type, weightage, isCustom) {
		if (this.deleteMetrics.indexOf(id) !== -1) {
			this.deleteMetrics.splice(this.deleteMetrics.indexOf(id), 1);
		}

		let metrics = sessionStorage.getItem('metrics_summary');
		let uuid = UUID.v4();
		let metricsJSON = {};
		if (metrics != null) {
			metricsJSON = JSON.parse(metrics);
			if (metricsJSON[id] !== undefined) {
				delete metricsJSON[id];
			}
		}

		var jsonObject = {
			uuid: uuid,
			name: name,
			id: id,
			datatype: type,
			weightage: weightage,
			isCustom,
		};
		metricsJSON[id] = jsonObject;
		sessionStorage.setItem('metrics_summary', JSON.stringify(metricsJSON));
	}

	candeleteMetric(id) {
		let tempmetrics = JSON.parse(this.initMetrics);
		if (tempmetrics[id]) {
			MetricService.deleteByMetricsId(id, 'PRODUCT')
				.then((response) => {
					return response;
				})
				.catch((error) => {});
		}
		return true;
	}

	deleteMetricsJiraStorage(id) {
		/**
		 * check delete metrcis are availabel in init metrics.
		 */
		let tempmetrics = JSON.parse(this.initMetrics);
		if (tempmetrics !== null && tempmetrics[id]) {
			MetricService.deleteByMetricsId(id, 'PRODUCT')
				.then((response) => {
					if (response) {
						let field_object = this.state.newfield_object_jira;
						for (let i = 0; i < field_object.length; i++) {
							if (id === field_object[i].name) {
								field_object[i].ischecked = !field_object[i].ischecked;
								break;
							}
						}
						this.setState({ newfield_object_jira: field_object });
						this.deleteMetrics.push(id);

						sessionStorage.setItem(
							'deleteMetricID',
							JSON.stringify(this.deleteMetrics)
						);
						let metrics = sessionStorage.getItem('metrics_summary');
						let metricsJSON = {};
						if (metrics != null) {
							metricsJSON = JSON.parse(metrics);
							if (metricsJSON[id] !== undefined) {
								delete metricsJSON[id];
								sessionStorage.setItem(
									'metrics_summary',
									JSON.stringify(metricsJSON)
								);
								delete this.state.metrics[id];
							}
						}
					} else {
						this.toast.error('you cannot delete this metrics.');
					}
				})
				.catch((error) => {});
		} else {
			let field_object = this.state.newfield_object_jira;
			for (let i = 0; i < field_object.length; i++) {
				if (id === field_object[i].name) {
					field_object[i].ischecked = !field_object[i].ischecked;
					break;
				}
			}

			// delee from the locallstorage allOtSubmittedCount
			let metrics = sessionStorage.getItem('metrics_summary');
			let metricsJSON = JSON.parse(metrics);

			delete metricsJSON[id];

			sessionStorage.setItem('metrics_summary', JSON.stringify(metricsJSON));
			this.setState({ newfield_object_jira: field_object });
		}
	}

	deleteCustomMetric(id) {
		console.log(id);
		let customfieldsnow = [];
		for (let i = 0; i < this.state.customFieldItems.length; i++) {
			if (this.state.customFieldItems[i]['key'] === id) continue;
			customfieldsnow.push(this.state.customFieldItems[i]);
		}

		this.setState({ customFieldItems: customfieldsnow });
	}

	deleteMetricsStorage(id) {
		/**
		 * check delete metrcis are availabel in init metrics.
		 */
		let tempmetrics = JSON.parse(this.initMetrics);
		if (tempmetrics !== null && tempmetrics[id]) {
			MetricService.deleteByMetricsId(id, 'PRODUCT')
				.then((response) => {
					if (response) {
						let field_object = this.state.newfield_object;
						for (let i = 0; i < field_object.length; i++) {
							if (id === field_object[i].name) {
								field_object[i].ischecked = !field_object[i].ischecked;
								break;
							}
						}
						this.setState({ newfield_object: field_object });
						this.deleteMetrics.push(id);

						sessionStorage.setItem(
							'deleteMetricID',
							JSON.stringify(this.deleteMetrics)
						);
						let metrics = sessionStorage.getItem('metrics_summary');
						let metricsJSON = {};
						if (metrics != null) {
							metricsJSON = JSON.parse(metrics);
							if (metricsJSON[id] !== undefined) {
								delete metricsJSON[id];
								sessionStorage.setItem(
									'metrics_summary',
									JSON.stringify(metricsJSON)
								);
								delete this.state.metrics[id];
							}
						}
					} else {
						this.toast.error('you cannot delete this metrics.');
					}
				})
				.catch((error) => {});
		} else {
			let field_object = this.state.newfield_object;
			for (let i = 0; i < field_object.length; i++) {
				if (id === field_object[i].name) {
					field_object[i].ischecked = !field_object[i].ischecked;
					break;
				}
			}

			// delee from the locallstorage allOtSubmittedCount
			let metrics = sessionStorage.getItem('metrics_summary');
			let metricsJSON = JSON.parse(metrics);

			delete metricsJSON[id];

			sessionStorage.setItem('metrics_summary', JSON.stringify(metricsJSON));
			this.setState({ newfield_object: field_object });
		}
	}

	//Component mount life cycle
	componentDidMount() {
		this.setState({ loading: false });
		let jwtToken = localStorage.getItem('token');
		let user = jwt_decode(jwtToken);
		sessionStorage.removeItem('metrics_summary');
		sessionStorage.removeItem('deleteMetricID');
		//sessionStorage.removeItem('deleteMetricID');
		// delete sessionStorage.deleteMetricID;
		RankingService.getAllFields(user.id).then((response) => {
			if (response.status === 'SUCCESS') {
				let metrics = [];
				MetricService.getAll('SALES').then((metricResponse) => {
					let metricsDTO = metricResponse.metricsDTOS;
					metricsDTO.forEach((element) => {
						metrics.push(element.name);
						this.addMetricsStorage(
							element.name,
							element.metricObjectId,
							element.type,
							element.weightage,
							false
						);
					});
					this.initMetrics = sessionStorage.getItem('metrics_summary');
					if (sessionStorage.getItem('metrics')) {
						metrics = metrics.concat(
							Object.keys(JSON.parse(sessionStorage.getItem('metrics')))
						);
					}
					let newfield_object = [];
					response.fieldsObjects.map((item, index) => {
						let isChecked = metrics.indexOf(item.label) != -1;
						if (isChecked) {
							item['ischecked'] = isChecked;
							newfield_object.push(item);
						}
					});
					this.setState({ newfield_object: newfield_object });
					this.setState({ loading: false });
				});
			} else {
				SFDCService.oauth_login_url().then((response) => {
					let url = response + '&state=/metrics';
					window.location = url;
				});
			}
		});

		RankingService.getAllJiraFields(user.id).then((response) => {
			if (response.status === 'SUCCESS') {
				let metrics = [];
				MetricService.getAll('PRODUCT').then((metricResponse) => {
					let metricsDTO = metricResponse.metricsDTOS;
					metricsDTO.forEach((element) => {
						metrics.push(element.name);
						console.log('0806060504030302023030304040405', element);
						this.addMetricsStorage(
							element.name,
							element.metricObjectId,
							element.type,
							element.weightage,
							element.custom
						);
					});
					this.initMetrics = sessionStorage.getItem('metrics_summary');
					if (sessionStorage.getItem('metrics')) {
						metrics = metrics.concat(
							Object.keys(JSON.parse(sessionStorage.getItem('metrics')))
						);
					}
					let newfield_object = [];
					response.fieldsObjects.map((item, index) => {
						item['ischecked'] = metrics.indexOf(item.label) != -1;
						newfield_object.push(item);
					});

					this.setState({ newfield_object_jira: newfield_object });

					this.setState({ loading: false });
				});
			} else {
				SFDCService.oauth_login_url().then((response) => {
					let url = response + '&state=/metrics';
					//alert(url)
					window.location = url;
					// window.location.href = url
					// window.location.reload();
				});
			}
		});
	}

	render() {
		const { classes } = this.props;
		let customItem = [];
		console.log('098765432345678998765432=====9876545678987654');
		console.log(this.state.newfield_object);
		let metricsJSON = undefined;
		let productkeys = [];
		if (sessionStorage.getItem('metrics_summary') != null) {
			metricsJSON = JSON.parse(sessionStorage.getItem('metrics_summary'));
			productkeys = Object.keys(metricsJSON);
		}

		console.log('123123123', metricsJSON);
		console.log('123123123', productkeys);
		console.log('098765432345678998765432=====9876545678987654');

		for (let i = 0; i < this.state.customFieldItems.length; i++) {
			customItem.push(
				<>
					<TextField
						id="outlined-basic"
						label="Name"
						key={this.state.customFieldItems[i]['key']}
						required
						name={this.state.customFieldItems[i]['name']}
						variant="outlined"
						style={{
							width: 400,
							marginLeft: '20px',
							marginTop: '10px',
						}}
						onChange={this.handleCustomEvent}
					/>
					<Icon
						onClick={() =>
							this.deleteCustomMetric(this.state.customFieldItems[i]['key'])
						}
					>
						<HighlightOff />
					</Icon>
				</>
			);
		}
		let itemList = this.state.newfield_object.map((item, index) => (
			<Grid item xs={2} sm={6} key={index}>
				<FormControlLabel
					control={
						<Checkbox
							color="secondary"
							checked={item.ischecked}
							onChange={this.handleChange(
								item.name,
								item.label,
								item.type,
								item.weightage
							)}
							name={item.name}
							value="yes"
						/>
					}
					label={item.label}
					value={item.name}
				/>
				<FormHelperText>Data Type: {item.type}</FormHelperText>
			</Grid>
		));
		let itemListProduct = productkeys.map((item, index) => {
			let keyid = item;
			item = metricsJSON[item];
			console.log('=-=-=-=-=-=-=-=-=-=-=-', item);
			if (item.isCustom)
				return (
					<Grid item xs={2} sm={6} key={index}>
						<FormControlLabel
							control={
								<Checkbox
									color="secondary"
									checked={item.isCustom}
									// onChange={this.handleChange(
									// 	item.name,
									// 	item.item.,
									// 	item.type,
									// 	item.weightage
									// )}
									onChange={this.handleChange(
										keyid,
										item.name,
										item.type,
										item.weightage
									)}
									name={item.name}
									value="yes"
								/>
							}
							label={item.name}
							value={item.name}
						/>
						<FormHelperText>Data Type: {item.type}</FormHelperText>
					</Grid>
				);
		});
		let itemListJira = this.state.newfield_object_jira.map((item, index) => (
			<Grid item xs={2} sm={6} key={index}>
				<FormControlLabel
					control={
						<Checkbox
							color="secondary"
							checked={item.ischecked}
							onChange={this.jiraHandleChange(
								item.name,
								item.label,
								item.type,
								item.weightage
							)}
							name={item.name}
							value="yes"
						/>
					}
					label={item.label}
					value={item.name}
				/>
				<FormHelperText>Data Type: {item.type}</FormHelperText>
			</Grid>
		));
		return (
			<LoadingOverlay
				active={this.state.loading}
				spinner
				className={classes.loadingOverlay}
			>
				<React.Fragment>
					{/* <Dialog
						onClose={this.handleClose}
						aria-labelledby="simple-dialog-title"
						open={this.state.open}
					>
						<DialogTitle id="alert-dialog-title">
							{'Create Custom Field On Jira'}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<form className={classes.root} noValidate autoComplete="off">
									<TextField
										id="outlined-basic"
										label="Name"
										required
										name="name"
										variant="outlined"
										style={{
											width: 400,
											marginLeft: '20px',
											marginTop: '10px',
										}}
										onChange={this.handleEvent}
									/>
									<br />
									<TextField
										id="outlined-basic"
										label="Description"
										name="description"
										required
										variant="outlined"
										style={{
											width: 400,
											marginLeft: '20px',
											marginTop: '10px',
										}}
										onChange={this.handleEvent}
									/>
									<br />
									<TextField
										id="outlined-basic"
										label="Type"
										name="type"
										item
										xs={9}
										variant="outlined"
										defaultValue={this.state.customField.type}
										style={{
											width: 400,
											marginLeft: '20px',
											marginTop: '10px',
										}}
										onChange={this.handleEvent}
									/>
								</form>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								Cancle
							</Button>
							<Button onClick={this.handleSubmit} color="primary" autoFocus>
								Submit
							</Button>
						</DialogActions>
					</Dialog> */}
					<CssBaseline />
					<Button
						variant="contained"
						color="primary"
						style={{ margin: '10px' }}
						onClick={this.createNew}
						className={classes.button}
					>
						Create New
					</Button>
					<Grid container spacing={16}>
						<Grid xs={2} sm={6} key={'customfield'}>
							{customItem}
						</Grid>
					</Grid>
					<Grid container spacing={16}>
						<Grid xs={2} sm={6} key={'customfield'}>
							{itemListProduct}
						</Grid>
					</Grid>

					<hr />
					<Grid container spacing={16}>
						{itemList}
					</Grid>
					<hr />
					<Grid container spacing={16}>
						{itemListJira}
					</Grid>
				</React.Fragment>
			</LoadingOverlay>
		);
	}
}
export default withStyles(useStyles)(ProductRankingComponent);
